/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import "./ParametreAdmin.css";
import Listeadmin from "./ListeAdmin";
import Listeporteur from "./ListePorteur";
import HeaderV2 from "../Navs/HeaderV2";
import NavbarV2 from "../NavbarV2/NavbarV2";
import Ajouterunadministrateurmodal from "../../../Modals/AjouterAdministrateurModal";
import AjouterSecteurActiviteModal from "../../../Modals/AjouterSecteurActiviteModal";
import Listeporteurprojetadminmodal from "../../../Modals/ListePorteurProjetAdminModal";
import ListSecteur from "./ListSecteur";
import Profil from "./Profil";

const Parametreadmin = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  return (
    <div className="users-container">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="pt-5 mt-2 bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content-profile">
            <div className="profile-containers">
              <h1 className="mon-profil">Parametre</h1>
              <Profil/>
            </div>
            {/* -----------------Liste Administrateur---------------------- */}
            <div className="admin-container">
              <h1 className="mon-profil">Administrateur</h1>
              <button
                type="button"
                className="btn btn-parametre-admin float-right"
                data-bs-toggle="modal"
                data-bs-target="#ajouterunadministrateur"
              >
                <AddCircleOutlineIcon className="btn-parametre-icon" />
                Ajouter un administrateur
              </button>

              <Listeadmin />
            </div>
            {/* -----------------Ajout du secteur---------------------- */}
            <div className="admin-container">
              <h1 className="mon-profil">Secteur d'activité</h1>
              <button
                type="button"
                className="btn btn-parametre-admin float-right"
                data-bs-toggle="modal"
                data-bs-target="#ajouterunsecteurActivite"
              >
                <AddCircleOutlineIcon className="btn-parametre-icon" />
                Ajouter un secteur d'activité
              </button>
              <ListSecteur />
            </div>
            {/* -----------------Liste porteur de projet---------------------- */}
            <div className="liste-porteur-projet-container">
              <h1 className="mon-profil">Liste des porteurs de projets</h1>
              <button
                type="button"
                className="btn btn-parametre-admin float-right"
                data-bs-toggle="modal"
                data-bs-target="#ajouterunporteur"
              >
                <AddCircleOutlineIcon className="btn-parametre-icon" />
                Ajouter un porteur de projets
              </button>
              <div
                className="modal fade"
                id="ajouterunporteur"
                tabIndex="-1"
                aria-labelledby="ajouterunporteurdeprojet"
                aria-hidden="true"
              >
                <Listeporteurprojetadminmodal />
              </div>{" "}
              <Listeporteur />
            </div>
          </div>
          <div
            className="modal fade"
            id="ajouterunadministrateur"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ajouterunadministrateur"
            aria-hidden="true"
          >
            {" "}
            <Ajouterunadministrateurmodal />
          </div>
          <div
            className="modal fade"
            id="ajouterunsecteurActivite"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ajouterunsecteurActivite"
            aria-hidden="true"
          >
            {" "}
            <AjouterSecteurActiviteModal />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Parametreadmin;
