import { FETCH_INVEST_BY_PROJECT } from "../types";

export const InvestByProjectReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_INVEST_BY_PROJECT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
      
      default:
        return state;
      }
};
