import React from "react";
import "./ValidationIdentite.css";
import close from "../../../assets/images/close.png";
import gab1 from "../../../assets/images/gab1.png";
import gab2 from "../../../assets/images/gab2.png";
import gab3 from "../../../assets/images/gab3.png";
import gab4 from "../../../assets/images/gab4.png";
import { NavLink } from "react-router-dom";
import SidebarTableauBord from "../InvestisseurTableauDeBord/SidebarTableauBord/SidebarTableauBord";
import TabsProfile from "../InvestisseurTableauDeBord/ProfilInvestisseur/TabsProfile/TabsProfile";
import HeaderV2 from "../../BackendV2/Admin/Navs/HeaderV2";
const ValidationIdentite = () => {
  return (
    <div className="component-mes-projet-tableau-de-bord bg-dark">
      <HeaderV2 />
      <SidebarTableauBord  />
      <div className="ps-0 p-5 section-content-page-tb-investisseur">
        <TabsProfile />
        <div className="p-3 content-identite">
          <h1 className="titrevalidationidentite pl-5 border-bottom">
            Validation indentité
          </h1>

          <p className="mt-4 ml-5 text-identite">
            Vous n’avez pas encore validé votre identité
          </p>
          <h1 className="titreverificationidentite m-5">
            Vérification identité
          </h1>
          <div className="id-validation-container m-5">
            <div className="id-validation-btn-status-container">
              <button className="btnnonidentifie">
                <img src={close} className="closing" alt="icone pour fermer" />
                <span className="id-validation-label no-valid">
                  Identité non validée
                </span>
              </button>
            </div>
            <div className="id-validation-status-textual-container">
              <p className="text-non-identifie">
                Dans le cadre de la régulation de l’autorité des marchés
                financiers, vous devez valider votre identité afin de pouvoir
                investir.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4  d-flex ">
              <NavLink
                className="btnvalidation m-5  "
                to="/dashboard/Choix-type-investisement"
              >
                VALIDATION DE L’IDENTITÉ
              </NavLink>
            </div>
          </div>

          <div className="payment-card-types-container">
            <h1 className="titreverificationidentite m-5">
              Nos prestataires de paiement
            </h1>
            <div className="payment-card-container m-5">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={gab1}
                    className="mb-3"
                    alt="carte banquaire mastercard"
                  />
                </div>
                <p className="gab">MASTER CARD</p>
              </div>
              <div className=" d-flex align-items-center">
                <div>
                  {" "}
                  <img
                    src={gab2}
                    className="mb-3"
                    alt="carte banquaire ecobank"
                  />
                </div>
                <p className="gab">ECOBANK</p>
              </div>
              <div className=" d-flex align-items-center">
                <div>
                  {" "}
                  <img src={gab3} className="mb-3" alt="carte banquaire visa" />
                </div>
                <p className="gab">VISA</p>
              </div>
              <div className=" d-flex align-items-center">
                <div>
                  {" "}
                  <img
                    src={gab4}
                    className="mb-3"
                    alt="carte banquaire paydunya"
                  />
                </div>
                <p className="gab">PAYDUNYA</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ValidationIdentite;
