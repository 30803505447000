import React, { useState, useEffect } from "react";
import "./PageAccueil.css";
import HomeBanner from "../Banniere/Banniere";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import unsplash_QKml62yudA from "../../../assets/images/unsplash_QKml62yu-dA.png";
import unsplash_Vd7YrLIJqYI from "../../../assets/images/unsplash_Vd7YrLIJqYI.png";
import imgdollar from "../../../assets/images/imgdollar.png";
import iconme from "../../../assets/images/iconme.png";
import ProjetsEnLigne from "./ProjetsEnLigne/ProjetsEnLigne";
import HowItWorksSteps from "../HowItWorksV2/HowItWorksSteps";
import { Link, NavLink } from "react-router-dom";
import { ArrowRight } from "react-iconly";
import Risque from "../../Modules/RisqueInvestissement/Risque";
import Partenaire from "../Partenaire/Partenaire";
// import Evenement from "./Evenement";

const PageAccueil = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="component-front-home">
      <FrontHeaderV2 />
      <div className="content-view-start">
        <HomeBanner setOpen={setOpen} isOpen={isOpen} />
        <div className="container"><ProjetsEnLigne /></div>
        <div className="container home-section-container">
          <div className="section-title-container section-a-propos-home">
            <h1 className="home-section-title">
              <i>
                " Investir dans les entreprises de demain ou lever des fonds n’a
                jamais été aussi facile "
              </i>
            </h1>
          </div>

          <div className="row home-section-row">
            <div className="col-md-6 dev-textual-container">
              <h2 className="dev-textual-title">
                Investisseurs, bâtissez l’avenir en finançant l’économie réelle
              </h2>
              <div className="dev-textual">
                <div className="dev-textual-content">
                  Nous vous proposons une sélection d’entreprises rigoureusement
                  analysées et choisies pour leur haut potentiel. Vous trouverez
                  des projets ambitieux et inspirants dans tout type de secteurs
                  et à différents stade de développement à même de satisfaire
                  votre profil d’investisseur et de risques. Selon les cas,
                  trois modalités d’investissements sont disponibles:
                  obligations, fonds propres. Gage d’un parfait
                  alignement des intérêts, nous nous engageons systématiquement
                  à vos côtés en co-participant au tour de table (entre 5% et
                  10%).
                </div>
              </div>
              <div className="mt-4">
                <Link
                  className="link-header-login link-header-logins btn trans-0-2"
                  to="/liste-des-projets"
                >
                  Découvrir les opportunités
                </Link>
              </div>
            </div>

            <div className="col-md-6 dev-illustration-container d-flex justify-content-center justify-content-md-end">
              <div className="dev-illustration">
                <img
                  src={unsplash_QKml62yudA}
                  className="img-fluid dev-image"
                  alt="bâtissezlavenir"
                />
                <img
                  src={imgdollar}
                  className="illustration-icon"
                  alt="bâtissezlavenirsigne"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container home-section-container">
          <div className="row home-section-row">
            <div className="col-md-6 dev-illustration-container">
              <div className="dev-illustration">
                <img
                  src={unsplash_Vd7YrLIJqYI}
                  className="img-fluid dev-image"
                  alt="avenir-img"
                />
                <img
                  src={iconme}
                  className="illustration-icon-lefty"
                  alt="avenir-img-lefty"
                />
              </div>
            </div>
            <div className="col-md-6 mt-0 dev-textual-container dev-textual-container2">
              <div className="dev-textual-content-2">
                <h2 className="dev-textual-title">
                  Entrepreneurs, levez des fonds auprès d’une communauté
                  d’investisseurs engagés
                </h2>
                <div className="dev-textual-content py-3 mt-3">
                  Vous recherchez des fonds pour développer votre entreprise et
                  rendre le monde meilleur ? A l’issue de la pré-sélection, nous
                  vous conseillons pour définir le mode de financement le plus
                  adapté à vos besoins (dette, augmentation de capital
                  ) et vous accompagnons pour préparer le dossier de
                  présentation à mettre en ligne. Nous participons également
                  systématiquement au tour de table (entre 5% à 10%) pour
                  matérialiser notre accompagnement à vos côtés et envoyer un
                  signal fort à la communauté d’investisseurs
                </div>
                <div className="pt-4">
                  <Link
                    className="link-header-login link-header-logins btn trans-0-2"
                    to="/se-financer"
                  >
                    Découvrir les solutions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="raise-money-container-home">
           <div className="container">
           <div className="">
              <h1 className="raise-money-title">Comment ça marche?</h1>
            </div>
          <HowItWorksSteps />
          <div className="p-4 text-center">
            <NavLink to="/comment-ca-marche" className="btn btn-en-savoir-plus">
              <span className="pr-2">En savoir plus</span>
              <ArrowRight set="light" />
            </NavLink>
          </div>
           </div>
        </div>
        <div className='container'> 
          <Partenaire 
            stylePartenaire="raise-money-title"
          />
        </div>
        {/* <Evenement/> */}
        <div className='container'>
        <Risque />
        </div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default PageAccueil;
