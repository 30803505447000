import React from "react";
import { SATUS_NEWPROJET_BY_PORTEUR } from "../../../utils/payes";
export const QuestionnaireStatus = ({
	formData,
	setValue,
	formState,
}) => {
	return (

    <div className="quest-pp-block">
    <h3 className="title-questionnaire">
    2. Sélectionnez le statut de votre projet actuel 
      <span className="text-danger">*</span>
    </h3>
    <div className="form-group">
      <select
        className="form-control"
        value={formData?.statut_newprojet_by_porteur}
        onChange={(e) => setValue("statut_newprojet_by_porteur", e.target.value)}
      >
        <option value="">Sélectionner le statut de votre projet</option>
        {SATUS_NEWPROJET_BY_PORTEUR.map((statut_newprojet_by_porteur) => (
          <option key={statut_newprojet_by_porteur[0]} value={statut_newprojet_by_porteur[0]}>
            {statut_newprojet_by_porteur[1]}
          </option>
        ))}
      </select>
    </div>
    {formState.errors && formState.errors.statut_newprojet_by_porteur && (
      <div className="alert alert-danger gfa-alert-danger" role="alert">
        {formState.errors.statut_newprojet_by_porteur?.message}
      </div>
    )}
  </div>
  );
};
