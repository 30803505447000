import { Projet } from "./Endpoints";
import axios from "axios";

export default {
  async onGetListProjet(config) {
    const response = await axios.get(Projet.onGetListProjet(), config);
    // console.log(response)
    return response.data;
  },
  async onGetListProjetByUser(id, config) {
    const response = await axios.get(Projet.onGetListProjetByUser(id), config);
    // console.log(response)
    return response.data;
  },
  async onEditProjet(id, data, config) {
    console.log("mon id",id)
    const response = await axios.put(Projet.onEditProjet(id), data, config);
    return response.data;
  },
  async onPostProject(data, config) {
    const response = await axios.post(Projet.onPostProjet(), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteProjet(id, config) {
    const response = await axios.delete(Projet.onDeleteProjet(id), config);
    // console.log(response)
    return response.data;
  },
  async onPostcandidature(data) {
    const response = await axios.post(Projet.onPostCandidature(), data);
    // console.log(response)
    return response.data;
  },
  async onEditCandidature(id, data, config) {
    const response = await axios.put(Projet.onEditCandidature(id), data, config);
    // console.log(response)
    return response.data;
  },
  async onPostProjectDocs(data) {
    const response = await axios.post(Projet.postProjectDocs(), data);
    // console.log(response)
    return response.data;
  },
  async onValidCandidature(id, data, config) {
    const response = await axios.put(Projet.onValidProject(id), data, config);
    // console.log(response)
    return response.data;
  },
  async onSubscriber(data, config) {
    const response = await axios.post(Projet.onSubscriber(),data, config);
    return response.data;
  },
  async onFollow(data,config) {
    const response = await axios.post(Projet.onFollow(), data, config);
    return response;
  },
};
