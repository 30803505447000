import React from "react";
import Skeleton from "react-loading-skeleton";

function ListeAdminSkeleton() {
  return (
    <div className="">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{ color: "var(--secondary-color)", fontWeight: 600, fontSize: 18 }}
              //   className="th-table-admin"
            >
              Prénom+Nom
            </th>

            <th
              scope="col"
              style={{ color: "var(--secondary-color)", fontWeight: 600, fontSize: 18 }}
              //   className="th-table-admin"
            >
              Téléphone
            </th>
            <th
              scope="col"
              style={{ color: "var(--secondary-color)", fontWeight: 600, fontSize: 18 }}
              //   className="th-table-admin"
            >
              Adresse mail
            </th>
            <th
              scope="col"
              style={{ color: "var(--secondary-color)", fontWeight: 600, fontSize: 18 }}
              //   className="th-table-admin"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ListeAdminSkeleton;
