import React from "react";
import { PAYS, SATUS_NEWPROJET_BY_PORTEUR } from "../../../utils/payes";
export const QuestionnaireLocalisation = ({
	formData,
	setValue,
	formState,
}) => {
	return (

    <div className="quest-pp-block">
    <h3 className="title-questionnaire">
    1. Sélectionnez le pays où est localisé votre projet 
      <span className="text-danger">*</span>
    </h3>
    <div className="form-group">
      <select
        className="form-control"
        value={formData?.senegal}
        onChange={(e) => setValue("senegal", e.target.value)}
      >
        <option value="">Sélectionner un pays</option>
        {PAYS.map((pays) => (
          <option key={pays[0]} value={pays[0]}>
            {pays[1]}
          </option>
        ))}
      </select>
    </div>
    {formState.errors && formState.errors.senegal && (
      <div className="alert alert-danger gfa-alert-danger" role="alert">
        {formState.errors.senegal?.message}
      </div>
    )}
  </div>
  );
};
