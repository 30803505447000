import React from "react";
import { NavLink } from "react-router-dom";
import "./Banniere.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronDownCircle } from "react-iconly";
import plateforme from "../../../images/icons/Group 427319027.png";
import Icon from '../../../images/icons/Group 427319028.png';

// requires a loader
// import { DataContext } from "../../../redux/store/GlobalState";
var Banniere = () => {
	// const { state, dispatch } = useContext(DataContext);
	// let { list_projects_front } = state;

	return (
		<div className="component-home-banner">
			<div className="container couverture-container">
				<div className="couverture-content-container">
					<h1 className="couverture-title">
					Investisser dans les projets d’incubateurs
					</h1>
				</div>

				<div className="couverture-actions-btn-container mb-5">
					<div className="funding-btn-container">
						<NavLink
							className="btn btn-success funding-btn"
							to="/investir"
						>
							Investir
						</NavLink>
					</div>
					<div className="funding-video-description-container">
						<NavLink
							to="/se-financer"
							className="btn btn-default funding-video-btn"
						>
							Se financer
						</NavLink>
					</div>
				</div>
			</div>
			<div className="banner-icon-down-container">
				<div className="banner-icon-container">
					<ChevronDownCircle size="medium" primaryColor="var(--primaryColor)"/>
				</div>
			</div>
		</div>
	);
};

export default Banniere;
