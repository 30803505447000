import React, { useEffect } from "react";
import "./MonCompteInvestisseur.css";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import SidebarMobileTableauBord from "../SidebarTableauBord/SidebarMobileTableauBord";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
import { Document } from "react-iconly";
import Download from "../../../../images/download.png";

const MonCompteInvestisseur = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-investisseur dashboard-container">
      <HeaderV2 />
      <SidebarTableauBord />
      <div className="no-see-desktop-sidebar margin-top-sidebar-mobile-tabs">
        <SidebarMobileTableauBord />
      </div>

      <div className="pt-4 pe-3 section-content-page-tb-investisseur">
        <div className="d-flex align-item-end bg-white my-3">
          <ul
            class="nav nav-pills mb-3 d-flex flex-column flex-md-row align-items-md-end justify-content-between justify-content-xl-around p-5 col-12"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link active navlink-tabs-prets"
                id="pills-alimenter-tab"
                data-bs-toggle="pill"
                href="#pills-alimenter"
                role="tab"
                aria-controls="pills-alimenter"
                aria-selected="true"
              >
                Alimenter votre compte
              </a>
            </li>
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link navlink-tabs-prets"
                id="pills-debiter-tab"
                data-bs-toggle="pill"
                href="#pills-debiter"
                role="tab"
                aria-controls="pills-debiter"
                aria-selected="false"
              >
                Débiter votre compte
              </a>
            </li>
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link navlink-tabs-prets"
                id="pills-reporting-tab"
                data-bs-toggle="pill"
                href="#pills-reporting"
                role="tab"
                aria-controls="pills-reporting"
                aria-selected="false"
              >
                Reporting
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active in"
            id="pills-alimenter"
            role="tabpanel"
            aria-labelledby="pills-alimenter-tab"
          >
            <div className="bg-white repartition-pret">
              <div className="page-first-row-container border-bottom">
                <div className="page-title-container pt-3">
                  <h1 className="page-title">Alimenter votre compte</h1>
                </div>
              </div>
              {/* <div className="py-4">
                      <div className="text-center py-5">
                        <p className="text-contenu-list-pret">
                          Votre solde:{" "}
                          <span className="titre-synthese-list">
                            50 000 FCFA
                          </span>
                        </p>
                      </div>
                      <div>
                        <form>
                          <div className="form-group">
                            <label className="text-contenu-list-pret">
                              Montant
                            </label>
                            <select
                              className="form-control form-control-transaction"
                              id="exampleFormControlSelect1"
                            >
                              <option>10 000 FCFA</option>
                              <option>50 000 FCFA</option>
                            </select>
                          </div>
                          <div className="d-flex justify-content-end pt-4">
                            <button className="btn btn-theme">Suivant</button>
                          </div>
                        </form>
                      </div>
                    </div> */}
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-debiter"
            role="tabpanel"
            aria-labelledby="pills-debiter-tab"
          >
            <div className="bg-white repartition-pret">
              <div className="page-first-row-container border-bottom">
                <div className="page-title-container pt-3">
                  <h1 className="page-title">Débiter votre compte</h1>
                </div>
              </div>
              <div className="py-4">
                <div className="text-center py-5">
                  <p className="text-contenu-list-pret">
                    Votre solde:{" "}
                    <span className="titre-synthese-list">50 000 FCFA</span>
                  </p>
                </div>
                <div>
                  <form>
                    <div className="form-group py-2">
                      <label className="text-contenu-list-pret">Montant</label>
                      <select
                        className="form-control form-control-transaction"
                        id="exampleFormControlSelect1"
                      >
                        <option>10 000 FCFA</option>
                        <option>50 000 FCFA</option>
                      </select>
                    </div>
                    <div className="form-group py-2">
                      <label className="text-contenu-list-pret">
                        Compte crédité
                      </label>
                      <input
                        className="form-control form-control-transaction"
                        placeholder="SN76 3*** **** **** **** **06 363"
                      />
                    </div>
                    <div className="d-flex justify-content-end pt-4">
                      <button className="btn btn-theme">Suivant</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-reporting"
            role="tabpanel"
            aria-labelledby="pills-reporting-tab"
          >
            <div className="bg-white p-3">
              <div className="page-first-row-container border-bottom">
                <div className="page-title-container pt-3">
                  <h1 className="page-title">Documents à télécharger</h1>
                </div>
              </div>
              <div className="p-5">
                <div className="row mt-4 div-compte d-flex align-items-center">
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <Document set="bold" primaryColor="#DADADA" />
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-8">
                    <div className="border-left border-right p-3">
                      <p className="text-gray-tb">
                        Rapport d’activités de Data Center Mbao - du 15 Avril au
                        15 Juin 2021
                      </p>
                      <p className="text-gray-tb-sous-title">
                        1.9 Mo . PDF . Juin 2021
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <img src={Download} alt="logo-pdf" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4 div-compte d-flex align-items-center">
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <Document set="bold" primaryColor="#DADADA" />
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-8">
                    <div className="border-left border-right p-3">
                      <p className="text-gray-tb">
                        Rapport d’activités de Data Center Mbao - du 15 Avril au
                        15 Juin 2021
                      </p>
                      <p className="text-gray-tb-sous-title">
                        1.9 Mo . PDF . Juin 2021
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <img src={Download} alt="doc" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4 div-compte d-flex align-items-center">
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <Document set="bold" primaryColor="#DADADA" />
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-8">
                    <div className="border-left border-right p-3">
                      <p className="text-gray-tb">
                        Rapport d’activités de Data Center Mbao - du 15 Avril au
                        15 Juin 2021
                      </p>
                      <p className="text-gray-tb-sous-title">
                        1.9 Mo . PDF . Juin 2021
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <img src={Download} alt="doc" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4 div-compte d-flex align-items-center">
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <Document set="bold" primaryColor="#DADADA" />
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-8">
                    <div className="border-left border-right p-3">
                      <p className="text-gray-tb">
                        Rapport d’activités de Data Center Mbao - du 15 Avril au
                        15 Juin 2021
                      </p>
                      <p className="text-gray-tb-sous-title">
                        1.9 Mo . PDF . Juin 2021
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <img src={Download} alt="doc" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4 div-compte d-flex align-items-center">
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <Document set="bold" primaryColor="#DADADA" />
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-8">
                    <div className="border-left border-right p-3">
                      <p className="text-gray-tb">
                        Rapport d’activités de Data Center Mbao - du 15 Avril au
                        15 Juin 2021
                      </p>
                      <p className="text-gray-tb-sous-title">
                        1.9 Mo . PDF . Juin 2021
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-2 d-flex justify-content-center">
                    <div className="p-3">
                      <img src={Download} alt="doc" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonCompteInvestisseur;
