import React, { useEffect, useState } from 'react'
import SidebarTableauBord from '../SidebarTableauBord/SidebarTableauBord';
import HeaderV2 from '../../../BackendV2/Admin/Navs/HeaderV2';
import RapportTable from './RapportTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectsForVisitors } from '../../../../redux/projectsForVisitors/ProjectForVisitorAction';

const Rapport = () => {
    const projectsForVisitors = useSelector((state) => state.projects);
    // const projectsForVisitors = useSelector((state) => state.projectsForVisitors);
    console.log("projectsForVisitors",projectsForVisitors);
    const { isLoading, filteredData } = projectsForVisitors;
    const [error, setError] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [projects, setProjects] = useState([]);
    const dispatch = useDispatch();
  
    useEffect(() => {
      if (isLoading) {
        const timeoutId = setTimeout(() => {
          setShowSkeleton(false);
          setError("La requête a pris trop de temps. Veuillez réessayer.");
        }, 300000);
  
        return () => clearTimeout(timeoutId);
      } else {
        dispatch(fetchProjectsForVisitors());
        setShowSkeleton(false);
        setProjects(filteredData.slice(0, 109));
      }
    }, [isLoading, filteredData]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    
  return (
    <div className="component-mes-projet-tableau-de-bord">
    <HeaderV2 />
     <div className="mt-3 row p-0" style={{background: "white"}}>
       <div className="col-lg-4 col-xl-2">
         {/* <SidebarTableauDeBord value={2} /> */}
         <SidebarTableauBord />
       </div>
       <div className="col-lg-8 col-xl-10">
          <div className='title-des-porteurs'>
            <h6>Les porteurs de projets</h6>
          </div>
         <div className="projets" style={{background: "white", padding:"50px"}} >
          <RapportTable projects={projects}/>
         </div>
       </div>
     </div>
     {/* <FrontFooterV2 /> */}
   </div>
  )
}

export default Rapport



