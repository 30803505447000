import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import AuthServices from "../Services/AuthServices";
import { fetchMe } from "../../redux/user/userAction";
import { useParams } from "react-router-dom";

function UseLoginForm(saveData) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("Email invalide!")
          .required("L'email est requis."),
        password: yup.string().required("Le mot de passe est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    if (saveData) {
      saveData(formValues);
    }

    setIsSuccessfullySubmitted(true);
    var request = AuthServices.onLogin(formValues);
    await request
      .then(async (resp) => {
        const config = {
          headers: {
            Authorization: `Bearer ${resp?.token}`,
          },
        };

        let requestMe = AuthServices.onMe(config);
        await requestMe
          .then(async (res) => {
            if (res?.data?.id) {
              window.sessionStorage.setItem("userID", res?.data?.id);
              window.sessionStorage.setItem("userType", res?.data?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());

              // Mise en cache des données utilisateur
              const userData = {
                id: res.data.id,
                userType: res.data.user_type,
                token: resp.token,
              };
              window.sessionStorage.setItem(
                "userData",
                JSON.stringify(userData)
              );

              // Redirection en fonction du type d'utilisateur
              switch (res.data.user_type) {
                case "standard":
                  window.location = "/dashboard/editer-mon-profile";
                  break;
                case "investisseur":
                  if (
                    window.location.pathname ===
                    `/projet/connexion/${projectId}`
                  ) {
                    window.location = `/alimenter-son-compte/${projectId}`;
                  } else {
                    window.location = "/investisseur/Dashboard";
                  }
                  break;
                  case "entrepreneur":
                    window.location = "/entrepreneur/dashboard";
                    break;
                case "admin":
                  window.location = "/admin/dashboard";
                  break;
                case "incubateur":
                  window.location = "/incubateur/dashboard/";
                  break;
                default:
                  break;
              }
            } else {
              // Affichage d'un message d'erreur en cas de données utilisateur non valides
              Swal.fire({
                icon: "error",
                title: "Connexion non authorisée!",
                showConfirmButton: false,
                timer: 3000,
              });
            }
            setIsSuccessfullySubmitted(false);
          })
          .catch((err) => {
            setIsSuccessfullySubmitted(false);
            console.log("err", err, err?.response);
            if (err?.response === undefined) {
              Swal.fire({
                icon: "error",
                title: "Connexion non authorisée !",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.non_field_errors) {
          Swal.fire({
            icon: "error",
            title: "Email ou mot de passe incorrect ou compte inactif.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };

  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseLoginForm;
