import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as ReactIconly from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import ListProjetSkeleton from "./ListProjetSkeleton";
import { fetchProjectsByIncubateur } from "../../../../redux/ProjectsByIncubateur/ProjectsByIncubateurAction";
import "./enligne.css";
import storage from "../../../../Http/storage";
// import { AiFillStop } from "react-icons/ai";
// import { IoMdTime } from "react-icons/io";
// import { IoCheckmarkDoneCircle } from "react-icons/io5";
// import { FaRegTimesCircle } from "react-icons/fa";

const ProjetsEnLigneIncubateur = () => {
  const current_user = useSelector((state) => state.user.data);
  const id = current_user?.id;
  const projectsForVisitors = useSelector(
    (state) => state.projectsByIncubateur
  );
  const { isLoading, data: projectsData } = projectsForVisitors;

  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [filteredProjets, setFilteredProjets] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setShowSkeleton(true);
      dispatch(fetchProjectsByIncubateur(id)).then((response) => {
        setShowSkeleton(false);
        if (response?.error) {
          setError(
            "Une erreur est survenue lors du chargement des projets. Veuillez réessayer."
          );
        }
      });

      const timeoutId = setTimeout(() => {
        if (showSkeleton) {
          setShowSkeleton(false);
          setError("La requête a pris trop de temps. Veuillez réessayer.");
        }
      }, 300000);

      return () => clearTimeout(timeoutId);
    }
  }, [dispatch, id]);

  useEffect(() => {
    const filtered = projectsData.filter((projet) => {
      if (activeTab === 0) {
        return true;
      } else if (activeTab === 1) {
        return projet.statut === "valider";
      } else if (activeTab === 2) {
        return projet.statut === "rejeter";
      } else if (activeTab === 3) {
        return projet.statut === "nouveau";
      } else if (activeTab === 4) {
        return projet.statut === "suspendu";
      } else if (activeTab === 5) {
        return projet.statut === "terminer";
      }
      return false;
    });

    setFilteredProjets(filtered);
  }, [activeTab, projectsData]);

  return (
    <div className="dashboard-container">
      <div className="p-2">
        <div className="mt-0 projets-info">
          <h1 className="liste-projets-title">Nos projets</h1>
        </div>
        <div className="d-flex justify-content-between align-items-center projets-info">
          <div className="d-flex">
            <ul className="d-flex flex-column align-items-start flex-md-row align-items-md-center p-0 m-0">
              <li
                className={`detail-projets-tabs-item ${
                  activeTab === 0 && "active-tab"
                }`}
                onClick={() => setActiveTab(0)}
              >
                Tous les projets
              </li>
              <li
                className={`detail-projets-tabs-item ${
                  activeTab === 4 && "active-tab"
                }`}
                onClick={() => setActiveTab(4)}
              >
                Suspendu
              </li>
              <li
                className={`detail-projets-tabs-item ${
                  activeTab === 1 && "active-tab"
                }`}
                onClick={() => setActiveTab(1)}
              >
                En cours
              </li>
              <li
                className={`detail-projets-tabs-item ${
                  activeTab === 3 && "active-tab"
                }`}
                onClick={() => setActiveTab(3)}
              >
                En attente
              </li>
              <li
                className={`detail-projets-tabs-item ${
                  activeTab === 5 && "active-tab"
                }`}
                onClick={() => setActiveTab(5)}
              >
                Terminé
              </li>
              <li
                className={`detail-projets-tabs-item ${
                  activeTab === 2 && "active-tab"
                }`}
                onClick={() => setActiveTab(2)}
              >
                Rejeté
              </li>
            </ul>
          </div>
          <div className="">
            <Link
              to="/incubateur/ajouterprojet/"
              className="ajouter-projet-link border p-3"
              style={{ borderRadius: "10px" }}
            >
              <ReactIconly.Plus className="ajouter-projet-link-icon" />
              <span className="ajouter-projet-link-text">
                Ajouter un projet
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div>
        {showSkeleton && <ListProjetSkeleton />}
        {!showSkeleton && error && (
          <div className="text-center">
            <p className="bg-danger text-light font-weight-bold p-2">{error}</p>
          </div>
        )}
        {!showSkeleton && !error && filteredProjets.length === 0 && (
          <div className="text-center">
            <p className="bg-secondary text-light font-weight-bold p-2">
              Pas de données
            </p>
          </div>
        )}
        {!showSkeleton && !error && filteredProjets.length > 0 && (
          <div className="row m-0 project-list-container">
            {filteredProjets.map((project) => (
              <div className="col-md-4 project-item-container" key={project.id}>
                <Link
                  to={{
                    pathname: `/dashboard/incubateur/nos-projets/:projectId${project.slug}`,
                    state: project,
                  }}
                  className="d-flex justify-content-center"
                >
                  <div
                    className="project-item-global-card"
                    style={{ width: "340px" }}
                  >
                    <div className="card bg-white project-item-card">
                      <div className="project-item-card-img-container">
                        <video
                          controls
                          className="card-img-top project-item-img"
                        >
                          <source
                            src={storage + project?.video}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className="card-body bg-white p-0 project-item-card-body">
                        <div className="d-flex justify-content-between align-items-center pt-3">
                          <div className="">
                            <div
                              className="financement"
                              style={{ backgroundColor: "#E87930" }}
                            >
                              <p
                                style={{
                                  fontSize: "10.3px",
                                  color: "#E9F3F4",
                                  textAlign: "center",
                                  fontWeight: "700",
                                  padding: "6px 20px",
                                }}
                              >
                                {project?.secteur?.length
                                  ? project.secteur.map(
                                      (item, index) =>
                                        item.name +
                                        `${
                                          index < project.secteur.length - 1
                                            ? " - "
                                            : ""
                                        }`
                                    )
                                  : "non défini"}
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <div className="financement">
                              <p
                                style={{
                                  color: "#E9F3F4",
                                  textAlign: "center",
                                  fontSize: "9px",
                                  fontWeight: "900",
                                }}
                              >
                                {project.statut === "valider" ? (
                                  <div className="d-flex align-items-center">
                                    <p className="bg-warning text-white p-2 bordureStatut">
                                      En cours
                                    </p>
                                    {/* <IoMdTime /> */}
                                  </div>
                                ) : project.statut === "rejeter" ? (
                                  <div className="d-flex align-items-center">
                                    <p className="bg-danger text-white p-2 bordureStatut">
                                      Rejeté
                                    </p>
                                    {/* <FaRegTimesCircle /> */}
                                  </div>
                                ) : project.statut === "suspendu" ? (
                                  <div className="d-flex align-items-center">
                                    <p className="bg-danger text-white p-2 bordureStatut">
                                      Suspendu
                                    </p>
                                    {/* <AiFillStop /> */}
                                  </div>
                                ) : project.statut === "terminer" ? (
                                  <div className="d-flex align-items-center">
                                    <p className="bg-success text-white p-2 bordureStatut">
                                      Terminé
                                    </p>
                                    {/* <IoCheckmarkDoneCircle /> */}
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <p className="bg-secondary text-white p-2 bordureStatut">
                                      En attente
                                    </p>
                                    {/* <IoMdTime /> */}
                                  </div>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="px-1 py-2">
                          <h6 className="card-title mt-3 mr3 project-itemIncubateur">
                            {project.title}
                          </h6>
                          <div className="row">
                            <div className="col">
                              <div className="financement">
                                <span className="containFinancement">
                                  Besoin en financement:
                                </span>
                                <p className="ContainMontant ContainMontantColor">
                                  FCFA{" "}
                                  {project?.fonds_supplementaires_prix
                                    ? project.fonds_supplementaires_prix
                                    : "Montant non défini"}
                                </p>
                              </div>
                            </div>
                            <div className="col ml-auto">
                              <div
                                className="financement"
                                style={{ marginLeft: "40px" }}
                              >
                                <span className="containFinancement">
                                  Montant atteint:
                                </span>
                                <p className="ContainMontantColor">
                                  FCFA
                                  {project?.fonds_supplementaires_prix
                                    ? project.fonds_supplementaires_prix
                                    : "non défini"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjetsEnLigneIncubateur;
