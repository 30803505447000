import "@grapecity/wijmo.styles/wijmo.css";
import "./LineChartApp.css";
import * as React from "react";
import * as wjChart from "@grapecity/wijmo.react.chart";
import { isArray } from "@grapecity/wijmo";
import { Palettes } from "@grapecity/wijmo.chart";
import { getData } from "./data";

export default class LineChartApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getData(),
      palette: this.getRandomPalette(),
    };
  }

  render() {
    return (
      <div className="graphProgression">
        <div className="form-group position-relative">
          {/* <label htmlFor="secondaryAxis">Secondary Y Axis</label> */}
          <input
            id="secondaryAxis"
            type="checkbox"
            defaultChecked
            onClick={this.saChange.bind(this)}
          />
          <wjChart.FlexChart
            bindingX="country"
            initialized={this.initializeChart.bind(this)}
            itemsSource={this.state.data}
            palette={this.state.palette}
          >
            <wjChart.FlexChartSeries
              binding="contribution"
              name="Contribution"
              style={{
                fill: "var(--secondary-color)",
                stroke: "var(--secondary-color)",
                strokeWidth: 1,
                width: "17px",
              }}
            ></wjChart.FlexChartSeries>
            <wjChart.FlexChartSeries
              binding="sponsors"
              name="Sponsors"
              style={{
                fill: "#BB8111",
                stroke: "#BB8111",
                strokeWidth: 1,
                width: "17px",
              }}
            ></wjChart.FlexChartSeries>
            <wjChart.FlexChartSeries
              binding="tuteur"
              name="Tuteur"
              chartType="LineSymbols"
              style={{ fill: "#1F4F96", stroke: "#1F4F96", strokeWidth: 4 }}
            >
              <wjChart.FlexChartAxis
                wjProperty="axisY"
                position="Right"
                title="tuteur (k)"
                format="n0,"
                min={0}
                axisLine={true}
              ></wjChart.FlexChartAxis>
            </wjChart.FlexChartSeries>
            <wjChart.FlexChartAxis
              wjProperty="axisY"
              format="n0,"
              title="Contribution/Sponsors (US$ k)"
              axisLine={true}
            ></wjChart.FlexChartAxis>
          </wjChart.FlexChart>
        </div>
      </div>
    );
  }
  initializeChart(flex) {
    this.ser = flex.series[2];
    this.axisY = this.ser.axisY;
  }
  getRandomPalette() {
    let palettes = Object.getOwnPropertyNames(Palettes).filter((prop) =>
      isArray(Palettes[prop])
    );
    let rand = Math.floor(Math.random() * palettes.length);
    //
    return Palettes[palettes[rand]];
  }
  saChange(sa) {
    this.ser.axisY = sa.target.checked ? this.axisY : null;
  }
}
