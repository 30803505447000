import React, { useMemo, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import Projet from "../../../../Services/Projet";
import Swal from "sweetalert2";
import CreerUneDatePourLeLancement from "./CreerUneDatePourLeLancement";
import { useNavigate } from "react-router-dom";
import DocumentAJoindre from "../../../Questionnaires/DocumentAJoindre";
import api from "../../../../../Http/global-vars";
import { useSelector } from "react-redux";
import { parse, isValid, format } from "date-fns";

const InformationsSuplementairesScumulativeFoundingentreprise = ({
  formData,
  setFormData,
  navigation,
}) => {
  const users = useSelector((state) => state.user);
  const projet = useSelector((state) => state.projects);
  console.log("Données du projet :", projet?.data);
  const userId = users?.data?.id;
  console.log({ userId });

  function convertDate(inputDate) {
    const parsedDate = parse(inputDate, "dd MMMM yyyy", new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, "yyyy-MM-dd");
    } else {
      return null; // La date entrée n'est pas valide
    }
  }

  const userType = users.data.user_type ? users.data.user_type : "user";
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        // fonds_supplementaires: yup
        //   .boolean()
        //   .required("Ce champ est requis.")
        //   .nullable(),
        date_lieu_societe: yup.string().nullable(),
        capitale: yup.string().nullable(),
        repartition_propriete: yup.string().nullable(),
        nombre_fondateurs: yup.number().nullable(),
        actionnarat: yup.number().nullable(),
        // cv_entrepreneur: yup.number().nullable(),
        cv_entrepreneur: yup
        .mixed()
        .test("fileType", "Veuillez télécharger un fichier de type PDF ou Word.", (value) => {
            if (!value) return true; // Si aucun fichier n'est téléchargé, la validation passe
            return value && (value[0].type === "application/pdf" || value[0].type === "application/msword"); // Vérifie le type de fichier
        })
        .test("fileSize", "La taille du fichier est trop grande. Veuillez télécharger un fichier de moins de 5 Mo.", (value) => {
            if (!value) return true; // Si aucun fichier n'est téléchargé, la validation passe
            return value && value[0].size <= 5 * 1024 * 1024; // Limite la taille du fichier à 5 Mo
        })
        .nullable(),
        bio_fondateur: yup
          .string()
          .max(1000, "Vous devez écrire au maximum 100 caractères.")
          .required("Ce champ est requis.")
          .nullable(),
        lieu_societe: yup.string().nullable(),
        date: yup.date().nullable(),
        // position: yup.string().required("Ce champ est requis.").nullable(),
        // email: yup
        //   .string()
        //   .email("Email invalide.")
        //   .required("Ce champ est requis.")
        //   .nullable(),
        // telephone: yup.string().required("Ce champ est requis.").nullable(),
        lieu_connaissance: yup.string().nullable(),
        documents: yup
          .array()
          .min(1, "Veuillez joindre les documents du projet avant de continuer")
          .required(
            "Veuillez joindre les documents du projet avant de continuer"
          )
          .nullable(),
        site: yup.string().url("Url invalide").nullable(),
      }),
    []
  );
  const navigate = useNavigate();
  const { register, handleSubmit, clearErrors, formState, setValue } = useForm({
    // defaultValues:{
    //   secteur : projet.data.secteur,
    //   objectif_financement : projet.data.objectif_financement,
    //   mode_financement : projet.data.mode_financement,
    //   duree_existance : projet.data.duree_existance,
    // },
    resolver: yupResolver(validationSchema),
  });
  const [bio, setBio] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const onChange = (e) => {
    setValue("bio_fondateur", e.target.value);
    setBio(e.target.value);
  };
  useEffect(() => {
    setValue("fonds_supplementaires", formData?.fonds_supplementaires);
    setValue("revenus", formData?.revenus);
    setValue("evolutivite", formData?.evolutivite);
    setValue("date_lieu_societe", formData?.date_lieu_societe);
    setValue("capitale", formData?.capitale);
    setValue("repartition_propriete", formData?.repartition_propriete);
    setValue("nombre_fondateurs", formData?.nombre_fondateurs);
    setValue("actionnarat", formData?.actionnarat);
    setValue("bio_fondateur", formData?.bio_fondateur);
    setValue("cv_entrepreneur", formData?.cv_entrepreneur);
    setValue("lieu_societe", formData?.lieu_societe);
    setValue("date", formData?.date);
    if (formData?.documents?.length > 0) {
      setValue("documents", formData.documents);
    }
    setValue("lieu_connaissance", formData?.lieu_connaissance);
  }, [formData, setValue]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  const clean = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const onSubmit = async (data) => {
    await setFormData({ ...formData, ...data });
    data.date = convertDate(data.date);
    setIsLoading(true);
    let dataPost = await { ...formData, ...data };
    let documents = await dataPost.documents;
    await delete dataPost.documents;
    dataPost = clean(dataPost);
    if (dataPost?.otherSecteur) {
      await axios
        .post(`${api}secteurs/`, { name: dataPost?.otherSecteur })
        .then(async (resp) => {
          // console.log("resp", resp);
          await dataPost.secteur.push(resp?.data?.id);
        })
        .catch((error) => {
          console.log("error", error?.response);
        });
    }

    dataPost.secteur = await dataPost.secteur.filter(
      (item) => item !== "other"
    );
    // await console.log("test request", dataPost);
    let request = Projet.onPostcandidature(dataPost);

    await request
      .then(async (response) => {
        setProjectId(response?.slug);
        await documents.forEach((element) => {
          const fd = new FormData();
          fd.append("name", element?.name);
          fd.append("doc", element);
          fd.append("projet", response?.id);
          Projet.onPostProjectDocs(fd)
            .then((res) => {
              console.log("res doc", res);
            })
            .catch((error) => console.log("error doc", error?.response));
        });
        await Swal.fire({
          position: "center",
          icon: "success",
          title:
            "<h5>La candidature de votre projet a été soumise avec succés!</h5>",
          html: "Elle est en cours de validation et vous recevrez une notification par email pour la suite qui lui sera donnée.",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Créer une date pour le lancement",
          cancelButtonText: "Ignorer",
          iconColor: "var(--secondary-color)",
          confirmButtonColor: "var(--secondary-color)",
          allowOutsideClick: false,
        }).then((submit) => {
          if (submit.isConfirmed) {
            // navigate.push("/");
            setIsLoading(false);
            window.$("#CreerDateLancement").modal("show");
          } else {
            setIsLoading(false);
            if (userType === "entrepreneur") {
              navigate("/entrepreneur/gestion-des-projets");
            } else {
              navigate("/entrepreneur/gestion-des-projets");
            }
          }
        });
        // console.log("response", response);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de la soumission!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        console.log("error", error?.response);
      });
    // await console.log({ ...formData, ...data });
  };
  const props = {
    formData,
    clearErrors,
    formState,
    setValue,
  };
  return (
    <>
      <div className="soumettre-projet">
        <div className="d-flex justify-content-center align-items-end se-financer pt-5">
          <h1 className="">Se financer</h1>
        </div>
        <section className="container informations">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="formulaire-de-depot-dossier"
          >
            <div className="form-group-container">
              <h3 className="form-title">
                7. Informations complémentaires sur la société et les fondateurs
              </h3>
              <div className="form-textarea-group">
                <div className="d-flex flex-column justify-content-between  flex-md-row">
                <div className="flex-fill mr-5">
                    <label htmlFor="campagneName">
                      {" "}
                      Lieu societe
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="lieu societe"
                      id="lieu_societe"
                      name="lieu_societe"
                      {...register("lieu_societe")}
                      // data-testid="titleId"
                    />
                    {formState?.errors && formState?.errors?.lieu_societe && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState?.errors?.lieu_societe?.message}{" "}
                      </div>
                    )}{" "}
                  </div>
                  <div className="mt-5 mr-5 mt-md-0  flex-fill">
                    <label htmlFor="url">date</label>
                    <input
                      type="date"
                      placeholder="date"
                      name="date"
                      id="date"
                      {...register("date")}
                      // data-testid="url_demoId"
                    />
                    {formState?.errors && formState?.errors.date && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState?.errors?.date?.message}{" "}
                      </div>
                    )}{" "}
                  </div>{" "}
                </div>

                <div className="d-flex flex-column justify-content-between gap-2 flex-md-row">
                  
                    <div className="flex-fill">
                      <label htmlFor="campagneName">
                        {" "}
                        Cv fondateur
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        placeholder="cv fondateur"
                        id="cv_entrepreneur"
                        name="cv_entrepreneur"
                        {...register("cv_entrepreneur")}
                      />
                      {formState?.errors && formState?.errors?.cv_entrepreneur && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState?.errors?.cv_entrepreneur?.message}{" "}
                        </div>
                      )}{" "}
                  </div>
                </div>
              </div>
              <div className="form-textarea-group">
                <label htmlFor="capital">Capital social</label>
                <textarea
                  name="capitale"
                  id="capital"
                  cols="50"
                  rows="10"
                  {...register("capitale")}
                  data-testid="capitaleId"
                />
                {formState.errors && formState.errors.capitale && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.capitale?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="PropertyDistribution">
                  Répartition des parts
                </label>
                <textarea
                  name="repartition_propriete"
                  id="PropertyDistribution"
                  cols="50"
                  rows="10"
                  {...register("repartition_propriete")}
                  data-testid="repartition_proprieteId"
                />
                {formState.errors && formState.errors.repartition_propriete && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.repartition_propriete?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group d-flex flex-column justify-content-between flex-md-row">
                <div className="flex-fill mr-5">
                  <label htmlFor="numberFounders">
                    Combien de fondateurs êtes-vous ?
                  </label>
                  <input
                    type="number"
                    // placeholder="I"
                    id="numberFounders"
                    min={1}
                    name="nombre_fondateurs"
                    {...register("nombre_fondateurs")}
                    data-testid="nombre_fondateursId"
                  />
                  {formState.errors && formState.errors.nombre_fondateurs && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.nombre_fondateurs?.message}
                    </div>
                  )}
                </div>
                <div className="mt-5 mt-md-0 flex-fill">
                  <label htmlFor="cumulativeFounding">
                    Combien de parts détiennent les fondateurs (%) ?
                  </label>
                  <input
                    type="number"
                    // placeholder="I"
                    id="cumulativeFounding"
                    max={100}
                    min={0}
                    name="actionnarat"
                    {...register("actionnarat")}
                    data-testid="actionnaratId"
                  />
                  {formState.errors && formState.errors.actionnarat && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.actionnarat?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-textarea-group">
                <label htmlFor="biosFounder">
                  Mini Bios des Fondateurs
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  id="biosFounder"
                  cols="50"
                  rows="10"
                  name="bio_fondateur"
                  // {...register("bio_fondateur")}
                  onChange={onChange}
                  data-testid="bio_fondateurId"
                />

                <small>8. lignes maximum</small>
                <br />
                <span
                  className="description-length"
                  style={{
                    color:
                      bio?.length <= 1000
                        ? "var(--secondary-color)"
                        : "#e54747",
                  }}
                >
                  {" "}
                  {bio?.length
                    ? bio?.length +
                      `${bio?.length > 1 ? " caractères" : " caractère"}`
                    : null}{" "}
                </span>
                {formState.errors && formState.errors.bio_fondateur && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.bio_fondateur?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group-container">
              <input
                type="hidden"
                // placeholder="I"
                id="user"
                value={userId}
                name="user"
                {...register("user")}
              />
              {/* <h3>12. Vos coordonnées</h3>
              <div className="row mb-5">
                <div className="col-md-6">
                  <label htmlFor="name">
                    Nom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nom"
                    id="name"
                    name="nom"
                    {...register("nom")}
                    data-testid="nomId"
                  />
                  {formState.errors && formState.errors.nom && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.nom?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="prenom">
                    Prénom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Prénom"
                    name="prenom"
                    id="prenom"
                    {...register("prenom")}
                    data-testid="prenomId"
                  />
                  {formState.errors && formState.errors.prenom && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.prenom?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6">
                  <label htmlFor="fonction">
                    Fonction
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Fonction"
                    id="fonction"
                    name="position"
                    {...register("position")}
                    data-testid="fonctionId"
                  />
                  {formState.errors && formState.errors.position && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.position?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="site">
                    Site
                  </label>
                  <input
                    type="url"
                    placeholder="Site web"
                    name="site"
                    id="site"
                    {...register("site")}
                    data-testid="siteId"
                  />
                  {formState.errors && formState.errors.site && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.site?.message}
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div className="row mb-5">
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    {...register("email")}
                    data-testid="emailId"
                  />
                  {formState.errors && formState.errors.email && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.email?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="tel">
                    Téléphone
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="Téléphone"
                    name="telephone"
                    id="tel"
                    {...register("telephone")}
                    data-testid="telephoneId"
                  />
                  {formState.errors && formState.errors.telephone && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.telephone?.message}
                    </div>
                  )}
                </div>
              </div> */}
            </div>
            <div className="form-group-container">
              <div className="form-textarea-group">
                <label htmlFor="howheardMe" className="label-title">
                  9. Comment avez-vous entendu parler de nous
                </label>
                <textarea
                  name="lieu_connaissance"
                  id="howheardMe"
                  cols="50"
                  rows="10"
                  {...register("lieu_connaissance")}
                  data-testid="lieu_connaissanceId"
                />
                {formState.errors && formState.errors.lieu_connaissance && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.lieu_connaissance?.message}
                  </div>
                )}
              </div>
            </div>
            <DocumentAJoindre {...props} />
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={navigation?.previous}
                className="button-precedent"
                disabled={isLoading}
              >
                PRECEDENT
              </button>
              {!isLoading && (
                <button
                  type="submit"
                  data-testid="btnId"
                  className="button-soumettre"
                >
                  SOUMETTRE
                </button>
              )}

              {isLoading && (
                <button disabled className="button-soumettre">
                  En cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </form>
          <div
            className="modal fade in"
            id="CreerDateLancement"
            tabIndex="-1"
            aria-labelledby="CreerDateLancementLabel"
            aria-hidden="true"
          >
            <CreerUneDatePourLeLancement
              projectId={projectId}
              setProjectId={setProjectId}
              navigation={navigation}
              userType={userType}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default InformationsSuplementairesScumulativeFoundingentreprise;
