import React,{useEffect} from "react";
import InvestisseursTable from "../../../Tables/backend/Investisseurs/InvestisseursTable";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import * as ReactIConly from "react-iconly";
import { Link } from "react-router-dom";
import "./Investisseurs.css";

const Investisseurs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="users-container">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="liste-utilisateurs">
              <div className="liste-projets-bar">
                <div className="liste-projets-info">
                  <h1 className="liste-projets-title">
                    Liste des investisseurs
                  </h1>
                </div>
                <div className="ajouter-projet-link-container">
                  <Link
                    to="/admin/ajout-investisseur/"
                    className="ajouter-projet-link border p-3"
                    style={{ borderRadius: "10px" }}
                  >
                    <ReactIConly.Plus className="ajouter-projet-link-icon" />
                    <span className="ajouter-projet-link-text">
                      Ajouter un investisseur
                    </span>
                  </Link>
                </div>
              </div>
              <div className="users-table pt-1">
                <InvestisseursTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investisseurs;
