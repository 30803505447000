import React, { useEffect } from "react";


const ObjectifDuFinancement = ({
	formData,
	formState,
	clearErrors,
	setValue,
  userType,
  defaultValues
}) => {
	useEffect(() => {
		if (formData?.objectif_financement) {
			setValue(
				"objectif_financement",
				formData.objectif_financement
			);
		}
	}, [formData, setValue]);

	useEffect(() => {
		if (formState.errors) {
			setTimeout(() => {
				Object.entries(formState.errors).map(([key]) => {
					return clearErrors(key);
				});
			}, 5000);
		}
	}, [clearErrors, formState]);

	return (
    <div className="quest-pp-block">
      <h3 className="title-questionnaire">
      {userType === "admin" ? "5. Fond nécessaire pour financer le projet": "5. Quel est votre objectif de financement ?"}
        <span className="text-danger">*</span>
      </h3>
      <div className="form-group">
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="moins"
            value={"moins_1500"}
            name="objectif_financement"
            // {...register("objectif_financement")}
            defaultChecked={formData?.objectif_financement === "moins_1500"}
            onChange={(e) => setValue("objectif_financement", e.target.value)}
            data-testid="objectifId"
            defaultValue={defaultValues?.objectif_financement}
          />

          <label className="quest-label" htmlFor="moins">
            Moins de 1 million FCFA
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="entre"
            value={"entre_1500_et_50000"}
            name="objectif_financement"
            defaultChecked={
              formData?.objectif_financement === "entre_1500_et_50000"
            }
            onChange={(e) => setValue("objectif_financement", e.target.value)}
            data-testid="objectifId"
            // {...register("objectif_financement")}
          />
          <label className="quest-label" htmlFor="entre">
            Entre 1 et 15 millions FCFA
          </label>
        </div>
        <div className="form-quest-check ">
          <input
            type="radio"
            className="quest-control-input"
            id="et"
            value={"entre_50000_et_100000"}
            name="objectif_financement"
            defaultChecked={
              formData?.objectif_financement === "entre_50000_et_100000"
            }
            onChange={(e) => setValue("objectif_financement", e.target.value)}
            data-testid="objectifId"
            // {...register("objectif_financement")}
          />
          <label className="quest-label" htmlFor="et">
            Entre 15 et 100 millions FCFA
          </label>
        </div>
        <div className="form-quest-check ">
          <input
            type="radio"
            className="quest-control-input"
            id="plus"
            value={"plus_100000"}
            name="objectif_financement"
            defaultChecked={formData?.objectif_financement === "plus_100000"}
            onChange={(e) => setValue("objectif_financement", e.target.value)}
            data-testid="objectifId"
            // {...register("objectif_financement")}
          />
          <label className="quest-label" htmlFor="plus">
            Plus de 100 millions FCFA
          </label>
        </div>
        {formState.errors && formState.errors.objectif_financement && (
          <div className="alert alert-danger gfa-alert-danger" role="alert">
            {formState.errors.objectif_financement?.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default ObjectifDuFinancement;
