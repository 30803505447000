import React, {useEffect} from 'react'
import HeaderV2 from '../Navs/HeaderV2'
import NavbarV2 from '../NavbarV2/NavbarV2'
import * as ReactIConly from "react-iconly";
import { Link } from "react-router-dom";
import IncubateurTable from './EntrepreneurTable';
import HeaderComponentV2 from '../../Admin/HeaderComponent/HeaderComponentV2';


function Entrepreneur() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="users-container">
    <HeaderComponentV2 />
    <div className="bloc-principal-navbar-container">
      <div className="navbar-container-box">
        <NavbarV2 />
      </div>
      <div className="bloc-principal-body-container">
        <div className="projets-container bloc-principal-body-content">
          <div className="liste-utilisateurs">
            <div className="liste-projets-bar">
              <div className="liste-projets-info">
                <h1 className="liste-projets-title">
                  Liste des entrepreneurs
                </h1>
              </div>
              <div className="ajouter-projet-link-container">
                <Link
                  to="/incubateur/ajout-entrepreneur"
                  className="ajouter-projet-link border p-3"
                  style={{ borderRadius: "10px" }}
                >
                  <ReactIConly.Plus className="ajouter-projet-link-icon" />
                  <span className="ajouter-projet-link-text">
                    Ajouter un entrepreneur
                  </span>
                </Link>
              </div>
            </div>
            <div className="users-table pt-1">
              <IncubateurTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Entrepreneur;