import React from "react";
import { NavLink } from "react-router-dom";
import LigneBleu from '../../../../../src/images/ligne.png'

const CardTableauDeBord = ({ data }) => {
  return (
    <div className="card ">
      
      <NavLink to={data.link} className="dashboard-card">
      <div className="d-flex justify-content-between align-items-center gap-3">
      <div><img src={LigneBleu} alt="Image"/></div>
       <div>
       <div className="dasboard-card-info">
          <p className="dasboard-card-title">{data.title}</p>
          <p className="dasboard-card-montant">{data.nbr}</p>
        </div>
       
       </div>
      </div>
      </NavLink>
    </div>
  );
};

export default CardTableauDeBord;
