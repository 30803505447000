import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./ProfileAdmin.css";
import profileimage from "../../../../images/others/default-profile-avatar.png";
// import NavbarV2 from "../NavbarV2/NavbarV2";
// import HeaderV2 from "../Navs/HeaderV2";
import { useSelector, useDispatch } from "react-redux";
import baseUrl from "../../../../Http/backend-base-url";
import Swal from "sweetalert2";
import Utilisateur from "../../../Services/Utilisateur";
import { fetchMe } from "../../../../redux/user/userAction";
import AuthServices from "../../../Services/AuthServices";
import HeaderV2Projet from "../NavProjet/HeaderV2";
import NavbarV2 from "../NavbarV2/NavbarV2";
import Profil from "../../../BackendV2/Admin/Parametre/Profil";

const ProfiladminEntrepreneur = () => {
  const user = useSelector((state) => state.user);
  const [avatar, setAvatar] = React.useState(null);
  var [isLoad, setIsLoad] = React.useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg"
    ) {
      return Swal.fire({
        icon: "error",
        title: "Veuiller uploader une image de type jpg ou jpeg ou png",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const fd = new FormData();
    fd.append("avatar", file);
    setAvatar(file);
    setIsLoad(true);
    var request = Utilisateur.onEditUtilisateur(
      user?.data?.id,
      fd,
      requestConfig
    );

    request
      .then(async (resp) => {
        await dispatch(fetchMe());
        await setIsLoad(false);
        await setAvatar(null);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oupss! Une erreur est survenue lors de la modification.",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsLoad(false);
      });
  };

  useEffect(() => {
    register("new_password", {
      required: {
        value: true,
        message: "Le nouveau mot de passe est requis.",
      },
    });
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors, register]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    delete data?.confirm_password;
    // console.log("data", data);
    const request = AuthServices.onUpdatePassword(data, requestConfig);
    await request
      .then((res) => {
        console.log("res", res);
        if (res.status === "success") {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Mot de passe modifié avec succés.",
            showConfirmButton: false,
            timer: 1000,
          });

          e.target.reset();
          reset();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error?.response?.data?.old_password &&
          error?.response?.data?.old_password[0] === "Wrong password."
        ) {
          setError("Mot de passe incorrect");
          //  setIsLoading(false)
          setValue("old_password", "");

          setTimeout(() => {
            setError(null);
          }, 5000);
        } else {
          //   toast.error("Oops! Une erreur est survenue lors de la modification.");
          Swal.fire({
            icon: "error",
            title: "Oops! Une erreur est survenue lors de la modification.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };

  return (
    <div className="users-container">
      <HeaderV2Projet />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
         <NavbarV2 active={3} />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content-profile">
            <div className="profile-containers">
              <h1 className="mon-profil">Mon Profile</h1>
              {/* <div className="d-flex align-items-center flex-column">
                <div className="mb-auto ">
                  <div className="profile-avatar-container">
                    <img
                      src={
                        avatar
                          ? URL.createObjectURL(avatar)
                          : user?.data?.avatar &&
                            !user?.data?.avatar?.includes("default.png")
                          ? baseUrl + user?.data?.avatar
                          : profileimage
                      }
                      className="profile-avatar img-profil-app"
                      alt="Profile"
                    />
                    {isLoad && (
                      <span className="charge">
                        <i
                          className="fas fa-spin fa-spinner"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </span>
                    )}

                    <span>
                      <i className="fas fa-camera"></i>
                      <p>Changer</p>
                      <input
                        type="file"
                        name="avatar"
                        id="file_up"
                        accept="image/*"
                        onChange={changeAvatar}
                      />
                    </span>
                  </div>
                </div>
                <div className="text-jones-admin">
                  <div className="text-jones">
                    {user?.data?.id &&
                      user?.data?.prenom + " " + user?.data?.nom}
                  </div>
                  <p className="text-admin">Entrepreneur</p>
                </div>
              </div> */}
              <div>
              {/* <h2 className="information-personnelle m-0 infos-perso-input">
                    information personnelles
              </h2> */}
                  <Profil/>
              </div>
              <form
                className="form-input-profile"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h2 className="information-personnelle infos-perso-input m-0">
                  Modifer votre mot de passe
                </h2>
                <div className="container-input">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="infos-perso-input">
                        <label className="form-label-text">
                          Ancien mot de passe{" "}
                        </label>
                        <input
                          type="password"
                          {...register("old_password", {
                            required: "L'ancien mot de passe est requis.",
                          })}
                          className="form-control-profil"
                          name="old_password"
                          placeholder="Ancien mot de passe"
                        />
                        {formState?.errors &&
                          formState?.errors?.old_password && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.old_password?.message}
                            </div>
                          )}
                        {error && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {error}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="infos-perso-input">
                        <label className="form-label-text">
                          Nouveau mot de passe{" "}
                        </label>
                        <input
                          type="password"
                          name="new_password"
                          className="form-control-profil"
                          placeholder="Nouveau mot de passe"
                          {...register("new_password", {
                            required: "Le nouveau mot de passe est requis.",
                          })}
                        />
                        {formState?.errors &&
                          formState?.errors?.new_password && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.new_password?.message}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="infos-perso-input">
                        <label className="form-label-text">
                          Confirmer mot de passe{" "}
                        </label>
                        <input
                          type="password"
                          {...register("confirm_password", {
                            required:
                              "La confirmation du mot de passe est requis.",
                            validate: (value) => {
                              return (
                                value === watch("new_password") ||
                                "Veuillez entrer deux mots de passe identiques."
                              );
                            },
                          })}
                          name="confirm_password"
                          className="form-control-profil"
                          placeholder="Confirmer le nouveau mot de passe"
                        />
                        {formState?.errors &&
                          formState?.errors?.confirm_password && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.confirm_password?.message}
                            </div>
                          )}
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="d-flex align-items-end flex-column">
                  <div className="ms-auto">
                    {/* <button type="button" className="btn-modifier">
                      Modifier
                    </button> */}
                    {isLoading && (
                      <button className="btn-modifier" disabled>
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!isLoading && (
                      <button className="btn-modifier">Modifier</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfiladminEntrepreneur;
