import React, { useState } from "react";
import storage from "../../../../../Http/storage";
import LesInteresser from "./LesInteresser";

const DetailsProjetInvetisseurParticipation = ({ project }) => {
  const [isContentVisible, setIsContentVisible] = useState([
    true,
    false,
    false,
    false,
  ]);
  const [isIconExpanded, setIsIconExpanded] = useState([
    false,
    false,
    false,
    false,
  ]);

  const toggleContentVisibility = (index) => {
    const updatedIsContentVisible = [...isContentVisible];
    updatedIsContentVisible[index] = !updatedIsContentVisible[index];
    setIsContentVisible(updatedIsContentVisible);

    const updatedIsIconExpanded = [...isIconExpanded];
    updatedIsIconExpanded[index] = !updatedIsIconExpanded[index];
    setIsIconExpanded(updatedIsIconExpanded);
  };

  return (
    <div
      className="tab-pane fade show active in"
      id="pills-presentation-detail"
      role="tabpanel"
      aria-labelledby="pills-presentation-detail-tab"
    >
      <div className="ccm-section-containers">
        <div className="video-ccm-container ">
          <video controls width="100%" height="auto">
            <source src={storage + project?.video} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="pt-3 card">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <h5
              style={{ color: "#171B1E", fontWeight: "700", fontSize: "23px" }}
            >
              {project?.title || "Non défini"}
            </h5>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="containerInteresse">
              <LesInteresser project={project} />
            </div>
          </div>
        </div>
      </div>
      <div className="description-du-projet">
        <p style={{ fontSize: "17px", color: "#000000", fontWeight: "500" }}>
          Description du projet
        </p>
        <hr style={{ color: "#D3D3D3", fontWeight: 700 }} />
        <div>
          <p>{project?.description ? project?.description : "Non défini"}</p>
        </div>
      </div>
    </div>
  );
};

export default DetailsProjetInvetisseurParticipation;
