import React, { } from "react";
import "./NavbarContent.css";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";

const NavbarContentV2 = ({active}) => {
	return (
		<div className="hoverable" id="scrollbar">
			<div className="contents">
				<ul className="nav nav-items-customs">
					<li className="pt-4 nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 1 && 'active'}`}
							to="/admin/dashboard"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Chart
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Dashboard
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 2 && 'active'}`}
							to="/admin/gestion-des-projets"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Paper
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Projets
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 3 && 'active'}`}
							to="/admin/gestion-des-utilisateurs"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.People
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Utilisateurs
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 4 && 'active'}`}
							to="/admin/gestion-des-incubateurs"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.People
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Incubateurs
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 5 && 'active'}`}
							to="/admin/gestion-des-entrepreneurs"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.People
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Entrepreneurs
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 6 && 'active'}`}
							to="/admin/gestion-des-investisseurs"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.People
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Investisseurs
							</span>
						</NavLink>
					</li>
					{/* <li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 2 && 'active'}`}
							to="/admin/faq"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Chat
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								FAQ
							</span>
						</NavLink>
					</li> */}
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 7 && 'active'}`}
							to="/admin/contacts"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Message
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Contacts
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 8 && 'active'}`}
							to="/admin/parametre"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Setting
									className="icon-navbar-vertical"
									set="bold"
								/>
							</span>
							<span className="text-dashbord">
								Paramètres
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 9 && 'active'}`}
							to="/admin/profil"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.User
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Profil
							</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default NavbarContentV2;
