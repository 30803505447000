import React from "react";
import { Hide, Show } from "react-iconly";
import { useMemo } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import AuthServices from "../../../Services/AuthServices";
import Swal from "sweetalert2";

const ModificationMotDePasseComponent = () => {
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        old_password: yup
          .string()
          .required("Le mot de passe actuel est requis"),
        new_password: yup
          .string()
          .required("vous devez renseignez un nouveau mot de passe")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
            "Le mot de passe doit contenir au minimum 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
          ),
        confirm_password: yup
          .string()
          .required("Ce champ est requis")
          .oneOf(
            [yup.ref("new_password"), null],
            "Veuillez entrer des mots de passe identiques."
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleViewNewPassword = () => {
    const input = document.getElementById("new_password");
    if (viewNewPassword === false) {
      input.setAttribute("type", "text");
      setViewNewPassword(true);
    } else {
      input.setAttribute("type", "password");
      setViewNewPassword(false);
    }
  };
  const onSubmit = async (data) => {
    console.log("data", data);
    setIsSuccessfullySubmitted(true);
    let request = AuthServices.onUpdatePassword(data, config);

    await request
      .then(async (response) => {
        console.log("success", response);
        setIsSuccessfullySubmitted(false);
        reset();
        await Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error?.response);
        setIsSuccessfullySubmitted(false);
        if (error?.response?.data?.old_password[0] === "Wrong password.") {
          console.log("mdp incorrecte");
          Swal.fire({
            icon: "error",
            title: "Le mot de passe que vous avez saisi est incorrecte!",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };
  return (
    <>
      <div className="pt-2 title-block block-mot-de-passe">
        <h1 className="">Changer mon mot de passe</h1>

        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mot-de-passe-block">
            <div className="form-mdp-block">
              <div className="form-label-custom d-flex  p-0">
                <input
                  type="password"
                  className="border-0 p-0 w-full"
                  placeholder="Mot de passe actuel"
                  id="old_password"
                  name="old_password"
                  data-testid="oldPasswordId"
                  {...register("old_password")}
                />
                <Show set="bold" primaryColor="rgba(0,0,0,0.5)" />
              </div>
              {errors.old_password && (
                <small className="text-danger" role="alert">
                  {errors.old_password?.message}
                </small>
              )}
            </div>
            <div className="form-mdp-block">
              <div className="form-label-custom d-flex  p-0">
                <input
                  type="password"
                  placeholder="Nouveau mots de passe"
                  id="new_password"
                  name="new_password"
                  data-testid="newPasswordId"
                  className="border-0 p-0 w-full"
                  {...register("new_password")}
                />
                {!viewNewPassword ? (
                  <Show
                    set="bold"
                    primaryColor="rgba(0,0,0,0.5)"
                    onClick={handleViewNewPassword}
                  />
                ) : (
                  <Hide
                    set="bold"
                    primaryColor="rgba(0,0,0,0.5)"
                    onClick={handleViewNewPassword}
                  />
                )}
              </div>
              {errors.new_password && (
                <small className="text-danger" role="alert">
                  {errors.new_password?.message}
                </small>
              )}
            </div>
            <div className="form-mdp-block">
              <div className="form-label-custom d-flex p-0">
                <input
                  type="password"
                  className="border-0 p-0 w-full"
                  placeholder="Confirmation du nouveau mot de passe"
                  id="confirm_password"
                  name="confirm_password"
                  data-testid="confirmPasswordId"
                  {...register("confirm_password")}
                />
                <Show set="bold" primaryColor="rgba(0,0,0,0.5)" />
              </div>
              {errors.confirm_password && (
                <small className="text-danger" role="alert">
                  {errors.confirm_password?.message}
                </small>
              )}
            </div>
            {!isSuccessfullySubmitted ? (
              <div className="btn-container">
                <button
                  className="button-mot-de-passe"
                  type="submit"
                  data-testid="btnChangeMotDePasseId"
                >
                  Changer Mot de passe
                </button>
              </div>
            ) : (
              <div className="btn-container">
                <button className="button-mot-de-passe" type="submit">
                  En cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ModificationMotDePasseComponent;
