import React, { useRef } from "react";
import { Bar, Line } from "react-chartjs-2";
import "./chart.css";

function GrapheEvolution() {
  const chartRef = useRef(null);

  const data = {
    labels: ["Lun", "Mar", "Mer", "Jeu"],
    datasets: [
      {
        label: "Données",
        data: [0, 1, 19, 0],
        backgroundColor: [
          "rgba(54, 158, 138, 1)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(54, 158, 138, 1)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(54, 158, 138, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 158, 138, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 2,
      },
      {
        label: "Revenue",
        data: [10, 6, 13, 5],
        type: 'line', // This specifies that this dataset should be rendered as a line
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        fill: false, // Prevents the area under the line from being filled
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Désactiver l'affichage de la légende
      },
    },
  };

  return (
    <div className="container">
      <div className="chartCard">
        <div className="chartBox">
          <Bar ref={chartRef} data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default GrapheEvolution;
