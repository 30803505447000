import React, { useEffect, useState } from "react";
import * as ReactIConly from "react-iconly";
import { fetchProjects } from "../../../../redux/projects/projectAction.js";
import { useDispatch } from "react-redux";
// import "./Projets.css";
import { Link } from "react-router-dom";
import HeaderV2 from "../Navs/HeaderV2.js";
import NavbarV2 from "../NavbarV2/NavbarV2.js";
import { useLocation } from "react-router-dom";
import ProjetTable from "./ProjetTable.js";


const Projets = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProjects());
  },);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const url = params.get("url");
    if (url === "projetRejetes") {
      setActiveTab(1);
    } else if(url === "projetValides") {
      setActiveTab(0);
    }else if(url === "projetEnAttente") {
      setActiveTab(2);
    } else{
      return("/admin/gestion-des-projets");
    }
  }, [location.search]);

  return (
    <div className="users-container">
      <HeaderV2 />

      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="liste-projets">
              <div className="liste-projets-bar">
                <div className="liste-projets-info">
                  <h1 className="liste-projets-title">Liste des projets</h1>
                </div>
                <div className="ajouter-projet-link-container">
                  <Link
                    to="/incubateur/ajouterprojet/"
                    className="ajouter-projet-link border p-3"
                    style={{borderRadius: "10px"}}
                  >
                    <ReactIConly.Plus className="ajouter-projet-link-icon" />
                    <span className="ajouter-projet-link-text">
                      Ajouter un projet
                    </span>
                  </Link>
                </div>
              </div>
              <div className="projets-table pt-5">
              <div className="py-3 mb-3 d-flex menu">
                <ul className="detail-projets-tabs flex-column align-items-start flex-md-row align-items-md-center p-0">
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 0 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(0)}
                  >
                    Projets validés
                  </li>
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 1 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    Projets rejetés
                  </li>
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 2 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(2)}
                  >
                    Projets soumis
                  </li>
                </ul>
              </div>
              {activeTab === 0 && <ProjetTable categorie={(project) => project.statut === "valider"} />}
              {activeTab === 1 && <ProjetTable categorie={(project) => project.statut === "rejeter"} />}
              {activeTab === 2 && <ProjetTable categorie={(project) => project.statut === "nouveau"} />}
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projets;
