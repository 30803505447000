import React, { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useNavigate } from "react-router-dom";
import ProjetsSkeleton from "../../../Tables/backend/Projets/ProjetsSkeleton";
import { useSelector } from "react-redux";
import financement from "../../../../services/financement";

const ProjetsTable = (props) => {
  const navigate = useNavigate();
  const projectts = useSelector((state) => state.projects);
  console.log({projectts});
  const [filter, setFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [investissements, setInvestissements] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const request = financement.getAllFinancement(config);
  request.then((res) => {
    setInvestissements(res.results);
  });

  const redirectionFormatter = (cell, row) => {
    return (
      <div>
        <span
          className="redirection-projet"
          onClick={() => navigate(`/admin/detail-projet/${row.id}`)}
        >
          {cell}{" "}
        </span>
      </div>
    );
  };
  
  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée",
  };

  const montantFormatter = (cell, row) => {
    if (cell === "moins_1500") {
      return `Moins de 1 500£`;
    } else if (cell === "entre_1500_et_50000") {
      return `Entre 1 500£ et 50 000£`;
    } else if (cell === "entre_50000_et_100000") {
      return `Entre 50 000£ et 100 000£`;
    } else if (cell === "plus_de_100000") {
      return `Plus de 100 000£`;
    }

    return null;
  };

  const nameFormatter = (cell, row) => {
    const fullName = row.user?.prenom ? `${row.user?.prenom} ${row.user?.nom}` : row.user?.nom;
    return (
      <div className="name-email-group">
        <span className="name">{fullName}</span>
        <span className="email">{cell}</span>
      </div>
    );
  };

  const filterProjects = () => {
    const filteredProjects = projectts.data.filter(
      (projet) =>
        projet.title.toLowerCase().includes(filter.toLowerCase()) ||
        projet.prenom.toLowerCase().includes(filter.toLowerCase()) ||
        projet.email.toLowerCase().includes(filter.toLowerCase()) ||
        projet.objectif_financement.toLowerCase().includes(filter.toLowerCase())
    );

    // switch (statusFilter) {
    //   case "lancer":
    //     return filteredProjects.filter((project) => project.statut === "valider");
    //   default:
        
    // }
    switch (countryFilter) {
      case "senegal":
        return filteredProjects.filter(
          (project) => project.senegal === true
        );
      default:
        return filteredProjects;
    }
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {filterProjects()?.isLoading && <ProjetsSkeleton />}
            {!filterProjects()?.isLoading && (
              <div className="table-container-activite">
                <BootstrapTable
                  data={(filterProjects() || []).filter(props.categorie || (() => true))}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      whiteSpace: "normal",
                    }}
                    width="100"
                    dataField="title"
                    dataFormat={(cell, row) => redirectionFormatter(cell, row)}
                  >
                    Nom du projet
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      whiteSpace: "normal",
                    }}
                    width="100"
                    dataField="email"
                    dataFormat={(cell, row) => nameFormatter(cell, row)}
                  >
                    Nom de l'entrepreneur
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                    }}
                    width="120"
                    dataField="objectif_financement"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                  >
                    Montant demandé
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjetsTable;
