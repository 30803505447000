import React, { useEffect, useState } from "react";
import "./Resume.css";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
import financement from "../../../../services/financement";
import { useSelector } from "react-redux";
import ProjetsFinance from "./ListeProjetFinanceDetail";

const Resume = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-investisseur">
      <HeaderV2 />

      <SidebarTableauBord />
      <div className="p-5 pe-3 ps-0 section-content-page-tb-investisseur">
        <div className="bg-white mt-2 p-3">
          <div className="page-first-row-container border-bottom">
            <div className="page-title-container pt-3">
              <ProjetsFinance/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
