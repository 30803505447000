import React from "react";

function TitreTab() {
  return (
    <div>
      <ul
        className="nav nav-pills d-flex mb-3 pb-4 gap-3"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            className="nav-link text-center active"
            id="pills-presentation-detail-tab"
            data-bs-toggle="pill"
            href="#pills-presentation-detail"
            role="tab"
            aria-controls="pills-presentation-detail"
            aria-selected="true"
          >
            Details
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link text-center"
            id="pills-donnee-tab"
            data-bs-toggle="pill"
            href="#pills-donnee"
            role="tab"
            aria-controls="pills-donnee"
            aria-selected="false"
          >
            Données financières
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link text-center"
            id="pills-actu-tab"
            data-bs-toggle="pill"
            href="#pills-actu"
            role="tab"
            aria-controls="pills-actu"
            aria-selected="false"
          >
            Document
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link text-center"
            id="pills-actu-tab"
            data-bs-toggle="pill"
            href="#pills-info"
            role="tab"
            aria-controls="pills-info"
            aria-selected="false"
          >
            Informations porteur de projet
          </a>
        </li>
      </ul>
    </div>
  );
}

export default TitreTab;
