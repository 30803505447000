import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./QuestionaireProjet.css";

const ModeFinancement = ({ formData, formState, clearErrors, setValue ,open, onCloseModal,navigation, userType }) => {
	useEffect(() => {
		if (formData?.mode_financement) {
			setValue("mode_financement", formData.mode_financement);
		}
	}, [formData, setValue]);

	useEffect(() => {
		if (formState.errors) {
			setTimeout(() => {
				Object.entries(formState.errors).map(([key]) => {
					return clearErrors(key);
				});
			}, 5000);
		}
	}, [clearErrors, formState]);

	
	return (
    <div className="quest-pp-block">
      <h3 className="title-questionnaire">
      {userType === "admin" ? "5. Définissez le mode de finacement(deux choix maximum)": "5. Quel mode de financement souhaiteriez-vous ? (deux choix maximum)"}
        <span className="text-danger">*</span>
      </h3>
      <div className="form-group">
      <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input "
            id="love_money"
            name="mode_financement"
            value={"love_money"}
            defaultChecked={formData?.mode_financement === "love_money"}
            onChange={(e) => setValue("mode_financement", e.target.value)}
            data-testid="mode_financementId"
          />
          <label className="quest-label" htmlFor="loveMoney">
            Love money
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input "
            id="obligation"
            name="mode_financement"
            value={"obligation"}
            defaultChecked={formData?.mode_financement === "obligation"}
            onChange={(e) => setValue("mode_financement", e.target.value)}
            data-testid="mode_financementId"
          />
          <label className="quest-label" htmlFor="loveMoney">
            Prêt / Obligations
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input "
            id="action"
            name="mode_financement"
            value="action"
            defaultChecked={formData?.mode_financement === "action"}
            onChange={(e) => setValue("mode_financement", e.target.value)}
            data-testid="mode_financementId"
          />
          <label className="quest-label" htmlFor="action">
            Actions
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input "
            id="don"
            value={"don"}
            name="mode_financement"
            defaultChecked={formData?.mode_financement === "don"}
            onChange={(e) => setValue("mode_financement", e.target.value)}
            data-testid="mode_financementId"
          />
          <label className="quest-label" htmlFor="don">
            Donation
          </label>
        </div>
        {formState.errors && formState.errors.mode_financement && (
          <div className="alert alert-danger gfa-alert-danger" role="alert">
            {formState.errors.mode_financement?.message}
          </div>
        )} 
        {/* <div className="questionnaire-button-container">
					<button
						className="btn-se-financer-part a-link"
						// onClick={onOpenModal}
						type="submit"
						data-testid="btnId"
					>
						CONTINUER
					</button>
				</div> */}
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "customModal-questionnaire",
        }}
      >
        <div className="modal-mode-financement-content">
          <h2 className="modal-mode-financement-title">
            Super!
            <br /> Continuons avec le mode{" "}
            {formData?.mode_financement === "obligation"
              ? "Prêt / Obligations"
              : formData?.mode_financement === "action"
              ? "Action"
              : formData?.mode_financement === "don"
              ? "Donation"
              : formData?.mode_financement === "love_money"
              ? "Love money"
              : null}
            !
          </h2>
          <div className="m-auto text-center mt-input-part-project">
            <button
              className="btn-questionnaire-confimation-non a-link mr-5"
              onClick={onCloseModal}
            >
              Non
            </button>
            <button
              className="btn-se-financer-part a-link"
              onClick={navigation?.next}
            >
              Oui
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModeFinancement;
