import React from 'react'

import Elikia from "../../../../src/images/logoElikia.png";
import Volkeno from "../../../../src/images/Frame.png";

function Partenaire(props) {
  return (
    <div className="pt-0 container-cayore partenaire-section-container">
          <div className="partenaire-card-conten">
            <h1 className={`${props.stylePartenaire}`}>Nos partenaires</h1>
          </div>
          <div className="row partenaires-logos-row">
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={Elikia}
                  className="partenaire-logo"
                  alt="Elikia"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={Volkeno}
                  className="partenaire-logo"
                  alt="Volkeno"
                />
              </div>
            </div>
          </div>
        </div>
  )
}

export default Partenaire