import React,{useEffect} from 'react'
import { useSelector } from "react-redux";
import UseEditParam from "./UseEditParam";

function Profil() {
    const {
        register,
        onSubmit,
        formState,
        isSuccessfullySubmitted,
        setValue,
        clearErrors,
      } = UseEditParam();
    
      const user = useSelector((state) => state.user);
    
      useEffect(() => {
        if (formState.errors) {
          setTimeout(() => {
            Object.entries(formState.errors).map(([key]) => {
              return clearErrors(key);
            });
          }, 3000);
        }
      }, [formState]);
    
      useEffect(() => {
        if (user?.data?.id) {
          setValue("prenom", user?.data?.prenom);
          setValue("nom", user?.data?.nom);
          setValue("email", user?.data?.email);
          setValue("phone", user?.data?.phone);
          setValue("adresse", user?.data?.adresse);
          register("id");
          setValue("id", user?.data?.id);
        }
      }, [user]);
    
  return (
    <div>
        <form className="pt-0 form-input-profile" onSubmit={onSubmit}>
                <div className="container-input-address">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Nom</label>
                        <input
                          type="text"
                          className="form-control-profil"
                          name="nom"
                          {...register("nom")}
                          placeholder="Nom"
                        />
                        {formState?.errors && formState?.errors?.nom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.nom?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Prenom</label>
                        <input
                          type="text"
                          {...register("prenom")}
                          className="form-control-profil"
                          name="prenom"
                          placeholder="Prénom"
                        />
                        {formState?.errors && formState?.errors?.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.prenom?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Téléphone</label>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control-profil"
                          placeholder="Téléphone"
                          {...register("phone")}
                        />
                        {formState?.errors && formState?.errors?.phone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.phone?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control-profil"
                          placeholder="email"
                          {...register("email")}
                          readOnly
                        />
                        {formState?.errors && formState?.errors?.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.email?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Addresse</label>
                        <input
                          type="Addresse"
                          className="form-control-profil"
                          placeholder="Adresse"
                          name="adresse"
                          {...register("adresse")}
                        />
                        {formState?.errors && formState?.errors?.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.adresse?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end flex-column">
                  <div className="ms-auto">
                    {isSuccessfullySubmitted && (
                      <button className="btn-modifier" disabled>
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!isSuccessfullySubmitted && (
                      <button className="btn-modifier">Modifier</button>
                    )}
                  </div>
                </div>
              </form>
    </div>
  )
}

export default Profil