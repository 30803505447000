import React, { useMemo, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-input-2/lib/style.css";
import HeaderV2 from "../Navs/HeaderV2";
import { useNavigate } from "react-router-dom";
import NavbarV2 from "../NavbarV2/NavbarV2";
import Entrepreneur from "../../../../services/entrepreneur"
import Swal from "sweetalert2";
import { useSelector } from "react-redux";


function AjoutEntrepreneur({ user }) {
    const config = {
		headers: {
			Authorization: `Bearer  ${sessionStorage.getItem(
				"userToken"
			)}`,
		},
	};
    // console.log("token", config);
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  const navigate = useNavigate();
  const current_user = useSelector((state) => state.user).data;
    console.log(current_user)
  const Backspace = () => {
  navigate('/incubateur/gestion-des-entrepreneurs');
}
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email("Email incorrect!").required("L'email est requis."),
        prenom: yup.string().required("Le prénom est requis."),
        nom: yup.string().required("Le nom est requis."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
        sexe: yup.string().nullable().required("Le sexe est requis."),
      }),
    []
  );
  const { register, setValue, handleSubmit, clearErrors, formState } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  const onSubmit = async (data) => {
    console.log({data});
    setIsSuccessfullySubmitted(true); 
    
    const formData = new FormData();
    formData.append("prenom", data.prenom);
    formData.append("nom", data.nom);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("sexe", data.sexe);
    formData.append("incubateur", current_user.id);
  
    try {
      const response = await Entrepreneur.postEntrepreneur(formData, config);
      console.log("response", response);
      await Swal.fire({
        position: "center",
        icon: "success",
        title: "<h5>L'entrepreneur a été ajouté avec succés! Il peut vérifier sa boîte mail pour récupérer ses identifiants afin de se connecter.</h5>",
        showConfirmButton: false,
        iconColor: "var(--secondary-color)",
        timer: 1500,
      });
      window.location.href = ("/incubateur/gestion-des-entrepreneurs");
    } catch (error) {
      console.log("error", error?.response);
      setIsSuccessfullySubmitted(false);
      if (error?.response?.data?.message) {
        Swal.fire({
          icon: "error",
          title: error?.response?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la soumission!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };
  
  return (
    <div className="easypm-modal-dialog modal-lg">
      <HeaderV2 />
      <div className="users-container">
        <NavbarV2 active={2} />
        <div className="bloc-principal-body-ajoutI">
          <div className="ajout-form">
            <h5 className="liste-projets-title">Ajouter un entrepreneur</h5>
            <div className="add-userAdmin-form-container">
              <form onSubmit={handleSubmit(onSubmit)} className="formCrud">
                <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="prenom">
                      Prénom <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      className="form-control crud-form-control"
                      id="prenom"
                      {...register("prenom")}
                    />
                  </div>
                  {formState?.errors && formState?.errors?.prenom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.prenom?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="nom">
                      Nom <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      className="form-control crud-form-control"
                      id="nom"
                      {...register("nom")}
                    />
                  </div>
                  {formState?.errors && formState?.errors?.nom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.nom?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">
                      Email <strong style={{ color: "#BB4411" }}>*</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Adresse email"
                      name="email"
                      className="form-control crud-form-control"
                      id="email"
                      {...register("email")}
                    />
                  </div>
                  {formState?.errors && formState?.errors?.email && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.email?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="phone">
                      Télephone<strong style={{ color: "#BB4411" }}>*</strong>{" "}
                    </label>
                    <input
                      type="tel"
                      placeholder="phone"
                      name="phone"
                      className="form-control crud-form-control"
                      id="phone"
                      {...register("phone")}
                    />
                  </div>
                  {formState?.errors && formState?.errors?.phone && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.phone?.message}
                    </div>
                  )}
                </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="sexe">Sexe</label>
                      <select
                        placeholder="sexe"
                        name="sexe"
                        //   {...register("sexe")}
                        onChange={(e) => setValue("sexe", e.target.value)}
                        className="form-control"
                        style={{
                          height: "40px",
                          padding: "8px",
                          borderRadius: "10px",
                        }}
                        defaultValue={user?.sexe || ""}
                        id="sexe"
                      >
                        <option value="" hidden>
                          Choix du sexe
                        </option>

                        <option value="homme">Homme</option>
                        <option value="femme">Femme</option>
                      </select>
                    </div>
                    {formState?.errors && formState?.errors?.sexe && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.sexe?.message}
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-end crud-form-action-btn-container">
                    <div className="d-flex align-items-center gap-4">
                      <button className="btn btn-secondary cancelleb-btn" type="button" onClick={Backspace}>
                        Retour
                      </button>
                      {!isSuccessfullySubmitted && (
                        <button
                          type="submit"
                          className="btn btn-primary crud-submit-btn"
                        >
                          Enregistrer
                        </button>
                      )}
                      {isSuccessfullySubmitted && (
                        <button
                          disabled
                          className="auth-submit-btn btn btn-success"
                        >
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
              </div>                 
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutEntrepreneur;
