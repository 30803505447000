import React, { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import UsersSkeleton from "./UsersSkeleton";
import "./UsersTable.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import UserDetails from "./modalUser/UserDetails";
import UserEditModal from "./modalUser/UserEditModal";
import { fetchUsers } from "../../../../redux/users/usersAction";
import Swal from "sweetalert2";
import Axios from "axios";
import api from "../../../../Http/global-vars";

const UsersTable = ({users ,user }) => {
  // const users = useSelector((state) => state.users);
  // console.log({users});
  
  //filtre
  const [valeurFiltre, setValeurFiltre] = useState("");
  const handleChangementFiltre = (e) => {
    setValeurFiltre(e.target.value);
  };
  
 
  // const utilisateursFiltres = users.data && Array.isArray(users.data)
  // ? users.data.filter((utilisateur) => {
  //   console.log("utilisateur", utilisateur);
      // const nomComplet =
      //   utilisateur?.prenom + " " + utilisateur?.nom + " " + utilisateur?.email + " " + utilisateur?.user_type + " " + utilisateur?.phone;
      //   const profilAffiche = utilisateur?.user_type === "standard" ? "Entrepreneur" : utilisateur?.user_type;
      //   return nomComplet.toLowerCase().includes(valeurFiltre.toLowerCase()) ||
      //   profilAffiche.toLowerCase().includes(valeurFiltre.toLowerCase());
  //   })
  // : [];
  const dispatch = useDispatch();
  
  const utilisateursFiltres = users.data && Array.isArray(users.data)
       ? users.data.filter(user)
       : [];

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        item?.is_active ? "désactiver" : "activer"
      } cet utilisateur?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      confirmButtonColor: "var(--secondary-color)",
      cancelButtonColor: "#E53E3E",
      preConfirm: () => {
        return Axios.put(
          `${api}user/${item?.id}/`,
          { is_active: !item?.is_active },
          config
        )
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchUsers());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Utilisateur ${
            item?.is_active ? "désactivé" : "activé"
          } avec succès.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.prenom + " " + row?.nom}</span>
        <span className="email">{cell}</span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <button
          data-bs-toggle="modal"
          data-bs-target={"#DetailsUserModal" + row.id}
          style={{ color: "#9B51E0", backgroundColor: "transparent" }}
          className="mr-2"
        >
          <IconlyPack.Show />
        </button>
        <button
          data-bs-toggle="modal"
          data-bs-target={`#EditUserModal${row?.id}`}
          style={{ color: "#2D3748", backgroundColor: "transparent" }}
          className="mr-2"
          title="Modifier"
        >
          <IconlyPack.Edit set="light" style={{ fontSize: "20px" }} />
        </button>
        {!row?.is_active ? (
          <button
            style={{ color: "var(--secondary-color)", backgroundColor: "transparent" }}
            className="mr-2"
            title="Activer"
            onClick={() => onDelete(row)}
          >
            <IconlyPack.TickSquare set="light" style={{ fontSize: "20px" }} />
          </button>
        ) : (
          <button
            style={{ color: "#E53E3E", backgroundColor: "transparent" }}
            className="mr-2"
            title="Désactiver"
            onClick={() => onDelete(row)}
          >
            <IconlyPack.Delete set="light" style={{ fontSize: "20px" }} />
          </button>
        )}

        <div
          className="modal fade in"
          id={"DetailsUserModal" + row.id}
          tabIndex="-1"
          aria-labelledby="CreerDateLancementLabel"
          aria-hidden="true"
        >
          <UserDetails user={row} />
        </div>
        <div
          className="modal fade in"
          id={`EditUserModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="EditUserModalLabel"
          aria-hidden="true"
        >
          <UserEditModal user={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}
        <div>
        <div className="filtres-container">
          {/* <h6 className="filter-label">Filter</h6> */}
          {/* <div className="filter-input-group">
            <input
              type="text"
              className="filter-input"
              placeholder="Entrer un nom"
              value={valeurFiltre}
              onChange={handleChangementFiltre}
            />
          </div> */}
        </div>
        </div>
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {users?.isLoading && <UsersSkeleton />}
            {!users?.isLoading && (
              <div className="table-container-activite">
                <BootstrapTable
                  data={utilisateursFiltres}
                  hover={true}
                  condensed={true}
                  multiColumnSort={4}
                  options={options}
                  version="4"
                  pagination
                  bordered={false}
                >
                  <TableHeaderColumn
                  isKey={true}
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      zIndex: 0,
                      position: "static"
                    }}
                    width="150"
                    dataField="email"
                    dataFormat={(cell, row) => nameFormatter(cell, row)}
                  >
                    Nom + Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      zIndex: 0,
                      position: "static"
                    }}
                    width="100"
                    dataField="user_type"
                    // dataFormat={(cell, row) =>
                    //   cell === "standard" ? "Entrepreneur" : cell
                    // }
                  >
                    Profil
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      zIndex: 0,
                      position: "static"
                    }}
                    width="100"
                    dataField="phone"
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      zIndex: 0,
                      position: "static"
                    }}
                    width="100"
                    dataField="created_at"
                    dataFormat={(cell, row) =>
                      moment(cell).format("DD/MM/YYYY")
                    }
                  >
                    Date d'inscription
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    dataField="id"
                    width="100"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
