import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const QuestionnaireSecteur = ({
  formData,
  formState,
  clearErrors,
  setValue,
  errorSecteur,
  userType,
  defaultValues
}) => {
  const secteurs = useSelector((state) => state.secteurs);
  const [data, setData] = useState([]);
  const [otherChecked, setOtherChecked] = useState(false);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  const onChange = (e) => {
    if (e.target.checked) {
      if (e.target.value === "other") {
        setData([e.target.value]);
      } else {
        setData([parseInt(e.target.value)]);
      }
    } else {
      if (e.target.value === "other") {
        setData(data.filter((val) => val !== "other"));
      } else {
        setData(data.filter((val) => parseInt(val) !== parseInt(e.target.value)));
      }
    }

    if (e.target.value === "other") {
      setOtherChecked(!otherChecked);
    }

    setData((prevData) => {
      setValue("secteur", prevData);
      console.log(prevData);
      return prevData;
    });
    
  };
  return (
    <div className="quest-pp-block">
      <h3 className="title-questionnaire">
      {userType === "admin" ? "Choisissez la catégorie du projet": "3. Dans quel secteur d’activités opérez-vous ?"}
        
        <span className="text-danger">*</span>
      </h3>
      <div className="form-group">
        {!secteurs?.isLoading &&
          (secteurs?.data?.length ? (
            <>
              {secteurs.data
                .sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((secteur) => (
                  <div className="form-quest-check" key={secteur?.id}>
                  <input
                  type="radio"
                  className="quest-control-input"
                  id={secteur?.id}
                  defaultChecked={
                    formData?.secteur?.length &&
                    formData?.secteur.includes(secteur?.id)
                  }
                  name="secteur"
                  value={secteur?.id}
                  onChange={onChange}
                  data-testid="secteurId"
                  defaultValue={defaultValues?.secteur}
                />
                
                    <label className="quest-label" htmlFor={secteur?.id}>
                      {secteur?.name}
                    </label>
                  </div>
                ))}
              <div className="form-quest-check">
                <input
                  type="radio"
                  className="quest-control-input"
                  id="other"
                  name="secteur"
                  value="other"
                  onChange={onChange}
                  data-testid="secteurId"
                />
                <label className="quest-label" htmlFor="other">
                  Autre
                </label>
              </div>
              {otherChecked && (
                <div>
                  <input
                    className="input-part-du-project-other"
                    type="text"
                    placeholder="Saisissez votre secteur d'activité"
                    onChange={(e) => setValue("otherSecteur", e.target.value)}
                    data-testid="autreSecteurId"
                  />
                  {errorSecteur && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {errorSecteur}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="gfa-rowy mt-5">
              <div className="alert alert-warning gfa-warning">
                Aucun secteur disponible pour le moment
              </div>
            </div>
          ))}
        {formState.errors && formState.errors.secteur && (
          <div className="alert alert-danger gfa-alert-danger" role="alert">
            {formState.errors.secteur?.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionnaireSecteur;
