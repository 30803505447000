import React, { useMemo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Utilisateur from "../../../../Services/Utilisateur";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../../../../redux/users/usersAction";

function UserEditModal({ user }) {
  const dispatch = useDispatch();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const token = window.sessionStorage.getItem("userToken");

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("Email incorrect!")
          .required("L'email est requis."),

        prenom: yup.string().required("Le prénom est requis."),
        nom: yup.string().required("Le nom est requis."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
        adresse: yup.string().nullable(),
      }),
    []
  );
  const { register, setValue, handleSubmit, clearErrors, formState, reset } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (user?.id) {
      setValue("prenom", user?.prenom);
      setValue("nom", user?.nom);
      setValue("adresse", user?.adresse);
      setValue("email", user?.email);
      setValue("phone", user?.phone);
      // console.log(user);
    }
    return () => {
      reset();
    };
  }, [reset, setValue, user]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsSuccessfullySubmitted(true);
    let request = Utilisateur.onEditUtilisateur(user?.id, data, requestConfig);
    await request
      .then(async (response) => {
        console.log("response update user", response);

        await dispatch(fetchUsers());
        await Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 1500,
        });
        await window.$(`#EditUserModal${user?.id}`).modal("hide");
        // await window.$(`#EditUserModal${user?.id} .close`).click();
        await setIsSuccessfullySubmitted(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.message &&
          !error?.response?.data?.error?.email?.length
        ) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (
          (error?.response?.data?.email?.length ||
            error?.response?.data?.error?.email?.length) &&
          (error?.response?.data?.email[0] ===
            "user with this email already exists." ||
            error?.response?.data?.error?.email[0] ===
              "user with this email already exists.")
        ) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title:
              "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          //   toast.error("Veuillez vérifier votre connexion internet.");admin
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Modifier un utilisateur
          </h5>
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="add-userAdmin-form-container">
            <form onSubmit={handleSubmit(onSubmit)} className="crud-form">
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="prenom">
                      Prénom <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      {...register("prenom")}
                      className="form-control crud-form-control"
                      id="prenom"
                    />
                  </div>
                  {formState?.errors && formState?.errors?.prenom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.prenom?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="nom">
                      Nom <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register("nom")}
                      className="form-control crud-form-control"
                      id="nom"
                    />
                  </div>
                  {formState?.errors && formState?.errors?.nom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.nom?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="adresse">Adresse </label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                  </div>
                  {formState?.errors && formState?.errors?.adresse && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.adresse?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="phone">
                      Télephone<strong style={{ color: "#BB4411" }}>*</strong>{" "}
                    </label>
                    <input
                      type="tel"
                      placeholder="phone"
                      name="phone"
                      {...register("phone")}
                      className="form-control crud-form-control"
                      id="phone"
                    />
                  </div>
                  {formState?.errors && formState?.errors?.phone && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.phone?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">
                      Email <strong style={{ color: "#BB4411" }}>*</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Adresse email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                    />
                  </div>
                  {formState?.errors && formState?.errors?.email && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.email?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="user_type">Profil</label>
                    <select
                      placeholder="Profil"
                      name="user_type"
                      defaultValue={user?.user_type || ""}
                      //   {...register("user_type")}
                      onChange={(e) => setValue("user_type", e.target.value)}
                      className="form-control p-8"
                      style={{
                        height: "40px",
                        padding: "8px",
                        borderRadius: "10px",
                      }}
                      id="user_type"
                    >
                      <option value="" hidden>
                        Choix du type d'utilisateur
                      </option>

                      <option value="standard">Standard</option>
                      <option value="investisseur">Investisseur</option>
                    </select>
                  </div>
                  {formState?.errors && formState?.errors?.user_type && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.user_type?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="user_type">Sexe</label>
                    <select
                      placeholder="sexe"
                      name="sexe"
                      //   {...register("sexe")}
                      onChange={(e) => setValue("sexe", e.target.value)}
                      className="form-control"
                      style={{
                        height: "40px",
                        padding: "8px",
                        borderRadius: "10px",
                      }}
                      defaultValue={user?.sexe || ""}
                      id="sexe"
                    >
                      <option value="" hidden>
                        Choix du sexe
                      </option>

                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                    </select>
                  </div>
                  {formState?.errors && formState?.errors?.sexe && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.sexe?.message}
                    </div>
                  )}
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-bs-dismiss="modal"
                  >
                    Retour
                  </button>
                  {!isSuccessfullySubmitted && (
                    <button
                      className="btn btn-primary crud-submit-btn"
                      type="submit"
                    >
                      Enregistrer
                    </button>
                  )}
                  {isSuccessfullySubmitted && (
                    <span
                      className="btn btn-primary crud-submit-btn w-50"
                      style={{ padding: "15px" }}
                    >
                      En cours&nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEditModal;
