import { FETCH_SOUSCRIRE } from "../types";

const initialState = {
  isLoading: true,
  count: 0,
  results: [], // tableau des souscriptions
  byProject: {}, // objet pour stocker les souscriptions par projet
};

export const souscrireReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOUSCRIRE:
      const byProject = {};
      action.payload.results.forEach((souscription) => {
        const projetId = souscription.projet.id;
        if (!byProject[projetId]) {
          byProject[projetId] = [];
        }
        byProject[projetId].push(souscription);
      });

      return {
        ...state,
        isLoading: false,
        count: action.payload.count,
        results: action.payload.results,
        byProject: byProject,
      };

    default:
      return state;
  }
};
