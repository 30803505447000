import React, { useEffect, useState } from "react";
import UsersTable from "../../../Tables/backend/Users/UsersTable";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import * as ReactIConly from "react-iconly";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Users.css";

const UsersIncubateur = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const users = useSelector((state) => state.users);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const url = params.get("url");
    if (url === "investisseur") {
      setActiveTab(3);
    } else if (url === "incubateur") {
      setActiveTab(1);
    } else if (url === "entrepreneur") {
      setActiveTab(2);
    } else {
      return "/admin/gestion-des-utilisateurs";
    }
  }, [location.search]);
  useEffect(() => {
    if (activeTab === 3) {
      // Filtrer les investisseurs
      // Vous pouvez mettre à jour l'état ou appeler une fonction pour passer les utilisateurs filtrés à votre composant UsersTable
      console.log("Afficher les investisseurs");
    } else if (activeTab === 1) {
      // Filtrer les incubateurs
      console.log("Afficher les incubateurs");
    } else if (activeTab === 2) {
      // Filtrer les entrepreneurs
      console.log("Afficher les entrepreneurs");
    }else if (activeTab === 0) {
      // Filtrer les entrepreneurs
      console.log("Afficher les utilisateurs");
    }
  }, [activeTab, users]);

  return (
    <div className="users-container">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="liste-utilisateurs">
              <div className="liste-projets-bar">
                <div className="liste-projets-info">
                  <h1 className="liste-projets-title">
                    Liste des utilisateurs
                  </h1>
                </div>
                <div className=" d-flex menu">
                  <ul className="detail-projets-tabs flex-column align-items-start flex-md-row align-items-md-center p-0">
                  <li
                      className={`detail-projets-tabs-item ${
                        activeTab === 0 && "active-tab"
                      }`}
                      onClick={() => {
                        // console.log("Investisseurs tab clicked");
                        setActiveTab(0);
                      }}
                    >
                      Tous les utilisateurs
                    </li>
                    <li
                      className={`detail-projets-tabs-item ${
                        activeTab === 3 && "active-tab"
                      }`}
                      onClick={() => {
                        // console.log("Investisseurs tab clicked");
                        setActiveTab(3);
                      }}
                    >
                      Investisseurs
                    </li>
                    <li
                      className={`detail-projets-tabs-item ${
                        activeTab === 1 && "active-tab"
                      }`}
                      onClick={() => setActiveTab(1)}
                    >
                      Incubateurs
                    </li>
                    <li
                      className={`detail-projets-tabs-item ${
                        activeTab === 2 && "active-tab"
                      }`}
                      onClick={() => setActiveTab(2)}
                    >
                      Entrepreneurs
                    </li>
                  </ul>
                </div>
                <div className="ajouter-projet-link-container">
                  <Link
                    to="/admin/ajout-user/"
                    className="ajouter-projet-link border p-3"
                    style={{ borderRadius: "10px" }}
                  >
                    <ReactIConly.Plus className="ajouter-projet-link-icon" />
                    <span className="ajouter-projet-link-text">
                        Utilisateur
                    </span>
                  </Link>
                </div>
              </div>
              <div className="users-table ">
                {activeTab === 3 && (
                  <UsersTable
                    users={users} // Passer tous les utilisateurs
                    user={(user) => user.user_type === "investisseur"} // Filtrer les investisseurs
                  />
                )}
                {activeTab === 1 && (
                  <UsersTable
                    users={users} // Passer tous les utilisateurs
                    user={(user) => user.user_type === "incubateur"} // Filtrer les incubateurs
                  />
                )}
                {activeTab === 2 && (
                  <UsersTable
                    users={users} // Passer tous les utilisateurs
                    user={(user) => user.user_type === "entrepreneur"} // Filtrer les entrepreneurs
                  />
                )}
                {activeTab === 0 && (
                  <UsersTable
                    users={users} user={(user) => user.user_type }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersIncubateur;
