import axios from "axios";
import api from "../../Http/global-vars";
import { FETCHS_PROJECT_BY_INCUBATEUR } from "../types";

export const fetchProjectsByIncubateur = (id) => {
    console.log('ID passé à fetchProjectsByIncubateur:', id);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
            },
        };

        const url = api + "projetbyincubateur/" + id + "/";

        if (window.sessionStorage.getItem("userToken")) {
            try {
                const res = await axios.get(url, config);
                dispatch({
                    type: FETCHS_PROJECT_BY_INCUBATEUR,
                    payload: res.data.results.sort((a, b) => (a.id > b.id ? 1 : a.id === b.id ? 0 : -1)),
                });

            } catch (err) {
                console.log("error", err.response);
            }
        }
    };
};
