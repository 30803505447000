import React from "react";
import ProjetSkeleton from "./ProjetSkeleton";

const ListProjetSkeleton = () => {
	return (
		<>
			<div className="d-block d-lg-flex justify-content-between align-items-center gap-3">
			<ProjetSkeleton />
			<ProjetSkeleton />
			<ProjetSkeleton />
			</div>
		</>
	);
};

export default ListProjetSkeleton;
