import React from "react";
import Entrepreneur from "./entrepreneur.png";
import "./tableau.css";
import InvestisseurModal from '../../../../Modals/InvestisseurModal'
import { useSelector } from "react-redux";

const LesInvestisseurs = ({ project }) => {
  const projects = useSelector((state) => state.users);
  const souscrire = useSelector((state) => state.souscrire);
console.log({souscrire});
  const filteredSouscriptions = souscrire.results.filter(
    (souscription) => souscription.projet.id === project.id
  );

  // Utiliser un Set pour les IDs uniques des utilisateurs
  const uniqueInvestors = new Set();
  const uniqueSouscriptions = filteredSouscriptions.filter((souscription) => {
    if (!uniqueInvestors.has(souscription.user.id)) {
      uniqueInvestors.add(souscription.user.id);
      return true;
    }
    return false;
  });

  return (
    <>
      <div className="investisseur-details">
        <div className="investisseurs">
          <h6
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "#171B1E",
              fontFamily: "Poppins",
            }}
          >
            Les Investisseurs sont:
          </h6>
        </div>
        <div className="porteur-de-projet">
          Pas d'investisseur pour le moment
        {/* {uniqueSouscriptions.map((souscription) => (
            <p
              key={souscription.user.id}
              style={{
                fontSize: "14px",
                fontWeight: 300,
                color: "#171B1E",
                fontFamily: "Poppins",
                marginTop: "10px",
              }}
            >
              {souscription.user.prenom || 'Pas '} {souscription.user.nom}
            </p>
          ))} */}
          <div
            className="porteur"
            style={{
              display: "flex",
              flexDirection: "column", // Mettre en colonne
              alignItems: "center", // Centrer horizontalement
              
            }}
          >
            <img src={Entrepreneur} alt="Image entrepreneur"/>
            <p >
              {project?.user?.prenom} {project?.user?.nom}
            </p>
          </div>
          <div className="name-porteur">
            <div className="description-porteur">
              {/* <p>
                Moussa Diop est un soutien précieux pour les nouvelles
                entreprises. Il aide les entrepreneurs à transformer leurs idées
                en succès en leur offrant conseils et ressources
              </p> */}
            </div>
            
              <div className="d-flex justify-content-center">
                <button  data-bs-toggle="modal"
                  data-bs-target={"#DetailsUserModal" + project.id}
                  style={{ color: "#9B51E0", backgroundColor: "transparent" }}
                  className=" btn-svplus">
                  En savoir plus
                </button>
              </div>
          
            <div
              className="modal fade in"
              id={"DetailsUserModal" + project.id}
              tabIndex="-1"
              aria-labelledby="CreerDateLancementLabel"
              aria-hidden="true"
            >
            <InvestisseurModal data={projects}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LesInvestisseurs;
