import React from "react";
import { NavLink } from "react-router-dom";

const TabsProfile = () => {
  return (
    <div className="pt-2">
      <ul
        class="nav nav-pills mb-3 d-flex flex-column flex-md-row align-items-md-end justify-content-between justify-content-xl-around p-5 col-12 bg-tabs"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item nav-item-tabs" role="presentation">
          <a
            class="nav-link active navlink-tabs-prets"
            id="pills-activite-tab"
            data-bs-toggle="pill"
            href="#pills-activite"
            role="tab"
            aria-controls="pills-activite"
            aria-selected="true"
          >
            Editer mon profil
          </a>
        </li>
        <li class="nav-item nav-item-tabs" role="presentation">
          <a
            class="nav-link navlink-tabs-prets"
            id="pills-solde-tab"
            data-bs-toggle="pill"
            href="#pills-solde"
            role="tab"
            aria-controls="pills-solde"
            aria-selected="false"
          >
            Modification mot de passe
          </a>
        </li>
      </ul>
      {/* <ul className="d-flex justify-content-between align-items-center bg-tabs p-5">
          <NavLink
            to="/dashboard-investisseur/profil"
            className="navLink"
          >
            Editer mon profil
          </NavLink>
        </ul> */}
    </div>
  );
};

export default TabsProfile;
