import React, { useEffect,useState } from "react";
import "./FrontConnexion.css";
import "./Responsive.css";
import { Link, useParams } from "react-router-dom";
import UseLoginForm from "../../Auth/useLoginForm";
import { Facebook, Twitter } from "@material-ui/icons";
import Google from "../../../assets/images/socials/google.png";
import googleClientId from "../../../Http/googleClientId";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import TwitterLogin from "react-twitter-login";
import { Lock, Message} from "react-iconly";
import LogoSlogan from "../../Logo/LogoSlogan";
import { Hide, Show } from "react-iconly";

function FrontConnexion({ saveData }) {
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const handleViewNewPassword = () => {
    const input = document.getElementById("mot_de_passe");
    if (viewNewPassword === false) {
      input.setAttribute("type", "text");
      setViewNewPassword(true);
    } else {
      input.setAttribute("type", "password");
      setViewNewPassword(false);
    }
  };

  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
  } = UseLoginForm(saveData);
  const { projectId } = useParams();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState, clearErrors]);
  // Auth with social medial link function
  const handleResponseFacebook = (res) => {
    console.log(res);
  };
  const handleTwitterResponse = (err, data) => {
    console.log(err, data);
  };
  const handleSuccess = async (googleData) => {
    console.log("google authenticate success", googleData.profileObj);
    const data = {
      email: googleData.profileObj.email,
      nom: googleData.profileObj.familyName,
      prenom: googleData.profileObj.givenName,
    };

    console.log(data);
  };
  const handleFailure = (response) => {
    console.log("error", response);
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5 inscription connexions">
      <div className="container">
        <div className="row b-r-15 container-secondaire">
          <div className="col-md-5 divLogo d-flex flex-column align-items-center justify-content-center">
            <LogoSlogan />
          </div>
            <div className="col-md-7 d-flex flex-column align-items-center justify-content-center py-5 vh-80">
              <div className="col-8">
                <p className="titreConnexion text-center mb-5">Connectez-vous</p>
                <form id="login-form" onSubmit={onSubmit}>
                  <div className="auth-form-content">
                    <div className="">
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Message
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>

                        <input
                          name="email"
                          id="email"
                          {...register("email")}
                          type="text"
                          className="form-control cv-input-with-icon inputField"
                          placeholder="Adresse email"
                          data-testid="emailId"
                        />
                        {formState.errors && formState.errors.email && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.email?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      {" "}
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Lock
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="password"
                          id="mot_de_passe"
                          type="password"
                          {...register("password")}
                          className="form-control cv-input-with-icon inputField"
                          placeholder="Mot de passe"
                          data-testid="passwordId"
                        />
                        {!viewNewPassword ? (
                          <Show
                            set="bold"
                            primaryColor="rgba(0,0,0,0.5)"
                            onClick={handleViewNewPassword}
                          />
                        ) : (
                          <Hide
                            set="bold"
                            primaryColor="rgba(0,0,0,0.5)"
                            onClick={handleViewNewPassword}
                          />
                        )}
                        {formState.errors && formState.errors.password && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.password?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                <div className="forget-password-link-container ">
                  <Link className="mdpOublie" to="/request-reset-password">
                    Mot de passe oublié ?
                  </Link>
                </div>

                <div className="auth-submit-btn-container ">
                  <div className="auth-submit-btn-content d-flex justify-content-center">
                    {!isSuccessfullySubmitted ? (
                      <button
                        type="submit"
                        data-testid="btnId"
                        className="auth-submit-btn btn text-white"
                      >
                        Connexion
                      </button>
                    ) : (
                      <button className="auth-submit-btn btn btn-success in-progress-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>

              <div className="row other-auth-action-row">
                <div className="col-md-10 m-auto auth-alternative-action-container">
                  <div className="auth-action">
                    <span className="auth-action-textual">
                      Vous n’avez pas de compte ? &nbsp;
                      <span className="auth-action-link-container">
                        <Link
                          className="color-part2"
                          to={
                            window.location.pathname ===
                            `/projet/connexion/${projectId}`
                              ? `/projet/inscription/${projectId}`
                              : "/inscription"
                          }
                        >
                          Inscrivez-vous ici.
                        </Link>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row other-auth-action-row">
                <div className="col-12">
                  <div className="auth-other-action-text-container">
                    <p className="auth-other-action-text">ou</p>
                  </div>
                  <ul className="auth-other-links-subscribe">
                    <GoogleLogin
                      render={(renderProps) => (
                        <button
                          className="auth-social-btn"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          data-testid="authGoogleBtnId"
                          name="authGoogleBtn"
                        >
                          <img
                            src={Google}
                            alt="google-icon"
                            style={{ width: "20px" }}
                          />
                        </button>
                      )}
                      clientId={googleClientId}
                      onSuccess={handleSuccess}
                      onFailure={handleFailure}
                      cookiePolicy={"single_host_origin"}
                    />
                    <FacebookLogin
                      appId="442092307711692"
                      fields="name,email,picture"
                      callback={handleResponseFacebook}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="auth-social-btn"
                        >
                          <Facebook
                            style={{
                              color: "#395185",
                              fontSize: "20",
                            }}
                          />
                        </button>
                      )}
                    />
                    <TwitterLogin
                      consumerKey="2DSGCjfBiEGmRLqvU3kn5MwZU"
                      consumerSecret="LGRSzAhj0XBzFG9zULSH9TRf8wWgWEbn8DkdLWAAusmphPSixh"
                      authCallback={handleTwitterResponse}
                      children={
                        <button className="auth-social-btn">
                          <Twitter
                            style={{ color: "#55ACEE", fontSize: "20" }}
                          />
                        </button>
                      }
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrontConnexion;
