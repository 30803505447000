import React, { } from "react";
import "../../../BackendV2/Admin/NavbarContent/NavbarContent.css";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faFolderClosed , faHandHoldingDollar} from '@fortawesome/free-solid-svg-icons'

const SidebarContentV2 = ({active}) => {
	return (
		<div className="hoverable" id="scrollbar">
			<div className="contents">
			<ul className="nav nav-items-customs">
          <li className="pt-4 nav-item nav-item-vertical-custom">
            <NavLink
              className={`nav-link nav-link-vertival-custom ${active === 1 && 'active'}`}
              to="/investisseur/Dashboard"
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Chart set="bold" className="icon-navbar-vertical" />
              </span>
              <span className="text-dashbord">Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className={`nav-link nav-link-vertival-custom ${active === 2 && 'active'}`}
              to="/dashboard-investisseur/nos-projets"
              
            >
              <span className="navbar-vertical-container-icon">
                <FontAwesomeIcon icon={faFolderClosed} className="icon-navbar-vertical"/>
              </span>
              <span className="text-dashbord">Projets</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className={`nav-link nav-link-vertival-custom ${active === 3 && 'active'}`}
              to="/dashboard-investisseur/resume"
              
            >
              <span className="navbar-vertical-container-icon">
                <FontAwesomeIcon icon={faHandHoldingDollar} className="icon-navbar-vertical"/>
              </span>
              <span className="text-dashbord">Projets financés</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard/investisseur/rapport"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Paper set="bold" className="icon-navbar-vertical" />
              </span>
              <span className="text-dashbord">Rapport</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className={`nav-link nav-link-vertival-custom ${active === 4 && 'active'}`}
              to="/dashboard-investisseur/profil"
              
            >
              <span className="navbar-vertical-container-icon">
                <FontAwesomeIcon icon={faUser} className="icon-navbar-vertical"/>
              </span>
              <span className="text-dashbord">Mon profil</span>
            </NavLink>
          </li>
        </ul>
			</div>
		</div>
	);
};
export default SidebarContentV2;
