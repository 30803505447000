import React, { } from "react";
import "./NavbarContent.css";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faHandHoldingDollar, faUserGroup} from '@fortawesome/free-solid-svg-icons'

const NavbarContentV2 = ({active}) => {
	return (
		<div className="mt-4 hoverable" id="scrollbar">
			<div className="contents">
			<p className="containMenu">Menu</p>
				<ul className="nav nav-items-customs">
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 1 && 'active'}`}
							to="/incubateur/dashboard"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Chart
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Dashboard
							</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
						className={`nav-link nav-link-vertival-custom  ${active === 3 && 'active'}`}
						to="/dashboard/incubateur/nos-projets"
						
						>
						<span className="navbar-vertical-container-icon">
							<IconlyPack.Paper set="bold" className="icon-navbar-vertical" />
						</span>
						<span className="text-dashbord">Nos projets</span>
						</NavLink>
					</li>
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 2 && 'active'}`}
							to="/incubateur/gestion-des-entrepreneurs/"
							
						>
							<span className="navbar-vertical-container-icon">
								<FontAwesomeIcon icon={faUserGroup} className="icon-navbar-vertical"/>
							</span>
							<span className="text-dashbord">
							Porteur de projets
							</span>
						</NavLink>
						<li className="nav-item nav-item-vertical-custom">
          </li>
		  <li className="nav-item nav-item-vertical-custom">
		  <NavLink
              className={`nav-link nav-link-vertival-custom ${active === 3 && 'active'}`}
              to="/incubateur/projetFinancer/"
              
            >
              <span className="navbar-vertical-container-icon">
                <FontAwesomeIcon icon={faHandHoldingDollar} className="icon-navbar-vertical"/>
              </span>
              <span className="text-dashbord">Projets financés</span>
            </NavLink>
		  </li>
					</li>
					
				
					{/* <li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 3 && 'active'}`}
							to="/incubateur/gestion-des-projets"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.Paper
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Projets
							</span>
						</NavLink>
					</li> */}
					<li className="nav-item nav-item-vertical-custom">
						<NavLink
							className={`nav-link nav-link-vertival-custom  ${active === 4 && 'active'}`}
							to="/incubateur/profil"
							
						>
							<span className="navbar-vertical-container-icon">
								<IconlyPack.User
									set="bold"
									className="icon-navbar-vertical"
								/>
							</span>
							<span className="text-dashbord">
								Profil
							</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default NavbarContentV2;
