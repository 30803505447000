import React from "react";
import NavbarMobileV2 from "../navbarMobileV2/NavbarMobileV2";
import avatar from "../../../../images/others/default-profile-avatar.png";
import { useNavigate,NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {logoBlanc} from "../../../Logo/LogoComponent";

const HeaderV2 = ({ activeLink }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  var logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("userToken");
    window.sessionStorage.clear();
    navigate("/");
  };
  return (
    <header >
      <nav className="navbar navbar-expand-lg position-fixed top-0 z-index-1  fixed-top w-100 py-3 empty-fluidy" >
        <div className="container">
            <NavLink className="w-25 navbar-brand" to="/">
						  <img src={logoBlanc} alt="logo" className="w-35" />
					  </NavLink>
            {/* Bouton toggler */}
          <div className="hiddenable-nav-mobile-content no-view-desktop">
            <NavbarMobileV2 activeLink={activeLink} />
          </div>
          <div className="header-others-items">
            <div className="connected-user-container no-view-mobile">
              <div className="connected-user-content ">
                <div className="dropdown dis-flex" style={{marginRight: "-75px"}}>
                  <button
                    className="d-flex user-acount-dashboard-header"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>
                    <h6 className="admin-name">
                      {user?.data?.id &&
                        user?.data?.prenom + " " + user?.data?.nom}
                    </h6>
                    <span className="text-dark fs-6 pt-2">{user?.data?.user_type}</span>
                    </div>
                    <div className="user-acount-dashboard-header-display">
                      <img
                        src={
                          avatar
                        }
                        alt="user-avatar"
                        height="50"
                        width="50"
                        className="admin-avatar"
                      />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => logout(e)}
                      className="dropdown-item item-dropdown-custom"
                    >
                      {" "}
                      Déconnexion
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderV2;
