import React, { useEffect } from "react";
import HeaderV2 from "../../../../BackendV2/Admin/Navs/HeaderV2";
import SidebarTableauBord from "../../SidebarTableauBord/SidebarTableauBord";
import ProjetsEnLigneInvestisseur from "../ProjetEnLigneInvestisseur/ProjetsEnLigneInvestisseur";

const NosProjet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
   
    <div className="component-mes-projet-tableau-de-bord">
   <HeaderV2 />
    <div className="mt-3 row p-0" style={{background: "white"}}>
      <div className="col-lg-4 col-xl-2">
        {/* <SidebarTableauDeBord value={2} /> */}
        <SidebarTableauBord />
      </div>
      <div className="col-lg-8 col-xl-10">
        <div className="projets">
        <ProjetsEnLigneInvestisseur />
        </div>
      </div>
    </div>
  </div>
  );
};

export default NosProjet;
