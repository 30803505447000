import React, { useMemo, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";

const InformationsSurLaConcurence = ({ formData, setFormData, navigation }) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        // produit_marche: yup
        //   .boolean()
        //   .required("Ce champ est requis.")
        //   .nullable(),
        // produit_duree: yup
        //   .string()
        //   .when("produit_marche", {
        //     is: true || "true",
        //     then: yup.string().required("Ce champ est requis.").nullable(),
        //   })
        //   .nullable(),
        // utilisateurs_actifs: yup
        //   .string()
        //   .when("produit_marche", {
        //     is: true || "true",
        //     then: yup.string().required("Ce champ est requis.").nullable(),
        //   })
        //   .nullable(),
        // taux_dernier_mois: yup
        //   .string()
        //   .when("produit_marche", {
        //     is: true || "true",
        //     then: yup.string().required("Ce champ est requis.").nullable(),
        //   })
        //   .nullable(),
        // revenus: yup.boolean().required("Ce champ est requis.").nullable(),
        // revenu_dernier_mois: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().nullable(),
        //   })
        //   .nullable(),
        // revenu_annuel_prevu: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().required("Ce champ est requis.").nullable(),
        //   })
        //   .nullable(),
        // taux_croissance_dernier_mois: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().nullable(),
        //   })
        //   .nullable(),
        // revenu_annuel_prevu_trois_ans: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().required("Ce champ est requis.").nullable(),
        //   })
        //   .nullable(),
        // kpis: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().nullable(),
        //   })
        //   .nullable(),
        // valeur_annuel_kpis: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().nullable(),
        //   })
        //   .nullable(),
        // vitesse_kpis: yup
        //   .string()
        //   .when("revenus", {
        //     is: true || "true",
        //     then: yup.string().nullable(),
        //   })
        //   .nullable(),
        // evolutivite: yup.boolean().nullable(),
        // besoins_pour_croitre: yup
        //   .string()
        //   .when("evolutivite", {
        //     is: true || "true",
        //     then: yup.string().required("Ce champ est requis.").nullable(),
        //   })
        //   .nullable(),
        // vitesse_croissance: yup
        //   .string()
        //   .when("evolutivite", {
        //     is: true || "true",
        //     then: yup.string().nullable(),
        //   })
        //   .nullable(),
        concurrents: yup.string().nullable(),
        positionnement_strategique: yup.string().nullable(),
        avantage_concurrentiel: yup
          .string()
          .required("Ce champ est requis.")
          .nullable(),
        maintenance_avantage_concurrentiel: yup
          .string()
          .required("Ce champ est requis.")
          .nullable(),
        autres_commentaires: yup
          .string()
          .max(300, "Vous devez écrire au maximum 300 caractères.")
          .nullable(),
        financement: yup.string().required("Ce champ est requis.").nullable(),
        fonds_supplementaires_prix: yup.number().required("Le champ est requis"),
        fonds_supplementaires_usage: yup.string().required("Le champ est requis"),
      }),
    []
  );
  const [haveProduct, setHaveProduct] = useState(false);
  const [generateRevenu, setGenerateRevenu] = useState(false);
  const [scalability, setScalability] = useState(false);

  const { register, handleSubmit, clearErrors, formState, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [commentaire, setCommentaire] = useState("");
  const onChange = (e) => {
    setValue("autres_commentaires", e.target.value);
    setCommentaire(e.target.value);
  };
  useEffect(() => {
    // setValue("produit_marche", formData?.produit_marche);
    // setValue("revenus", formData?.revenus);
    // setValue("evolutivite", formData?.evolutivite);
    setValue("concurrents", formData?.concurrents);
    setValue(
      "positionnement_strategique",
      formData?.positionnement_strategique
    );
    setValue("avantage_concurrentiel", formData?.avantage_concurrentiel);
    setValue(
      "maintenance_avantage_concurrentiel",
      formData?.maintenance_avantage_concurrentiel
    );
    setValue("autres_commentaires", formData?.autres_commentaires);
    setValue("financement", formData?.financement);
    setValue("revenu_annuel_prevu", formData?.revenu_annuel_prevu);
    setValue("revenu_dernier_mois", formData?.revenu_dernier_mois);
    setValue(
      "taux_croissance_dernier_mois",
      formData?.taux_croissance_dernier_mois
    );
    setValue("utilisateurs_actifs", formData?.utilisateurs_actifs);
    setValue("produit_duree", formData?.produit_duree);
    setValue("taux_dernier_mois", formData?.taux_dernier_mois);
    setValue(
      "revenu_annuel_prevu_trois_ans",
      formData?.revenu_annuel_prevu_trois_ans
    );
    setValue(
      "fonds_supplementaires_prix",
      formData?.fonds_supplementaires_prix
    );
    setValue(
      "fonds_supplementaires_usage",
      formData?.fonds_supplementaires_usage
    );
    setValue("kpis", formData?.kpis);
    setValue("vitesse_kpis", formData?.vitesse_kpis);
    setValue("valeur_annuel_kpis", formData?.valeur_annuel_kpis);
    setValue("besoins_pour_croitre", formData?.besoins_pour_croitre);
    setValue("vitesse_croissance", formData?.vitesse_croissance);
  }, [formData, setValue]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  const handleChange = (e) => {
    // if (e.target.name === "produit_marche") {
    //   setValue("produit_marche", e.target.value);
    //   if (e.target.value === "true") {
    //     setHaveProduct(true);
    //   } else {
    //     setHaveProduct(false);
    //   }
    // }
    // if (e.target.name === "revenus") {
    //   setValue("revenus", e.target.value);
    //   if (e.target.value === "true") {
    //     setGenerateRevenu(true);
    //   } else {
    //     setGenerateRevenu(false);
    //   }
    // }
    // if (e.target.name === "evolutivite") {
    //   setValue("evolutivite", e.target.value);
    //   if (e.target.value === "true") {
    //     setScalability(true);
    //   } else {
    //     setScalability(false);
    //   }
    // }
  };

  const onSubmit = async (data) => {
    await setFormData({ ...formData, ...data });
    // await console.log(data, formData);
    return navigation?.go("additional-company-information");
  };

  return (
    <>
      <div className="soumettre-projet">
        <div className="d-flex justify-content-center align-items-end se-financer pt-5">
          <h1 className="">Se financer</h1>
        </div>
        <section className="container informations">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="formulaire-de-depot-dossier"
          >
            {/* <div className="form-group-container">
              <h3 className="form-title">
                5. Product – market fit & traction marché
              </h3>
              <h5 className="text-left">
                Avez-vous déjà un produit – adéquation avec le marché et
                traction sur le marché ?<span className="text-danger">*</span>
              </h5>
              <div className="d-flex flex-column flex-md-row">
                <div className="d-flex  align-items-center mr-5">
                  <input
                    type="radio"
                    name="produit_marche"
                    id="haveProductOk"
                    value={true}
                    defaultChecked={formData?.produit_marche === true}
                    onChange={handleChange}
                    data-testid="produit_marcheId"
                    placeholder="Oui produit marche"
                  />
                  <label htmlFor="haveProductOk" className="ml-3 pt-3">
                    Oui
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="produit_marche"
                    id="haveProductNo"
                    value={false}
                    defaultChecked={formData?.produit_marche === false}
                    onChange={handleChange}
                    data-testid="produit_marcheId"
                    placeholder="Non produit marche"
                  />
                  <label htmlFor="haveProductNo" className="ml-3 pt-3">
                    Non
                  </label>
                </div>
              </div>
              {formState.errors && formState.errors.produit_marche && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState.errors.produit_marche?.message}
                </div>
              )}
            </div> */}
            {/* {haveProduct && (
              <div className="form-group-container">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="timeProduct">
                      Depuis quand ?<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      // placeholder="il y a un mois"
                      id="timeProduct"
                      name="produit_duree"
                      {...register("produit_duree")}
                      // onChange={(e) => console.log("date", e.target.value)}
                      data-testid="produit_dureeId"
                    />
                    {formState.errors && formState.errors.produit_duree && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState.errors.produit_duree?.message}
                      </div>
                    )}
                  </div>
                  <div className="mt-5 mt-md-0 col-md-6">
                    <label htmlFor="utilisateurs_actifs">
                      Combien de clients / utilisateurs actifs avez-vous ?
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      name="utilisateurs_actifs"
                      id="utilisateurs_actifs"
                      {...register("utilisateurs_actifs")}
                      data-testid="utilisateurs_actifsId"
                    />
                    {formState.errors && formState.errors.utilisateurs_actifs && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState.errors.utilisateurs_actifs?.message}
                      </div>
                    )}
                  </div>
                  <div className="mt-5 col-md-6">
                    <label
                      htmlFor="taux_dernier_mois"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Quel est votre taux de croissance du mois dernier ?
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      name="taux_dernier_mois"
                      id="taux_dernier_mois"
                      {...register("taux_dernier_mois")}
                      data-testid="taux_dernier_moisId"
                    />
                    {formState.errors && formState.errors.taux_dernier_mois && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState.errors.taux_dernier_mois?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )} */}
            {/* <div className="form-group-container">
              <h3 className="form-title">6. Revenus</h3>
              <h5 className="text-left">
                Générez-vous déjà des revenus ?
                <span className="text-danger">*</span>
              </h5>
              <div className="d-flex flex-column flex-md-row">
                <div className="d-flex  align-items-center mr-5">
                  <input
                    type="radio"
                    name="revenus"
                    id="handleIncomeOk"
                    value={true}
                    defaultChecked={formData?.revenus === true}
                    onChange={handleChange}
                    data-testid="revenusId"
                    placeholder="Oui revenus"
                  />
                  <label htmlFor="handleIncomeOk" className="ml-3 pt-3">
                    Oui
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="revenus"
                    id="handleIncomeNo"
                    value={false}
                    defaultChecked={formData?.revenus === false}
                    onChange={handleChange}
                    data-testid="revenusId"
                    placeholder="Non revenus"
                  />
                  <label htmlFor="handleIncomeNo" className="ml-3 pt-3">
                    Non
                  </label>
                </div>
              </div>
              {formState.errors && formState.errors.revenus && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState.errors.revenus?.message}
                </div>
              )}
              {generateRevenu && (
                <div className="form-group-container mt-5">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="revenu_dernier_mois">
                        Quels sont vos revenus du dernier mois ?
                      </label>
                      <input
                        type="number"
                        placeholder=""
                        min={0}
                        id="revenu_dernier_mois"
                        name="revenu_dernier_mois"
                        {...register("revenu_dernier_mois")}
                        data-testid="revenu_dernier_moisId"
                      />
                      {formState.errors &&
                        formState.errors.revenu_dernier_mois && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.revenu_dernier_mois?.message}
                          </div>
                        )}
                    </div>
                    <div className="mt-5 mt-md-0 col-md-6">
                      <label
                        htmlFor="taux_croissance_dernier_mois"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Quel est le taux de croissance de votre chiffre
                        d’affaires du dernier mois ?
                      </label>
                      <input
                        type="number"
                        min={0}
                        name="taux_croissance_dernier_mois"
                        id="taux_croissance_dernier_mois"
                        {...register("taux_croissance_dernier_mois")}
                        data-testid="taux_croissance_dernier_moisId"
                      />
                      {formState.errors &&
                        formState.errors.taux_croissance_dernier_mois && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {
                              formState.errors.taux_croissance_dernier_mois
                                ?.message
                            }
                          </div>
                        )}
                    </div>
                    <div className="mt-5 col-md-6">
                      <label htmlFor="revenu_annuel_prevu">
                        Quels revenus annuels prévoyez-vous dans 1 an ?
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        name="revenu_annuel_prevu"
                        id="revenu_annuel_prevu"
                        {...register("revenu_annuel_prevu")}
                        data-testid="revenu_annuel_prevuId"
                      />
                      {formState.errors &&
                        formState.errors.revenu_annuel_prevu && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.revenu_annuel_prevu?.message}
                          </div>
                        )}
                    </div>
                    <div className="mt-5 col-md-6">
                      <label htmlFor="revenu_annuel_prevu_trois_ans">
                        Quels revenus annuels prévoyez-vous dans 3 ans ?
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        name="revenu_annuel_prevu_trois_ans"
                        id="revenu_annuel_prevu_trois_ans"
                        {...register("revenu_annuel_prevu_trois_ans")}
                        data-testid="revenu_annuel_prevu_trois_ansId"
                      />
                      {formState.errors &&
                        formState.errors.revenu_annuel_prevu_trois_ans && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {
                              formState.errors.revenu_annuel_prevu_trois_ans
                                ?.message
                            }
                          </div>
                        )}
                    </div>
                    <div className="mt-5 col-md-6">
                      <label htmlFor="kpis">
                        Quels sont les trois indicateurs (KPIs) que vous suivez
                        activement ?
                      </label>
                      <input
                        type="number"
                        placeholder=""
                        min={0}
                        name="kpis"
                        id="kpis"
                        {...register("kpis")}
                        data-testid="kpissId"
                      />
                      {formState.errors && formState.errors.kpis && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.kpis?.message}
                        </div>
                      )}
                    </div>
                    <div className="mt-5 col-md-6">
                      <label htmlFor="valeur_annuel_kpis">
                        Quelles sont les valeurs actuelles de ces KPIs ?
                      </label>
                      <input
                        type="number"
                        min={0}
                        name="valeur_annuel_kpis"
                        id="valeur_annuel_kpis"
                        {...register("valeur_annuel_kpis")}
                        data-testid="valeur_annuel_kpisId"
                      />
                      {formState.errors && formState.errors.valeur_annuel_kpis && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.valeur_annuel_kpis?.message}
                        </div>
                      )}
                    </div>
                    <div className="mt-5 col-md-6">
                      <label htmlFor="vitesse_kpis">
                        À quelle vitesse chaque KPI augment-il (sur une base
                        mensuelle) ?
                      </label>
                      <input
                        type="number"
                        min={0}
                        name="vitesse_kpis"
                        id="vitesse_kpis"
                        {...register("vitesse_kpis")}
                        data-testid="vitesse_kpisId"
                      />
                      {formState.errors && formState.errors.vitesse_kpis && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.vitesse_kpis?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div> */}
            {/* <div className="form-group-container">
              <h3 className="form-title">7. Scalabilité</h3>
              <h5 className="text-left">
                Votre entreprise est-elle scalable ?
                <span className="text-danger">*</span>
              </h5>
              <div className="d-flex flex-column flex-md-row">
                <div className="d-flex  align-items-center mr-5">
                  <input
                    type="radio"
                    name="evolutivite"
                    id="scalabilityYes"
                    value={true}
                    defaultChecked={formData?.evolutivite === true}
                    onChange={handleChange}
                    data-testid="evolutiviteId"
                    placeholder="Oui evolutivite"
                  />
                  <label htmlFor="scalabilityYes" className="ml-3 pt-3">
                    Oui
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="evolutivite"
                    id="scalabilityNo"
                    value={false}
                    defaultChecked={formData?.evolutivite === false}
                    onChange={handleChange}
                    data-testid="evolutiviteId"
                    placeholder="Non evolutivite"
                  />
                  <label htmlFor="scalabilityNo" className="ml-3 pt-3">
                    Non
                  </label>
                </div>
              </div>
              {formState.errors && formState.errors.evolutivite && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState.errors.evolutivite?.message}
                </div>
              )}
            </div> */}
            {/* {scalability && (
              <div className="form-group-container mt-5">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="besoins_pour_croitre">
                      De quoi auriez-vous besoin pour faire croître massivement
                      votre entreprise ?<span className="text-danger">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="10"
                      id="besoins_pour_croitre"
                      name="besoins_pour_croitre"
                      {...register("besoins_pour_croitre")}
                      data-testid="besoins_pour_croitreId"
                    />
                    {formState.errors && formState.errors.besoins_pour_croitre && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState.errors.besoins_pour_croitre?.message}
                      </div>
                    )}
                  </div>
                  <div className="mt-5 mt-md-0 col-md-6">
                    <label htmlFor="vitesse_croissance">
                      À quelle vitesse pourriez-vous alors grandir (donner une
                      estimation en termes de nombre de clients et de valeur) ?
                    </label>
                    <textarea
                      cols="50"
                      rows="10"
                      id="vitesse_croissance"
                      name="vitesse_croissance"
                      {...register("vitesse_croissance")}
                      data-testid="vitesse_croissanceId"
                    />
                    {formState.errors && formState.errors.vitesse_croissance && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState.errors.vitesse_croissance?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )} */}
            <div className="form-group-container">
              <h3 className="form-title">
                4. Concurrence & Avantage concurrentiel
              </h3>
              <div className="form-textarea-group">
                <label htmlFor="competitors">
                  Qui sont et/ou seront vos concurrents ?
                </label>
                <textarea
                  name="concurrents"
                  id="competitors"
                  cols="50"
                  rows="10"
                  {...register("concurrents")}
                  data-testid="concurrentsId"
                />
                {formState.errors && formState.errors.concurrents && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.concurrents?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="strategicPosition">
                  Quel est leur positionnement stratégique ? (produits
                  similaires / substituts / Coûts bas / différenciation)
                </label>
                <textarea
                  name="positionnement_strategique"
                  id="strategicPosition"
                  cols="50"
                  rows="10"
                  {...register("positionnement_strategique")}
                  data-testid="positionnement_strategiqueId"
                />
                {formState.errors &&
                  formState.errors.positionnement_strategique && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.positionnement_strategique?.message}
                    </div>
                  )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="competitorsAdvantages">
                  Expliquez votre avantage concurrentiel ? Pourquoi et en quoi
                  êtes-vous différent de vos concurrents ? Pourquoi et en quoi
                  votre proposition de valeur est unique ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="avantage_concurrentiel"
                  id="competitorsAdvantages"
                  cols="50"
                  rows="10"
                  {...register("avantage_concurrentiel")}
                  data-testid="avantage_concurrentielId"
                />
                {formState.errors && formState.errors.avantage_concurrentiel && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.avantage_concurrentiel?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="maintainCompetitorsAdvantages">
                  Comment comptez-vous maintenir cet avantage concurrentiel /
                  unicité au fil du temps ? Pourquoi vos concurrents ne vous
                  copieront pas/ne reproduiront-ils pas votre avantage
                  concurrentiel ?<span className="text-danger">*</span>
                </label>
                <textarea
                  name="maintenance_avantage_concurrentiel"
                  id="maintainCompetitorsAdvantages"
                  cols="50"
                  rows="10"
                  {...register("maintenance_avantage_concurrentiel")}
                  data-testid="maintenance_avantage_concurrentielId"
                />
                {formState.errors &&
                  formState.errors.maintenance_avantage_concurrentiel && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {
                        formState.errors.maintenance_avantage_concurrentiel
                          ?.message
                      }
                    </div>
                  )}
              </div>
            </div>
            <div className="form-group-container">
              <h3 className="form-title">5. Autres commentaires</h3>
              <div className="form-textarea-group">
                <label htmlFor="comments">
                  N'hésitez pas à ajouter des commentaires
                </label>
                <textarea
                  name="autres_commentaires"
                  id="comments"
                  cols="50"
                  rows="10"
                  // {...register("autres_commentaires")}
                  onChange={onChange}
                  data-testid="autres_commentairesId"
                />
                <small>300 caractères maximum</small>
                <br />
                <span
                  className="description-length"
                  style={{
                    color: commentaire?.length <= 300 ? "var(--secondary-color)" : "#e54747",
                  }}
                >
                  {" "}
                  {commentaire?.length
                    ? commentaire?.length +
                      `${
                        commentaire?.length > 1 ? " caractères" : " caractère"
                      }`
                    : null}{" "}
                </span>
                {formState.errors && formState.errors.autres_commentaires && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.autres_commentaires?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group-container">
              <h3 className="form-title">6. Financement</h3>
              <div className="form-textarea-group">
                <label htmlFor="howFunded">
                  Comment avez-vous été financé à ce jour ? Montant ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="financement"
                  id="howFunded"
                  cols="50"
                  rows="10"
                  {...register("financement")}
                  data-testid="financementId"
                />
                {formState.errors && formState.errors.financement && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.financement?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="Funded">
                  {" "}
                  De combien de fonds supplémentaires avez-vous besoin ?
                </label>
                <input
                  name="fonds_supplementaires_prix"
                  id="Funded"
                  type="number"
                  {...register("fonds_supplementaires_prix")}
                  data-testid="fonds_supplementaires_prixId"
                  placeholder="mettez la somme attendue"
                />
                {formState.errors &&
                  formState.errors.fonds_supplementaires_prix && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.fonds_supplementaires_prix?.message}
                    </div>
                  )}
              </div>

              <div className="form-textarea-group">
                <label htmlFor="usage">
                  Quelle est l’usage prévu de ces fonds ?
                </label>
                <textarea
                  name="fonds_supplementaires_usage"
                  id="usage"
                  cols="50"
                  rows="10"
                  {...register("fonds_supplementaires_usage")}
                  data-testid="fonds_supplementaires_usageId"
                />
                {formState.errors &&
                  formState.errors.fonds_supplementaires_usage && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.fonds_supplementaires_usage?.message}
                    </div>
                  )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={navigation?.previous}
                className="button-precedent"
              >
                PRECEDENT
              </button>
              <button
                // onClick={navigation?.next}
                type="submit"
                data-testid="btnId"
                className="button-suivant"
              >
                SUIVANT
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default InformationsSurLaConcurence;
