import React, { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useNavigate } from "react-router-dom";
import { ArrowDropDown, Visibility, MoreVert, CheckCircle, Cancel } from "@material-ui/icons/";
import { Dropdown, ButtonToolbar, Popover, Whisper } from "rsuite";
import ProjetsSkeleton from "../../../Tables/backend/Projets/ProjetsSkeleton";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Projet from "../../../Services/Projet";
import { fetchProjects } from "../../../../redux/projects/projectAction";
import financement from "../../../../services/financement";

const ProjetsTable = (props) => {
  const navigate = useNavigate();
  const projectts = useSelector((state) => state.projects);
  console.log({projectts});
  const [filter, setFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  // const [statusFilter, setStatusFilter] = useState("");
  const dispatch = useDispatch();
  const [investissements, setInvestissements] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const request = financement.getAllFinancement(config);
  request.then((res) => {
    setInvestissements(res.results);
  });

  const redirectionFormatter = (cell, row) => {
    return (
      <div>
        <span
          className="redirection-projet"
          onClick={() => navigate(`/admin/detail-projet/${row.id}`)}
        >
          {cell}{" "}
        </span>
      </div>
    );
  };
  const progressPercentageFormatter = (cell, rowId) => {
    const montantsInvestis = [];
    const investisseurs = investissements.length > 0 ? investissements.filter(
      (invest) => invest.projet.id === rowId
    ) : [];
    let objectifCollecte = "";
    investisseurs.forEach((investisseur) => {
      montantsInvestis.push(investisseur ? investisseur.montant : "");
      objectifCollecte = investisseur.projet
        ? investisseur.projet.fonds_supplementaires_prix
        : "";
    });

    const totalInvestment = montantsInvestis.reduce((acc, current) => {
      const currentNumber = parseFloat(current);
      if (!isNaN(currentNumber)) {
        return acc + currentNumber;
      }

      return acc;
    }, 0);
    const pourcentage = Math.min(
      Math.floor((totalInvestment * 100) / objectifCollecte),
      100
    );

    return (
      <>
        <label className="statut-percentage">
          {isNaN(pourcentage) ? "0%" : `${pourcentage}%`}
        </label>
        <div className="progress progress-bar-projet">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${isNaN(pourcentage) ? 0 : pourcentage}%`, background:"var(--secondary-color)" }}
            aria-valuenow={0}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </>
    );
  };
  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée",
  };
  const onArchive = (item, newValue) => {
    const actionText = newValue ? "Validé" : "Rejeté";
    Swal.fire({
      title: `Êtes-vous sûr de vouloir ${actionText} ce projet?`,
      icon: "warning",
      input: newValue ? null : 'textarea',
      inputPlaceholder: "Motif du rejet",
      showCancelButton: true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      iconColor: "var(--secondary-color)",
      confirmButtonColor: "var(--secondary-color)",
      allowOutsideClick: false,
      inputAttributes: { 
        name: "motif_rejet"
      },
      inputValidator: (value) => {
        if (!value && !newValue) {
          return 'Vous devez entrer un motif de rejet';
        }
      },
      preConfirm: (motif) => {
        return Projet.onEditProjet(
          item?.id,
          {
            statut: newValue ? "valider" : "rejeter",
            motif_rejet: newValue ? null : motif,
          },
          config
        )
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchProjects());
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Ce projet a été ${actionText}`,
          iconColor: "var(--secondary-color)",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        dispatch(fetchProjects());
      }
    });
  };

  const StatutFormatter = (cell, row) => {
    const statusText = row.statut === "valider" ? "Validé" : row.statut === "rejeter" ? "Rejeté" : "Soumis";
    return <span className={row.statut === "valider" ? "bg-success text-white p-1" : row.statut === "rejeter" ? "bg-danger text-white p-1" : "bg-secondary text-white p-1"}>{statusText}</span>;
  };

  const montantFormatter = (cell, row) => {
    if (cell === "moins_1500") {
      return `Moins de 1 million FCFA`;
    } else if (cell === "entre_1500_et_50000") {
      return `Entre 1 et 15 millions FCFA`;
    } else if (cell === "entre_50000_et_100000") {
      return `Entre 15 et 100 millions FCFA`;
    } else if (cell === "plus_100000") {
      return `Plus de 100 millions FCFA`;
    }

    return null;
  };

  const navigateDetailProject = (rowId) => {
    navigate(`/admin/detail-projet/${rowId}`);
    
  };

  const actionFormatter = (cell, row) => {
    const isAlreadyActivated = row.statut === "valider";
    const isAlreadyRejeter = row.statut === "rejeter";
   
    const handleDropdownSelect = (eventKey, row) => {
      if (eventKey === "valider" && !isAlreadyActivated) {
        onArchive(row, true);       
      } else if (eventKey === "rejeter" && !isAlreadyRejeter) {
        onArchive(row, false);
      }
    };

    const renderMenu = ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey) => {
        handleDropdownSelect(eventKey, row); // Appel de la fonction de gestion de la sélection
        onClose();
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top, minWidth: "100px" }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={"valider"} className="h4 d-flex align-items-center theDrop" disabled={isAlreadyActivated }><CheckCircle className="text-success" /> Valider</Dropdown.Item>
            <Dropdown.Item eventKey={"rejeter"} className="h4 d-flex align-items-center theDrop" disabled={isAlreadyRejeter }><Cancel className="text-danger" /> Rejeter</Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

    return (
      <div>
        <div className="table-actions-container">
          <button
            className="mr-4 rounded-pill"
            onClick={() => navigateDetailProject(row.id)}
          >
            <Visibility style={{ fontSize: "20px", color: "#A9A9A9" }} />
          </button>
          <div className="dropdown">
            <ButtonToolbar>
              <Whisper
                placement="bottomStart"
                trigger="click"
                speaker={renderMenu}
              >
                <MoreVert
                  primaryColor="#ACABAB"
                  set="bold"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              </Whisper>
            </ButtonToolbar>
          </div>
        </div>
       
      </div>
    );
  };

  const nameFormatter = (cell, row) => {
    const fullName = row.user?.prenom ? `${row.user?.prenom} ${row.user?.nom}` : row.user?.nom;
    return (
      <div className="name-email-group">
        <span className="name">{fullName || "Pas défini"}</span>
        <span className="email">{cell}</span>
      </div>
    );
  };

  const filterProjects = () => {
    const filteredProjects = projectts.data.filter(
      (projet) =>
        projet.title.toLowerCase().includes(filter.toLowerCase()) ||
        projet.prenom.toLowerCase().includes(filter.toLowerCase()) ||
        projet.email.toLowerCase().includes(filter.toLowerCase()) ||
        projet.objectif_financement.toLowerCase().includes(filter.toLowerCase())
    );

    // switch (statusFilter) {
    //   case "lancer":
    //     return filteredProjects.filter((project) => project.statut === "valider");
    //   default:
        
    // }
    switch (countryFilter) {
      case "senegal":
        return filteredProjects.filter(
          (project) => project.senegal === true
        );
      default:
        return filteredProjects;
    }
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}
        {/* <div className="filtres-container pb-4">
          <h6 className="filter-label">Filtre</h6>
          <div className="filter-content">
            <div className="filter-input-group">
              <input
                type="text"
                className="filter-input"
                placeholder="Entrer un nom"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <div className="filter-input-select-group">
              <select
                name="company"
                id="company"
                className="input-select"
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
              >
                <option value="">Pays</option>
                <option value="senegal">Sénégal</option>
              </select>
              <ArrowDropDown className="input-select-icon" />
            </div>
          </div>
        </div> */}
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {filterProjects()?.isLoading && <ProjetsSkeleton />}
            {!filterProjects()?.isLoading && (
              <div className="table-container-activite">
                <BootstrapTable
                  data={(filterProjects() || []).filter(props.categorie || (() => true))}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      whiteSpace: "normal",
                    }}
                    width="100"
                    dataField="title"
                    dataFormat={(cell, row) => redirectionFormatter(cell, row)}
                  >
                    Nom du projet
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      whiteSpace: "normal",
                    }}
                    width="100"
                    dataField="email"
                    dataFormat={(cell, row) => nameFormatter(cell, row)}
                  >
                    Nom de l'entrepreneur
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                    }}
                    width="120"
                    dataField="objectif_financement"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                  >
                    Montant demandée
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                    }}
                    width="100"
                    dataField="progressPercentage"
                    dataFormat={(cell, row) =>
                      progressPercentageFormatter(cell, row.id)
                    }
                  >
                    Progression
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                      textAlign: "center",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2d3748",
                      fontFamily: "Poppins",
                      textAlign: "center",
                    }}
                    width="100"
                    dataField="en_ligne"
                    dataFormat={StatutFormatter}
                  >
                    Statut
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Poppins",
                    }}
                    dataField="id"
                    width="85"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjetsTable;
