import React from "react";
import "./telechargerRibInscription.css";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import FileDownload from "../../../assets/images/icons/filedownload.png";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { useForm } from "react-hook-form";
import api from "../../../Http/global-vars";
import axios from "axios";
import Swal from "sweetalert2";

const TelechargerRibInscription = ({ navigation }) => {
  const { next } = navigation;
  const token = window.sessionStorage.getItem("userToken");
  const userID = window.sessionStorage.getItem("userID");
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const { register, handleSubmit, setValue } = useForm();

  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(status, meta, file);
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label d-flex">
        <div className="label-img-container col-1">
          <img
            src={FileDownload}
            className="file-download"
            alt="file download"
          />
        </div>
        <div className="label-img-container col-11">
          <span className="first_label">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
          </span>
        </div>
      </div>
    );
    let buttonStartStyle = {
      // background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "var(--primaryColor)",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-12">
          {text}
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };
  const onSubmitDocumentRIB = async (data) => {
    console.warn("mamam", data);
    var fd = new FormData();
    fd.append("rib", data.rib);
    //fd.append('first_piece', data.first_piece)
    axios
      .put(`${api}user/${userID}/`, fd, requestConfig)
      .then(async (response) => {
        console.log("responsnsnsns", response);
        navigate("/inscription-numero-telephone");
        // console.log(window.$('#continuons').click())
      })
      .catch((e) => {
        console.log("error", e);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Vous devez inserer une copie de votre RIB.",
          showConfirmButton: true,
          iconColor: "var(--secondary-color)",
          confirmButtonColor: "var(--secondary-color)",
        });
      });
  };
  return (
    <div className="component-front-register-infos-perso-two">
      <div className="card card-body card-telecharger-document">
        <form onSubmit={handleSubmit(onSubmitDocumentRIB)}>
          <div className="div-contenant">
            <h5 className="title-verification titre-document">
              Téléchargez votre RIB
            </h5>
            <div className="bloc-div-register-form">
              <div className="row pl-5">
                <div className="col-md-12">
                  <div class="champ-texte style-text-RIB p-3 mt-5">
                    <p class="style-text-RIB2">
                      L’IBAN ne sera utilisé que pour transférer l’argent
                      disponible sur votre compte AEM vers votre compte bancaire
                      (gratuitement et à tout moment)
                    </p>
                  </div>
                </div>

                <div className="col-12 py-5 w-full">
                  <div className="input-file">
                    <div className="form-group">
                      <Dropzone
                        // getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        // onSubmit={handleSubmit}
                        accept="image/*"
                        styles={{
                          dropzoneReject: {
                            borderColor: "#F19373",
                            //backgroundColor: "#F1BDAB",
                          },
                          inputLabel: (files, extra) =>
                            extra.reject ? { color: "#A02800" } : {},
                        }}
                        maxFiles={1}
                        inputWithFilesContent={(files) => {
                          register("rib");
                          setValue("rib", files[0]?.file);
                        }}
                        InputComponent={InputChooseFile}
                        getFilesFromEvent={getFilesFromEvent}
                        classNames
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="boutton-div">
                  <button
                    className="btn boutton-continuer"
                    type="submit"
                    // onSubmit={() => {next()}}
                    onClick={next}
                  >
                    ENREGISTRER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default TelechargerRibInscription;
