import React from "react";
import ModificationMotDePasseComponent from "../../../TableauDeBord/ModificationMotDePasse/ModificationMotDePasseComponent";
import SidebarMobileTableauBord from "../../SidebarTableauBord/SidebarMobileTableauBord";
import SidebarTableauBord from "../../SidebarTableauBord/SidebarTableauBord";
import TabsProfile from "../TabsProfile/TabsProfile";
import HeaderV2 from "../../../../BackendV2/Admin/Navs/HeaderV2";

const ModificationMotDePasseInvestisseur = ({ saveData }) => {
  return (
    <div className="component-investisseur">
      <HeaderV2 />
      <div className="">
        <SidebarTableauBord />
        <div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
          <SidebarMobileTableauBord />
        </div>
        <div className="me-3 pb-4 pt-4 section-content-page-tb-investisseur">
          <div className="mt-4">
            <TabsProfile />
          </div>
          <div className="">
            <ModificationMotDePasseComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModificationMotDePasseInvestisseur;
