/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessagesContact } from "../../../../redux/contacts/contactAction";
import ModalFaqContent from "../../../Modals/ModalFaqContent";
import PaginationComponent from "../../../Modules/Pagination/Pagination";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import "./Contact.css";
import EditStatusModal from "./EditStatusModal";

function ContactsIncubateur() {
  const contacts = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const [per_page] = useState(8);

  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);

  useEffect(() => {
    if (contacts?.data?.length) {
      formatData(contacts?.data);
    }
  }, [contacts, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
    setLastIndex(indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };

  useEffect(() => {
    dispatch(fetchMessagesContact());
  }, [dispatch]);
  return (
    <div className="users-container">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="card card-body card-faq">
              <h3 className="admin-contacts-title">Messages de contact</h3>

              <div className="card-contacts-main-content">
                {contacts?.isLoading && <PlainteSkeleton />}
                {!contacts?.isLoading &&
                  (currentList?.length ? (
                    currentList.map((contact) => (
                      <div className="row div-card" key={contact?.id}>
                        <div
                          className="col-6"
                          data-bs-toggle="modal"
                          data-bs-target={`#contactModal${contact?.id}`}
                          type="button"
                        >
                          <h4 className="faq-card-title">
                            {`${contact?.message?.slice(0, 30)}...`}
                          </h4>
                          <p className="contact-card-para">
                            Le{" "}
                            {moment(contact?.created_at).format("DD/MM/YYYY")} à{" "}
                            {moment(contact?.created_at).format("HH:mm")}
                          </p>
                        </div>
                        <div className="col-6">
                          <div className="div-nouveau-name">
                            <div>
                              <button
                                type="button"
                                className={`btn contact-btn ${
                                  contact?.status === "new"
                                    ? "contact-btn-nouveau"
                                    : contact?.status === "en_cours"
                                    ? "contact-btn-encours"
                                    : contact?.status === "traité"
                                    ? "contact-btn-done"
                                    : ""
                                }`}
                                data-bs-toggle="modal"
                                data-bs-target={`#contactStatusModal${contact?.id}`}
                              >
                                {contact?.status === "new"
                                  ? "Nouveau"
                                  : contact?.status === "en_cours"
                                  ? "En cours"
                                  : contact?.status === "traité"
                                  ? "Traité"
                                  : null}
                              </button>
                            </div>
                            <div className="div-icon">
                              <p className="contact-name-para">
                                {contact?.nom_complet}
                              </p>
                            </div>
                          </div>
                        </div>
                        <ModalFaqContent message={contact} />
                        <EditStatusModal message={contact} />
                      </div>
                    ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        Aucun message disponible
                      </div>
                    </div>
                  ))}
              </div>
              {currentList?.length ? (
                <PaginationComponent
                  per_page={per_page}
                  current_page={current_page}
                  total_data={total_data}
                  handleClick={handleClick}
                  lastIndex={lastIndex}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactsIncubateur;

const PlainteSkeleton = () => {
  return (
    <section>
      {Array(4)
        .fill()
        .map((item, index) => (
          <div className="card-dashboard m-b-30" key={index}>
            <div className="row">
              <div className=" col-xl-5 col-lg-5 col-md-6">
                <div className="titre-forum-card" to="/details-forum">
                  <Skeleton height={36} width={`90%`} />
                </div>
                <p className="publish-date-forum-card m-b-20">
                  <Skeleton width={`80%`} />
                </p>
              </div>
              <div className=" col-xl-4 col-lg-3 col-md-2 col-sm-6">
                <p className="nbre-message-forum-card text-center mt-3">
                  <Skeleton width={`40%`} />
                </p>
              </div>
              <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 dis-flex m-b-10 align-items-center">
                <p className="text-forum-card fs-12 fw-300">
                  <strong>
                    <Skeleton duration={1} height={30} width={150} />
                  </strong>
                </p>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
};