// SidebarTableauBord.js
import React, { Component } from "react";
import SidebarContentV2 from "./SidebarContentV2";

export default class SidebarTableauBord extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { active } = this.state; 

    return (
      <div className="navbar-vertical-customisation-v2 no-view-mobile">
        <SidebarContentV2 active={active} />
      </div>
    );
  }
}
