import { Incubateur } from './Endpoints'
import axios from 'axios'

export default {
  async postIncubateur(data, config) {
    const response = await axios.post(Incubateur.onPostIncubateur(), data, config);
    return response.data;
  },
  
  async postProjetByIncubateur(data, config) {
    const response = await axios.post(Incubateur.onPostProjectByIncubateur(), data, config);
    return response.data;
  },
};
