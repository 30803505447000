import React, {useEffect} from "react";
// import * as IconlyPack from "react-iconly";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import "./Dashboard.css";
import { fetchProjectsForVisitors } from "../../../../../src/redux/projectsForVisitors/ProjectForVisitorAction";
import { fetchUsers } from "../../../../../src/redux/users/usersAction";
import {fetchInvestisseurs} from "../../../../../src/redux/investisseurs/investisseurAction"
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

// import LineChart from "./LineChart/LineChart";

const Dashboard = () => {
  const projectsForVisitors = useSelector((state) => state.projects);
  const investissements = useSelector((state) => state.investisseurs);
  const utilisateurs = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { filteredData : projectsFilteredData } = projectsForVisitors;
  const { filteredData : utilisateursFilteredData } = utilisateurs;
  dispatch(fetchInvestisseurs());
  dispatch(fetchProjectsForVisitors());
  const totalProjects = projectsFilteredData ? projectsFilteredData.length : 0;

  const ValidateProjects = projectsFilteredData
    ? projectsFilteredData.filter((project) => project.statut === "valider")
    : [];
  const ProjectValidate = ValidateProjects.length;

  const RejectProjects = projectsFilteredData
    ? projectsFilteredData.filter((project) => project.statut === "rejeter")
    : [];
  const ProjectReject = RejectProjects.length;

  const SubmitProjects = projectsFilteredData
    ? projectsFilteredData.filter((project) => project.statut === "nouveau")
    : [];
  const ProjectSubmit = SubmitProjects.length;

  const FinanceProjects = projectsFilteredData
    ? projectsFilteredData.filter((project) => project.projet_invest.length > 0)
    : [];
    const numberOfFundedProjects = FinanceProjects.length;

  dispatch(fetchUsers());
  const Incubateur = utilisateursFilteredData
    ? utilisateursFilteredData.filter((users) => users.user_type === "incubateur")
    : [];
  const NbrIncubateur = Incubateur.length;
  const Entrepreneur = utilisateursFilteredData
  ? utilisateursFilteredData.filter((users) => users.user_type === "entrepreneur")
  : [];
const NbrEntrepreneur = Entrepreneur.length;
const Investisseur = utilisateursFilteredData
  ? utilisateursFilteredData.filter((users) => users.user_type === "investisseur")
  : [];
const NbrInvestisseur = Investisseur.length;

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    

  return (
    <div className="dashboard-container ">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="row m-2 dashboard-card-container ">
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-incubateurs" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre d'incubateurs
                    </h1>
                    <h3 className="dasboard-card-montant">{NbrIncubateur}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-entrepreneurs" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre d'entrepreneurs
                    </h1>
                    <h3 className="dasboard-card-montant">{NbrEntrepreneur}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-investisseurs" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre d'investisseurs
                    </h1>
                    <h3 className="dasboard-card-montant">{NbrInvestisseur}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/projetFinance/" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Montant total investi
                    </h1>
                    <h3 className="dasboard-card-montant">{investissements?.data?.data?.results?.total_investment}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-projets?url=projetAll" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre de projets totals
                    </h1>
                    <h3 className="dasboard-card-montant">{totalProjects}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-projets?url=projetValides" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre de projets validés
                    </h1>
                    <h3 className="dasboard-card-montant">{ProjectValidate}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-projets?url=projetRejetes" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre de projets rejetés
                    </h1>
                    <h3 className="dasboard-card-montant">{ProjectReject}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/gestion-des-projets?url=projetEnAttente" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre de projets soumis
                    </h1>
                    <h3 className="dasboard-card-montant">{ProjectSubmit}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/admin/projetFinance/" className="p-4 dashboard-card">
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">
                      Nombre de projets financés
                    </h1>
                    <h3 className="dasboard-card-montant">{numberOfFundedProjects}</h3>
                  </div>
                  <div className="dasboard-card-img-container">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.94668 5.31816H19.0092C19.1734 5.31809 19.3375 5.32851 19.5005 5.34937C19.4453 4.96167 19.3121 4.58917 19.109 4.25433C18.9059 3.91949 18.6371 3.62925 18.3188 3.4011C18.0006 3.17294 17.6394 3.01161 17.257 2.92683C16.8747 2.84204 16.4792 2.83556 16.0943 2.90776L4.5292 4.88223H4.51602C3.79007 5.02105 3.14451 5.43178 2.71118 6.03052C3.36403 5.56616 4.14553 5.31713 4.94668 5.31816Z"
                        fill="white"
                      />
                      <path
                        d="M19.0093 6.37482H4.94678C4.20111 6.37563 3.48621 6.67221 2.95894 7.19948C2.43167 7.72675 2.13509 8.44164 2.13428 9.18732V17.6248C2.13509 18.3705 2.43167 19.0854 2.95894 19.6127C3.48621 20.1399 4.20111 20.4365 4.94678 20.4373H19.0093C19.7549 20.4365 20.4698 20.1399 20.9971 19.6127C21.5244 19.0854 21.821 18.3705 21.8218 17.6248V9.18732C21.821 8.44164 21.5244 7.72675 20.9971 7.19948C20.4698 6.67221 19.7549 6.37563 19.0093 6.37482ZM16.9219 14.8123C16.6437 14.8123 16.3719 14.7298 16.1406 14.5753C15.9093 14.4208 15.7291 14.2012 15.6227 13.9442C15.5162 13.6873 15.4884 13.4045 15.5426 13.1317C15.5969 12.8589 15.7308 12.6084 15.9275 12.4117C16.1242 12.215 16.3747 12.0811 16.6475 12.0268C16.9203 11.9726 17.2031 12.0004 17.46 12.1069C17.717 12.2133 17.9366 12.3935 18.0911 12.6248C18.2456 12.8561 18.3281 13.1279 18.3281 13.4061C18.3281 13.779 18.18 14.1367 17.9162 14.4004C17.6525 14.6642 17.2948 14.8123 16.9219 14.8123Z"
                        fill="white"
                      />
                      <path
                        d="M2.15625 12.1514V7.77881C2.15625 6.82651 2.68359 5.22998 4.51392 4.88413C6.06738 4.59277 7.60547 4.59277 7.60547 4.59277C7.60547 4.59277 8.61621 5.2959 7.78125 5.2959C6.94629 5.2959 6.96826 6.37256 7.78125 6.37256C8.59424 6.37256 7.78125 7.40527 7.78125 7.40527L4.50732 11.1187L2.15625 12.1514Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
