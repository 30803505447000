import React, { useState } from "react";
import FinanceProjetsTable from "./FinanceProjetsTable.js";
import HeaderV2 from "../Navs/HeaderV2.js";
import NavbarV2 from "../NavbarV2/NavbarV2.js";


function ProjetFinance(){
  

  return (
    <div className="users-container">
    <HeaderV2 />

    <div className="bloc-principal-navbar-container">
      <div className="navbar-container-box">
        <NavbarV2 active={2}/>
      </div>
      <div className="bloc-principal-body-container">
        <div className="projets-container bloc-principal-body-content">
          <div className="liste-projets">
            <div className="liste-projets-bar">
              <div className="liste-projets-info">
                <h1 className="liste-projets-title">Projets financés</h1>
              </div>
            </div>
            <div className="projets-table pt-5">
            <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            <FinanceProjetsTable/>
          </div>
        </div>
      </div>
    </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
  );
};

export default ProjetFinance;
