import React, { useEffect } from "react";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import { useSelector, useDispatch } from "react-redux";
import { fetchSouscrire } from "../../../../../src/redux/souscrire/souscrireAction";
import { fetchProjects } from "../../../../../src/redux/projects/projectAction";
import CardTableauDeBord from "./CardTableauDeBord";
import LigneGris from "../../../../../src/images/lignegris.png";
import GrapheEvolution from "./grapheEvolution";
import GraphSecteur from "./GraphSecteur";
import ProjetInteresser from "./ProjetInteresser";
import { useParams } from "react-router-dom";
import './chart.css'
import SecteurInvestissement from "./SecteurInvestissement";


const TableauDeBord = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSouscrire());
    dispatch(fetchProjects());
  }, [dispatch]);

  const projects = useSelector((state) => state.projects.filteredData || []);
  const projetDetail = projects.find(
    (project) => project.id === parseInt(projectId)
  );

  const projetSouscrit = useSelector((state) => state.souscrire);
  // const projects = useSelector((state) => state.projects);
  const current_user = useSelector((state) => state.user).data;
  const totalProjects = projects.filteredData
    ? projects.filteredData.length
    : 0;

  const { isLoading, filteredData } = projects;

  // const projetDetail =
  //  filteredData?.find((project) => project.slug === projectId);

  const isAlreadySubscribed = projetSouscrit.results?.some(
    (item) =>
      item.projet.id === projetDetail?.id && item.user.id === current_user.id
  );

  // console.log("Nombre de projets souscrits:", projetSouscrit);

  const Table = [
    {
      id: 0,
      title: "Nombre de projet investi",
      nbr: 0,
      // lien: "/dashboard-investisseur/nos-projets",
    },
    {
      id: 1,
      title: "Montant investi",
      nbr: "0 F CFA",
      // nbr: projetSouscrit.count,
      // lien: "/dashboard-investisseur/resume",
    },
  ];

  return (
    <div className="dashboard-container paddingDashboard">
      <HeaderV2 />
      <div className="no-view-mobile">
        <SidebarTableauBord />
      </div>
      <div className="content">
        <div className="section-content-page-tb-investisseur">
          <div className="p-2 px-5">
            <div className="pb-4 titreDashboard">Dashboard</div>
            <div className="row ">
              <div className="col-md-8">
                <div className="card bg-white p-4">
                  <div className="titrePortefeuille">Portefeuille</div>
                  <div className="row pt-5 align-items-center">
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="">
                        <img src={LigneGris} alt="Ligne" className="w-25" />
                      </div>
                      <div>
                        {Table.map((item, id) => (
                          <div className="" key={id}>
                            <CardTableauDeBord
                              data={{
                                link: item.lien,
                                title: item.title,
                                nbr: item.nbr,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <GrapheEvolution /> 
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card bg-white p-4">
                  <div className="titrePortefeuille">Profit</div>
                  <GraphSecteur />
                </div>
              </div>
              <div className="col-md-8">
                <SecteurInvestissement/>
              </div>
            </div>
            <ProjetInteresser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableauDeBord;
