import React, {} from "react";
import "./NavbarMobileV2.css";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";

const NavbarMobileV2 = ({activeLink}) => {
	return (
		<nav className="navbar navbar-expand-lg navbar-light">
			<button
				className="navbar-toggler ml-sm-3"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarNavDropdown"
				aria-controls="navbarNavDropdown"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>

			<div
				className="collapse navbar-collapse bg-theme nav-vertical-admin"
				id="navbarNavDropdown"
			>
				<div className="py-4">
					<div className="mobile-menu-container">
						<div className="mobile-menu-content">
							<ul className="mobile-menu-ul">
								<li>
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/dashboard"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.Chart
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											Dashboard
										</span>
									</NavLink>
								</li>
								<li>
									<NavLink
										className={`nav-link nav-link-vertival-custom  ${
											activeLink ===
												1 &&
											"actived"
										}`}
										to="/admin/gestion-des-projets"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.Paper
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											projets
										</span>
									</NavLink>
								</li>
								<li>
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/gestion-des-utilisateurs"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.People
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											Utilisateurs
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/gestion-des-investisseurs"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.People
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											Investisseurs
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/faq"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.Chat
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											FAQ
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/contacts"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.Message
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											Contacts
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/parametre"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.Setting
												className="icon-navbar-vertical"
												set="bold"
											/>
										</span>
										<span className="mobile-navlink-label">
											Paramètres
										</span>
									</NavLink>
								</li>
								<li className="nav-item nav-item-vertical-custom">
									<NavLink
										className="nav-link nav-link-vertival-custom"
										to="/admin/profil"
									>
										<span className="navbar-vertical-container-icon">
											<IconlyPack.User
												set="bold"
												className="icon-navbar-vertical"
											/>
										</span>
										<span className="mobile-navlink-label">
											Profil
										</span>
									</NavLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default NavbarMobileV2;
