export const PAYS = [
  ["senegal", "Sénégal"],
  ["nigeria", "Nigeria"],
  ["afrique_du_Sud", "Afrique du sud"],
  ["kenya", "Kenya"],
  ["ghana", "Ghana"],
  ["ethiopie", "Éthiopie"],
  ["egypte", "Égypte"],
  ["maroc", "Maroc"],
  ["tanzanie", "Tanzanie"],
  ["rwanda", "Rwanda"],
];

export const SATUS_NEWPROJET_BY_PORTEUR = [
  ["idee_nouvelle", "Idée nouvelle"],
  ["deja_lance", "deja lancé"],
  ["realise_des_ventes", "réalise des ventes"],
];
