import React, { useEffect, useRef } from "react";
// import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import ListeInfoSkeleton from "./ListeInfoSkeleton";
import baseUrl from "../../../../Http/backend-base-url";
import avatar from "../../../../images/others/default-profile-avatar.png";


const InfoPorteurProject = (props) => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const infosRef = useRef([]);

  useEffect(() => {
    infosRef.current = [props.project];
    setTimeout(() => {
      setIsPageLoading((prevIsPageLoading) => {
        return false;
      });
    }, 1000);
  }, [props.project]);

  // console.log(infosRef.current);

  const options = {
    clearSearch: true,
    noDataText: "Aucune donnée disponible",
  };

  return (
    <div className="child-table-container mt-5">
      <div className="table-body-container-documents">
          <div className="card px-4 py-5">
            <div className="d-flex">
              <div className="image-aicha">
                {" "}
                <img
                  src={
                    props.project?.user?.avatar &&
                    !props.project?.user?.avatar?.includes("default.png")
                      ? baseUrl + props.project?.user?.avatar
                      : avatar
                  }
                  style={{ borderRadius: "100%" }}
                  width={100}
                  height={100}
                  alt="aicha"
                />
              </div>
              <div className="px-4">
                <p className="text-aicha">
                  Prénom: {props.project ? props.project?.user?.prenom : "Pas de nom pour le porteur"}
                </p>
                <p className="text-aicha">
                  Nom: {props.project ? props.project?.user?.nom : "Pas de nom pour le porteur"}
                </p>
                <p className="text-aicha">
                  Email: {props.project ?
                    props.project?.user?.email : "Pas de nom pour le porteur"}
                </p>
                <p className="text-aicha">
                  Tél: {props.project ? 
                    props.project?.user?.phone : "Non communiqué"}
                </p>
                <span className="text-aicha-porteur">Porteur du projet</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default InfoPorteurProject;
