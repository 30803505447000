import React, { Component } from "react";
// import NavbarContentV2 from "../NavbarContent/NavbarContentV2";
import NavbarContentV2projet from "../NavbarContent/NavbarContentV2";

export default class NavbarV2projet extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {active} = this.props

		return (
			<div className="navbar-vertical-customisation-v2 no-view-mobile">
				<NavbarContentV2projet active={active}/>
			</div>
		);
	}
}
