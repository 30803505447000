import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom";
import storage from '../../../../Http/storage'


const RapportTable = ({ projects }) => {
    var [isPageLoading, setIsPageLoading] = React.useState(true);

    useEffect(() => {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 1000);
    }, []);
  
    var handleClearButtonClick = (onClick) => {
      // console.log("This is my custom function for ClearSearchButton click event");
      onClick();
    };
  
    var createCustomClearButton = (onClick) => {
      return (
        <ClearSearchButton
          btnText="Effacer"
          btnContextual="btn-warning"
          className="my-custom-class"
          onClick={(e) => handleClearButtonClick(onClick)}
        />
      );
    };
  
    const nameFormatter = (cell, row) => {
      return (
        <div className="name-email-group">
          <span className="name">{row.user?.prenom + " " + row?.user?.nom}</span>
        </div>
      );
    };

    const rapportFormatter = (cell, row) => {
      return (
        <div className="d-flex align-items-center gap-3">
          <span><FontAwesomeIcon icon={faFilePdf} className="text-danger"/></span>
          <span className="name">{row.files.data?.name || "Non défini"}</span>
          <NavLink to={`https://api-easy-funding.fewnu.app/${row.files?.data?.doc}`} download={row.files?.data?.doc}><FontAwesomeIcon icon={faDownload} className="text-dark"/></NavLink>
        </div>
      );
    };

    const options = {
      clearSearch: true,
      clearSearchBtn: createCustomClearButton,
      noDataText: "Aucune donnée disponible",
    };
    // console.log("Data to display:", projects);


  return (
    <div className="child-table-container">
      <div className="p-0 table-body-container border-none">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {/* {isPageLoading  
            <InvestisseurCalendrierRemboursementTableSkeleton />
            } */}
            {!isPageLoading && (
              <div className="p-3 table-container">
                <BootstrapTable
                  data={projects}
                  striped={true}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                  pagination
                >
                  <TableHeaderColumn
                    dataField="id"
                    isKey
                    hidden="true"
                    className="data-center-table"
                  ></TableHeaderColumn>
                  <TableHeaderColumn
                    className="data-center-table-danger"
                    dataFormat={(cell, row) => {
                      return row.date_lancement || 'Non défini'; 
                    }}
                  >
                   Date 
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="155"
                    dataFormat={(cell, row) => {
                      return row.title || 'Non défini'; 
                    }}
                  >
                    Nom du projet
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="265"
                    className="data-center-table-danger"
                    dataFormat={(cell, row) => nameFormatter(cell, row)}
                  >
                   Nom du porteur de projet 
                  </TableHeaderColumn>
                  <TableHeaderColumn
                  dataFormat={(cell, row) => rapportFormatter(cell, row)}
                    width="300"
                  >
                    Rapport 
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataFormat={(cell, row) => {
                      return row.fonds_supplementaires_prix || 'Non défini';
                    }}
                  >
                    Revenue
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RapportTable;
