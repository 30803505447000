import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import DureeExistence from "./DureeExistence";
import { QuestionnaireLocalisation } from "./QuestionnaireLocalisation";
import QuestionnaireSecteur from "./QuestionnaireSecteur";
import ObjectifDuFinancement from "./ObjectifDuFinancement";
import ModeFinancement from "./ModeFinancement";
import { useSelector } from "react-redux";
import { QuestionnaireStatus } from "./Status_projet_porteur";
const QuestionnaireForm = ({ navigation, setFormData, formData }) => {
  const [open, setOpen] = useState(false);
  const users = useSelector((state) => state.user);
  const projet = useSelector((state) => state.projects);
  const userType = users.data.user_type ? users.data.user_type : "user";
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        senegal: yup
          .string()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
        statut_newprojet_by_porteur: yup
          .string()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
        secteur: yup
          .mixed()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
        duree_existance: yup
          .string()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
        objectif_financement: yup
          .string()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
        mode_financement: yup
          .string()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
      }),
    []
  );
  const { handleSubmit, clearErrors, formState, setValue } = useForm({
    defaultValues:{
      secteur : projet.data?.secteur,
      objectif_financement : projet.data?.objectif_financement,
      mode_financement : projet.data?.mode_financement,
      duree_existance : projet.data?.duree_existance,
    },
    resolver: yupResolver(validationSchema),
  });

  const [errorSecteur, setErrorSecteur] = useState("");
console.log(projet);
  useEffect(() => {
   if(formData?.data?.id){
    setValue("senegal", formData?.data?.senegal);
    setValue("statut_newprojet_by_porteur",formData?.data?.statut_newprojet_by_porteur);
    setValue("duree_existance", formData?.data?.duree_existance);
    setValue("secteur", formData?.data?.secteur);
   }
  }, [formData, setValue]);
  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors]);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onSubmit = async (data) => {
    if (data?.secteur?.includes("other") && !data?.otherSecteur) {
      await setErrorSecteur("Veuillez renseigner le secteur d'activité");
      window.scrollTo(50, 50);
      setTimeout(() => {
        setErrorSecteur("");
      }, 3000);
    } else {
      await setFormData({ ...formData, ...data });
      console.log(data?.secteur);
      onOpenModal();
    }

    // return navigation.go("questionnaire-secteur-activite");
  };
  const props = {
    handleSubmit,
    clearErrors,
    formState,
    setValue,
    formData,
    open,
    onCloseModal,
    navigation,
    errorSecteur,
    userType,
  };
  return (
    <div className="component-front-home">
      <form
        className="container mb-4 marge-questionaire-projet"
        onSubmit={handleSubmit(onSubmit)}
      >
        <QuestionnaireLocalisation {...props} />
        <QuestionnaireStatus {...props} />
        <QuestionnaireSecteur {...props} />
        <DureeExistence {...props} />
        <ObjectifDuFinancement {...props} />
        <ModeFinancement {...props} />
        <div className="mt-input-part-project mb-input-part-project">
          <button
            type="submit"
            className="btn-se-financer-part a-link"
            data-testid="btnId"
            // onClick={handleSubmit(onSubmit) ? navigation.next : ""}
          >
            CONTINUER
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuestionnaireForm;
