import React from 'react'
import HeaderV2 from '../Navs/HeaderV2'
import NavbarV2 from '../NavbarV2/NavbarV2'
import * as ReactIConly from "react-iconly";
import { Link } from "react-router-dom";
import IncubateurTable from './EntrepreneurTable';
import {fetchIncubateurs} from '../../../../redux/incubateur/incubateurAction'
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../Http/global-vars";

function Entrepreneur() {
  const incubateurs = useSelector((state) => state.incubateurs);
  const dispatch = useDispatch();
  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };
  return (
    <div className="users-container">
    <HeaderV2 />
    <div className="bloc-principal-navbar-container">
      <div className="navbar-container-box">
        <NavbarV2 />
      </div>
      <div className="bloc-principal-body-container">
        <div className="projets-container bloc-principal-body-content">
          <div className="liste-utilisateurs">
            <div className="liste-projets-bar">
              <div className="liste-projets-info">
                <h1 className="liste-projets-title">
                  Liste des entrepreneurs
                </h1>
              </div>
              <div className="ajouter-projet-link-container">
                <Link
                  to="/admin/ajout-entrepreneur/"
                  className="ajouter-projet-link border p-3"
                  style={{ borderRadius: "10px" }}
                >
                  <ReactIConly.Plus className="ajouter-projet-link-icon" />
                  <span className="ajouter-projet-link-text">
                    Ajouter un entrepreneur
                  </span>
                </Link>
              </div>
            </div>
            <div className="users-table pt-1">
              <IncubateurTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Entrepreneur;