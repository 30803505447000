import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { projectsReducer } from "./projects/projectReducer";
import { userReducer } from "./user/userreducer";
import { secteurReducer } from "./secteurs/secteurReducer";
import { projectsForVisitorsReducer } from "./projectsForVisitors/ProjectForVisitorReducer";
import { usersReducer } from "./users/usersReducer";
import { contactReducer } from "./contacts/contactReducer";
import { adminsReducer } from "./admins/adminsreducer";
import { projectInCampaignReducer } from "./ProjetsEnCoursDeCampagne/ProjectInCampaignReducer";
import { projectsByPorteurReducer } from "./projectsByPorteur/ProjectForByPorteurReducer";
import { finishProjectByPorteurReducer } from "./ProjectsFinishByPorteur/ProjectsFinishByPorteurReducer";
import {investisseurReducer} from "./investisseurs/investisseurReducer";
import {incubateurReducer} from "./incubateur/incubateurReducer";
import { entrepreneurReducer } from "./entrepreneur/entrepreneurReducer";
import { InvestByProjectReducer } from "./InvestByProject/InvestByProjectReducer";
import {ProjectByIncubateurReducer} from "./ProjectsByIncubateur/ProjectsByIncubateurReducer"
import {souscrireReducer} from "./souscrire/souscrireReducer"

const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(
  combineReducers({
    projects: projectsReducer,
    user: userReducer,
    secteurs: secteurReducer,
    projectsForVisitors: projectsForVisitorsReducer,
    users: usersReducer,
    contacts: contactReducer,
    admins: adminsReducer,
    projectsInCampaign: projectInCampaignReducer,
    projectsByPorteur: projectsByPorteurReducer,
    finishProjectByPorteur: finishProjectByPorteurReducer, 
    investisseurs: investisseurReducer,
    incubateurs: incubateurReducer,
    entrepreneurs: entrepreneurReducer,
    investsByProjects : InvestByProjectReducer,
    projectsByIncubateur: ProjectByIncubateurReducer,
    souscrire : souscrireReducer,
  }),
  composedEnhancers
);

export default store;
