import React from "react";
import Skeleton from "react-loading-skeleton";

const CalendrierDeRemboursementSkeleton = () => {
    return(
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"  style={{ color:'var(--secondary-color)', fontSize: '16px', lineHeight: '24px', fontWeight: 'bold'}} >Nom</th>
                        <th scope="col" style={{ color:'var(--secondary-color)', fontSize: '16px', lineHeight: '24px', fontWeight: 'bold'}} >Date</th>
                        <th scope="col" style={{ color:'var(--secondary-color)', fontSize: '16px', lineHeight: '24px', fontWeight: 'bold'}} >Etat actuel</th>
                        <th scope="col" style={{ color:'var(--secondary-color)', fontSize: '16px', lineHeight: '24px', fontWeight: 'bold'}} >Date de lancement</th>
                        <th scope="col" style={{ color:'var(--secondary-color)', fontSize: '16px', lineHeight: '24px', fontWeight: 'bold'}} >Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CalendrierDeRemboursementSkeleton;