import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ProjetSkeleton = () => {
  return (
    <div className="col-md-4 col-12 col-sm-12 col-xl-4 project-item-container">
    <div className="project-item-global-card">
        <div className="card bg-white project-item-card">
            <div className="project-item-card-img-container" >
                <Skeleton height={250}/>
            </div>
            <div className="project-funding-type-label-container">
            </div>
            <div className="card-body bg-white project-item-card-body">
                <div className="d-flex justify-content-between project-statistic-container">
                    <div className="project-funded-pourcentage">
                        <p>
                           <Skeleton width={30}/>
                        </p>
                    </div>
                    <div className="project-funding-container">
                        <p>
                            <Skeleton width={60} />
                        </p>
                    </div>
                </div>
                <div className="">
                    <Skeleton height={5}/>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="project-dates-container">
                        <p>
                            <Skeleton width={100} />
                        </p>
                    </div>
                    <div className="project-funding-donor-counting">
                        <p>
                            <Skeleton width={100} />
                        </p>
                    </div>
                </div>
                <h5 className="card-title mt-3 project-item-name">
                    <Skeleton  height={10}/>
                </h5>
                <div className="project-description-container">
                    <Skeleton height={5} />
                    <Skeleton height={5}/>
                    <Skeleton height={5}/>
                    <Skeleton height={5} />

                </div>
                <div className="py-4 text-center mt-2 fund-project-btn-container">
                    <Skeleton  height={50}/>
                </div>
            </div>
        </div>
    </div>
</div>
  )
};

export default ProjetSkeleton;