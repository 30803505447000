import React, { useState, useEffect } from "react";
import * as IconlyPack from "react-iconly";
// import { Delete, EditTwoTone } from "@material-ui/icons/";
import { NavLink, useParams} from "react-router-dom";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
// import avatarPorteurProjet from "../../../../images/others/default-profile-avatar.png";
import moment from "moment";
import DetailProjetDescription from "../../Admin/Projets/DetailProjetDescription";
import DetailProjetDonneesFinancieres from "../../Admin/Projets/DetailProjetDonneesFinancieres";
import DetailProjetDocument from "../../Admin/Projets/DetailProjetDocument";
import DetailProjectInfo from "./DetailProjectInfo";
import { useSelector } from "react-redux";
import financement from '../../../../services/financement';

const DetailProjetIncubateur = () => {
  const [activeTab, setActiveTab] = useState(0);
  const projectsState = useSelector((state) => state.projects);
  const { data: projects, isLoading } = projectsState;
  const { projectId } = useParams();
  const projectIdNumber = parseInt(projectId, 10);
  const [investissements, setInvestissements] = useState([]);
  const montantsInvestis = [];
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const request = financement.getAllFinancement(config);
  request.then((res) => {
    setInvestissements(res.results)
  })

  {/*-----------------Filtrage des investisseurs dont le projet financé correspond au projet choisi-------------*/}
  const investisseurs = investissements.length > 0 ? investissements.filter(
    (invest) => invest.projet.id === projectIdNumber
  ) : [];

  {/*-----------------Récupération des montants investis-------------*/}
  investisseurs.forEach((investisseur) => {
    montantsInvestis.push(investisseur ? investisseur.montant : "");
  });

  {/*-----------------Calcul de la somme total des montants-------------*/}
  const totalInvestment = montantsInvestis.reduce((acc, current) => {
    const currentNumber = parseFloat(current);    
    if (!isNaN(currentNumber)) {
      return acc + currentNumber;
    }
  
    return acc;
  }, 0);

  const [selectedProject, setSelectedProject] = useState({});
  useEffect(() => {
    if (!isLoading && projects && projects && projects.length > 0) {  
      const projectFound = projects.find((p) => p.id === projectIdNumber);
  
      if (projectFound) {
        setSelectedProject(projectFound);
      } else {
        console.error("Projet non trouvé");
      }
    }
  }, [projectIdNumber, projects, isLoading]);
  const objectifCollecte = typeof selectedProject.fonds_supplementaires_prix === "string" || selectedProject.fonds_supplementaires_prix === "number" ? selectedProject.fonds_supplementaires_prix : 0; 
  const pourcentage = Math.min(Math.floor(totalInvestment * 100 / objectifCollecte), 100);

  return (
    <div className="detail-projet-container">
      <HeaderV2 activeLink={1} />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 active={3} />
        </div>
        <div className="bg-body-secondary p-5 bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content px-4">
            <div className="detail-projet-content px-5">
              <div className="detail-projet-info-actions row px-3">
                <div className="detail-projet-info col-md-6">
                  <div className="detail-projet-icon-title-container">
                    <NavLink
                      to="/incubateur/gestion-des-projets"
                      className="add-project-icon"
                    >
                      <IconlyPack.ArrowLeft />
                    </NavLink>
                    <h1 className="detail-projet-name-projet m-0">
                      {selectedProject?.title}
                    </h1>
                  </div>
                  <div className="detail-projet-text-container">
                    <p className="detail-projet-text">
                      {/* {selectedProject?.description_titre} */}
                    </p>
                  </div>
                </div>
                {/*<div className="detail-projet-actions col-md-6">
                  <Link
                    to={{
                      pathname: "/admin/detail-projet",
                      state: { project: selectedProject },
                    }}
                    className="link-edit link-detail-projet mr-3"
                  >
                    <EditTwoTone style={{ fontSize: "25px" }} />
                  </Link>
                  </div>*/}
              </div>
              <div className="detail-projet-info-sup row mt-3">
                <div className="detail-projet-info-cat-date col-md-8">
                  <div className="detail-projet-info-cat mb-2">
                    <span className="detail-projet-info-label">
                      Secteurs :{" "}
                    </span>
                    <span className="detail-projet-info-val text-uppercase">
                      {selectedProject?.secteur?.map(
                        (item, index) =>
                          item?.name +
                          `${
                            index < selectedProject?.secteur?.length - 1 ? " - " : " "
                          }`
                      )}
                    </span>
                  </div>
                  <div className="detail-projet-info-date">
                    <span className="detail-projet-info-label">
                      Date de création:{" "}
                    </span>
                    <span className="detail-projet-info-val">
                      le {moment(selectedProject?.created_at).format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>
                <div className="detail-projet-resume col-md-4 card py-5">
                  <h6 className="detail-projet-resume-title">
                    Résumé de la campagne
                  </h6>
                  <div className="detail-projet-resume-progress-bar">
                    <div className="detail-projet-resume-progress-text">
                      <h6 className="detail-projet-resume-percentage">{isNaN(pourcentage) ? '0%' : `${pourcentage}%`}</h6>
                      <h6 className="detail-projet-resume-solde">
                        {totalInvestment} FCFA (collectés)
                      </h6>
                    </div>
                    <div className="progress mb-2">
                      <div
                        className="progress-bar"
                        style={{
                          width: `${pourcentage}%`,
                        }}
                        role="progressbar"
                        aria-valuenow="10"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="detail-projet-resume-objectif d-flex justify-content-end">
                      <p className="detail-projet-resume-objectif-text">
                        sur un objectif de {objectifCollecte} FCFA
                      </p>
                    </div>
                  </div>
                  <div className="detail-projet-resume-info-porteur-projet">
                    {/* <img
                      src={avatarPorteurProjet}
                      alt="avatar-porteur-projet"
                      className="detail-projet-resume-avatar-porteur-projet"
                    /> */}
                    <h2 className="detail-projet-resume-cat-porteur-projet m-0">
                      {/* {project?.prenom + " " + project?.nom} */}
                      <span className="detail-projet-resume-name-porteur-projet">Porteur du projet : </span>
                        {selectedProject?.user?.prenom} {selectedProject?.user?.nom}
                    </h2>
                    {/* <h3 className="detail-projet-resume-cat-porteur-projet m-0">
                      Porteur du projet
                    </h3> */}
                  </div>
                </div>
              </div>
              <div className="detail-projets-tabs-container row">
                <ul className="detail-projets-tabs flex-column align-items-start flex-md-row align-items-md-center">
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 0 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(0)}
                  >
                    Projet
                  </li>
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 1 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    Données financières
                  </li>
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 2 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(2)}
                  >
                    Documents
                  </li>
                  <li
                    className={`detail-projets-tabs-item ${
                      activeTab === 3 && "active-tab"
                    }`}
                    onClick={() => setActiveTab(3)}
                  >
                    Informations porteur projet
                  </li>
                </ul>
              </div>
              <div className="detail-projets-tabs-content-container row mt-3">
                {activeTab === 0 && (
                  <DetailProjetDescription project={selectedProject} objectif={objectifCollecte} />
                )}
                {activeTab === 1 && (
                  <DetailProjetDonneesFinancieres project={selectedProject} data={investisseurs} isLoading={isLoading} />
                )}
                {activeTab === 2 && <DetailProjetDocument project={selectedProject} isLoading={isLoading} />}
                {activeTab === 3 && <DetailProjectInfo project={selectedProject} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailProjetIncubateur;
