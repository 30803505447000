import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import "./DetailDesProjet.css";
import { useParams, useNavigate } from "react-router-dom";
import ProjetImg from "../../../images/image2.png";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import {} from "react-scroll/modules/mixins/scroller";
import DonneesFinancieresTab from "./DonneesFinancieresTab";
import DocumentsTab from "./DocumentsTab";
import DetailsTab from "./DetailsTab";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import DetailProjectInfo from "../../BackendV2/Admin/Projets/DetailProjectInfo";
import Risque from "../../Modules/RisqueInvestissement/Risque";
import DetailProjetParticipation from "./DetailProjetParticipation";
import TitreTab from "./TitreTab";
import SuiviProjet from "./SuiviProjet";

const DetailDesProjet = () => {
  const projectsState = useSelector((state) => state.projectsForVisitors);
  const { data: projects, isLoading } = projectsState;
  const { slug } = useParams();
  const [selectedProject, setSelectedProject] = useState();

  useEffect(() => {
    if (!isLoading && projects && projects.length > 0) {
      const projectFound = projects.find((p) => p.slug === slug);

      if (projectFound) {
        // console.log("Projet trouvé:", projectFound);
        setSelectedProject(projectFound);
      } else {
        // console.error("Projet non trouvé");
      }
    }
  }, [slug, projects, isLoading]);

  const projectInvestisseurs = selectedProject
    ? selectedProject.projet_invest
    : [];
  const projectMontantsInvestis = projectInvestisseurs.map(
    (investisseur) => investisseur.montant || 0
  );
  const projectTotalInvestment = projectMontantsInvestis.reduce(
    (acc, current) => {
      const currentNumber = parseFloat(current) || 0;
      return acc + currentNumber;
    },
    0
  );
  const uniqueInvestisseurs = new Set(
    projectInvestisseurs.map((investisseur) => investisseur.investisseur)
  );

  const projectObjectifCollecte = selectedProject
    ? selectedProject.fonds_supplementaires_prix
    : 0;
  const projectPourcentage = Math.min(
    Math.floor((projectTotalInvestment * 100) / projectObjectifCollecte),
    100
  );
  console.log(selectedProject);

  return (
    <div className="component-details-projet">
      <FrontHeaderV2 active={2} />
      <div className="content-view-start pb-5">
        <div className="banner-details-projet">
          <div className="text-center">
            <h3 className="mi-border-bottom border-aqua titre-banner-liste-proj">
            <span className="colorBorderBottom">{selectedProject?.title || "Pas de titre pour le moment"} 
              </span>
            </h3>
          </div>
        </div>
        <div className="px-5 container-cayore">
          <div className="row p-3 pt-5">
            <div className="col-md-8 project-presentation-col">
              <div>
                <TitreTab />
                <div className="tab-content" id="pills-tabContent">
                  <DetailsTab project={selectedProject} />
                  <DonneesFinancieresTab project={selectedProject} />
                  <DocumentsTab project={selectedProject} />
                  <DetailProjectInfo
                    project={selectedProject}
                    fade={"tab-pane fade"}
                  />
                </div>
                <SuiviProjet
                  Count={selectedProject?.uniqueInvestisseursCount}
                  investisseur={selectedProject?.uniqueInvestisseursCount}
                />
              </div>
            </div>
            <div className="col-md-4">
              <DetailProjetParticipation Project={selectedProject} />
            </div>
          </div>
          <Risque />
        </div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default DetailDesProjet;
