import React from "react";
import { NavLink } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import ImgP1 from "../../../images/Frame.png";
import { navbarLogo } from "../../Logo/LogoComponent";

function Investisseur() {
  const Investisseurs = [
    {
      id: 0,
      img: navbarLogo,
      title: "Elikia Venture",
      desciption:
        "Basé à Dakar, au cœur de l’écosystème entrepreneurial sénégalais, nous investissons dans des personnes extraordinaires avec des idées exceptionnelles susceptibles de révolutionner leur secteur d’activités et de délivrer un maximum d’impact",
      lien: "https://elikia.vc/",
    },
    {
      id: 1,
      img: ImgP1,
      title: "Volkeno",
      desciption:
        "Volkeno est une entreprise numérique et une startup studio basée à Dakar. Créée en 2015. Elle a pour vocation de créer et d'aider à créer des produits et services numériques à forte valeur ajoutée. Nous accompagnons tous les acteurs ( entreprises et entrepreneurs ) dans la matérialisation de leurs projets numériques",
      lien: "https://www.volkeno.sn/",
    },
  ];
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 3 },
  ];

  return (
    <div className="mb-5 container">
      <h1 className="titre-a-propos-text pb-5">Nos investisseurs</h1>
      <Carousel className=""
         itemsToShow={3}
         infinite={true}
         breakPoints={breakPoints}
 
      >
        {Investisseurs.map((index, key) => (
          <div className="bg-white shadow p-3 h-100 " key={key.id}>
            <div className="d-flex flex-column justify-content-between align-items-center gap-4">
              <div className="text-center">
              <NavLink to={index.lien} target="_blank" className="site-partenaire">
                <img src={index.img} alt="partenaire" className="img-part"/>
                </NavLink>
                
              </div>
              <div>
                <h3 className="text-center propos-sous-titre pb-4">{index.title}</h3>
                <p className="text-justify text-ap-propos-text">{index.desciption}</p>
              </div>
              <div className="text-center">
                <NavLink to={index.lien} className="site-partenaire">
                    {index.lien}
                </NavLink>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Investisseur;
