import React from 'react'
import Num1 from "../../../images/icons/number1.png";
import Illust1 from "../../../images/rafiki.png";
import Num2 from "../../../images/icons/number2.png";
import Illust2 from "../../../images/anno.png";
import Num3 from "../../../images/icons/number3.png";
import Illust3 from "../../../images/pana.png";
import Num4 from "../../../images/icons/number4.png";
import Illust4 from "../../../images/task.png";
import Num5 from "../../../images/icons/number5.png";
import Illust5 from "../../../images/email.png";
import Num6 from "../../../images/icons/number6.png";
import Illust6 from "../../../images/product.png";
import Num7 from "../../../images/icons/number7.png";
import Illust7 from "../../../images/cuate.png";
import Illust8 from "../../../images/sign-up.png";
import Illust9 from "../../../images/search.png";
import Illust10 from "../../../images/bro.png";
import Illust11 from "../../../images/social-dashboard.png";
import Illust12 from "../../../images/money-illus.png";

const HowItWorksSteps = () => {
  return (
          <div className="bg-white p-5 container-cayore">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="p-3">
                  <div>
                    <p className="sous-titre-ccm">
                      Les différentes étapes pour investir
                    </p>
                  </div>
                  <div className="py-4">
                    <div className="d-flex align-items-center">
                      <img src={Num1} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">
                        Déposer son dossier
                      </span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust1}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Une démarche simple et sécurisée. Compléter le
                        questionnaire en ligne « Test d’éligibilité » et
                        soumettre les pièces nécessaires à l’analyse de votre
                        dossier.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num2} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">
                        Analyse de votre dossier
                      </span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust2}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Dans un délai de 72h, un de nos analystes prendra
                        contact avec vous pour confirmer l’éligibilité de
                        principe de votre dossier et dès lors, initier le
                        travail de Due Diligence de votre entreprise.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num3} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">
                        Comité de validation du dossier
                      </span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust3}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Une fois les analyses et Due Diligences terminées, un
                        comité interne d’investissement se réunit pour statuer
                        sur votre projet. En cas de validation, le Comité
                        confirmera les éléments clés du financement participatif
                        envisagé (selon l’instrument financier : valorisation de
                        l’entreprise, profil de la dette (taux d’intérêt,
                        durée), % royalties…).
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num4} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">
                        Création de la fiche projet
                      </span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust4}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Nos équipes travailleront avec vous pour parfaire les
                        éléments de présentation de votre projet (Teaser, Pitch
                        Deck…).
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num5} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">
                        Lancement de la campagne
                      </span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust5}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Votre projet est mis en ligne sur la plateforme. Nous
                        vous accompagnons dans vos efforts de communication.
                        Nous gérons les souscriptions en ligne et les
                        versements.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num6} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">
                        Clôture de la levée de fonds
                      </span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust6}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Nous clôturons la campagne dès le montant maximal
                        atteint ou la date de fin arrivée. Dans le premier cas,
                        nous versons les fonds à l’entreprise sous cinq jours
                        ouvrés et dans le second cas, nous restitutions les
                        fonds collectés à date aux contributeurs.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num7} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">SUIVI</span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust7}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Nous assurons le suivi et gérons la relation avec vos
                        contributeurs. Vous aurez accès à un ensemble d’outils
                        pour échanger régulièrement avec eux.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="p-3">
                  <div>
                    <p className="sous-titre-ccm">
                      Les différentes étapes pour lever des fonds
                    </p>
                  </div>
                  <div className="py-4">
                    <div className="d-flex align-items-center">
                      <img src={Num1} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">Inscrivez-vous</span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust8}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Inscrivez-vous gratuitement pour avoir accès à
                        l'ensemble des projets / opportunités d’investissement.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num2} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">Consulter</span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust9}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Rechercher les projets selon vos critères et votre
                        profil investisseurs: stade de développement de
                        l’entreprise (amorçage, croissance, développement…);
                        secteur d’activités; type d’investissements (capital,
                        obligation, royalties)… Etudier en détails les projets
                        qui vous intéressent : plan d’affaires, éléments
                        financiers, statuts, projet de contrat…et poser vos
                        questions en ligne.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num3} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">Investissez</span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust10}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Une fois votre décision d’investissement prise, déposer
                        vos fonds en quelques clics sur le compte de la
                        plateforme. C’est simple, sécurisé et encadré par un
                        contrat entre vous et l’entreprise. Les fonds seront
                        conservés jusqu’à ce que l’objectif de montant soit
                        atteint ou jusqu’à la date de clôture de la campagne de
                        levée de fonds. Dans le premier cas, nous versons les
                        fonds à l’entreprise et dans le second cas, nous vous
                        restitutions les fonds collectés sous cinq jours ouvrés.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num4} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">SUIVEZ</span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust11}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Accédez au suivi de vos investissements depuis votre
                        interface personnelle et recevez des Reportings
                        réguliers sur l’évolution et l’actualité de
                        l’entreprise.
                      </p>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="d-flex align-items-center">
                      <img src={Num5} alt="numero" className="img-num" />
                      <span className="text-num-ccm pl-2">Récoltez</span>
                    </div>
                    <div className="py-3">
                      <img
                        src={Illust12}
                        alt="illustration"
                        className="img-illust"
                      />
                    </div>
                    <div>
                      <p className="texte-ccm-home">
                        Les fruits de votre investissement vous sont
                        automatiquement versés sur le compte renseigné sur votre
                        Profil conformément aux modalités de la convention
                        d’investissements préalablement signée.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  )
}

export default HowItWorksSteps