import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./FrontInscription.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AddUser } from "react-iconly";
import { Lock, Message } from "react-iconly";
import UseRegisterForm from "../../Auth/useRegisterForm";
import { Hide, Show } from "react-iconly";
import LogoSlogan from "../../Logo/LogoSlogan";

var FrontInscription = ({ saveData }) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseRegisterForm(saveData);
  const [phone, setPhone] = useState("");
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  const countUppercase = (str) => {
    if (str.replace(/[^A-Z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str) => {
    if (str.replace(/[^a-z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str) => {
    if (str.replace(/[^0-9]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };
  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [viewPassword, setViewPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const handleViewNewPassword = () => {
    const input = document.getElementById("mot_de_passe");
    setViewNewPassword(prevState => !prevState);
    if (viewNewPassword === false) {
      input.setAttribute("type", "text");
      setViewNewPassword(true);
    } else {
      input.setAttribute("type", "password");
      setViewNewPassword(false);
    }
  };
  const handleViewNewPassworde = () => {
    const input = document.getElementById("passwordConfirmation");
    setViewPassword(prevState => !prevState);
    if (viewPassword === false) {
      input.setAttribute("type", "text");
      setViewPassword(true);
    } else {
      input.setAttribute("type", "passwordConfirmation");
      setViewPassword(false);
    }
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5 inscription">
      <div className="container">
        <div className="row b-r-15 container-secondaire">
          <div className="col-md-5 divLogo d-flex flex-column align-items-center justify-content-center">
            <LogoSlogan/>
          </div>
            <div className="col-md-7 d-flex flex-column align-items-center justify-content-center py-5">
              <div className="px-lg-5 px-sm-0 px-md-4">
                <div className="mb-5 text-center">
                  <p className="titreConnexion text-center">Inscription</p>
                </div>
                <form id="login-form" onSubmit={onSubmit}>
                  <div className="auth-form-content">
                    <div className="">
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <AddUser
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="prenom"
                          id="prenom"
                          type="text"
                          {...register("prenom")}
                          className="form-control cv-input-with-icon border-bottom"
                          placeholder="Prénom"
                          data-testid="prenomId"
                        />
                      </div>
                      {formState.errors && formState.errors.prenom && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.prenom?.message}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <AddUser
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="nom"
                          id="nom"
                          type="text"
                          {...register("nom")}
                          className="form-control cv-input-with-icon "
                          placeholder="Nom"
                          data-testid="nomId"
                        />
                      </div>
                      {formState.errors && formState.errors.nom && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.nom?.message}
                        </div>
                      )}
                    </div>
                    <div className="phonePadding">
                      <PhoneInput
                        masks={{ sn: ".. ... .. .." }}
                        inputClass="form-control cv-input-with-icon unMargin"
                        country={"sn"}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          // padding: "30px 10px 30px 60px",
                          // borderRadius: "10px",
                        }}
                        dropdownStyle={{
                          border: "none",
                        }}
                        data-testid="phoneId"
                      />
                      {formState.errors && formState.errors.phone && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.phone?.message}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Message
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          {...register("email")}
                          className="form-control cv-input-with-icon"
                          placeholder="Adresse email"
                          data-testid="emailId"
                        />
                      </div>
                      {formState.errors && formState.errors.email && (
                        <div
                          className="alert alert-danger gfa-alert-danger"
                          role="alert"
                        >
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Lock
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="password"
                          id="mot_de_passe"
                          type={viewNewPassword ? "text" : "password"}
                          {...register("password")}
                          className="form-control cv-input-with-icon inputField"
                          onChange={(e) => passwordHandleChange(e)}
                          placeholder="Choisissez votre mot de passe"
                          data-testid="passwordId"
                        />
                        {!viewNewPassword ? (
                          <Show
                            set="bold"
                            primaryColor="rgba(0,0,0,0.5)"
                            onClick={handleViewNewPassword}
                          />
                        ) : (
                          <Hide
                            set="bold"
                            primaryColor="rgba(0,0,0,0.5)"
                            onClick={handleViewNewPassword}
                          />
                        )}
                         </div>
                    </div>
                    <div className="" style={{ position: "relative" }}>
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Lock
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="passwordConfirmation"
                          id="passwordConfirmation"
                          {...register("passwordConfirmation")}
                          type={viewPassword ? "text" : "password"}
                          className="form-control cv-input-with-icon"
                          placeholder="Confirmez votre mot de passe"
                          data-testid="confirm_passwordId"
                        />
                        {!viewPassword ? (
                          <Show
                            set="bold"
                            primaryColor="rgba(0,0,0,0.5)"
                            onClick={handleViewNewPassworde}
                          />
                        ) : (
                          <Hide
                            set="bold"
                            primaryColor="rgba(0,0,0,0.5)"
                            onClick={handleViewNewPassworde}
                          />
                        )}
                      </div>
                      {formState.errors &&
                        formState.errors.passwordConfirmation && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.passwordConfirmation?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="auth-checkbox-group">
                   <div className="auth-checkbox-input-container">
                     <input
                       type="checkbox"
                       className="auth-checkbox-input"
                       name="risques"
                       {...register("risques")}
                       data-testid="risqueId"
                     />
                   </div>
                   <div className="auth-checkbox-label-container">
                     J’ai pris connaissance des &nbsp;
                     <span className="auth-linkify">
                       <Link to="/risques-investissement" className="auth-link">
                         risques liés à l’investissemnt
                       </Link>
                     </span>
                   </div>
                 </div>
                 {formState.errors && formState.errors.risques && (
                   <div
                     className="alert alert-danger gfa-alert-danger"
                     role="alert"
                   >
                     {formState.errors.risques?.message}
                   </div>
                 )}

                 <div className="auth-checkbox-group">
                   <div className="auth-checkbox-input-container">
                     <input
                       type="checkbox"
                       className="auth-checkbox-input"
                       name="conditions"
                       {...register("conditions")}
                       data-testid="honneurId"
                     />
                   </div>
                   <div className="auth-checkbox-label-container">
                     J’atteste sur l’honneur que les fonds qui vont servir à
                     &nbsp;
                     <span className="auth-darking">
                       mes futurs investissements sur la plateforme ne
                       proviennent pas d’activités illégales.
                     </span>
                   </div>
                 </div>
                 {formState.errors && formState.errors.conditions && (
                   <div
                     className="alert alert-danger gfa-alert-danger"
                     role="alert"
                   >
                     {formState.errors.conditions?.message}
                   </div>
                 )}

                  <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul">
                      <li
                        className={
                          "min-character-count pvt-li " +
                          (haveMinCharacter && "min-character-count-ok")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "min-character-uppercase pvt-li " +
                          (haveMinUppercase && "min-character-uppercase-ok")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "min-character-lowercase pvt-li " +
                          (haveMinLowercase && "min-character-lowercase-ok")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "min-character-number pvt-li " +
                          (haveMinNumber && "min-character-number-ok")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "min-character-special pvt-li " +
                          (haveMinSpecialCharacter &&
                            "min-character-special-ok")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>
                  <div className="auth-submit-btn-container">
                    <div className="auth-submit-btn-content d-flex justify-content-center ">
                      {!isSuccessfullySubmitted && (
                        <button
                          type="submit"
                          data-testid="btnInsId"
                          className="auth-submit-btn btn text-white"
                        >
                          Inscription
                        </button>
                      )}
                      {isSuccessfullySubmitted && (
                        <button
                          disabled
                          className="auth-submit-btn btn btn-success"
                        >
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>

                <div className="row other-auth-action-row">
                  <div className="col-md-12 auth-alternative-action-container">
                    <div className="auth-action">
                      <span className="auth-action-textual">
                        Vous avez déjà un compte ? Connectez-vous &nbsp;
                        <span className="auth-action-link-container">
                          <Link className="color-part2" to="/connexion">
                            ici.
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default FrontInscription;
