import React, { useEffect } from "react";
import baseUrl from "../../../Http/backend-base-url";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Facebook from "../../../images/icons/entypo-social_facebook-with-circle.png";
import Mail from "../../../images/icons/Frame 163.png";
import Linkedin from "../../../images/icons/entypo-social_linkedin-with-circle.png";
import Twitter from "../../../images/icons/entypo-social_twitter-with-circle.png";
import whatsapp from "../../../images/icons/whatshapp.png";
import avatar from "../../../images/others/default-profile-avatar.png";
import { useNavigate } from "react-router-dom";

function DetailProjetParticipation({ Project }) {
  const userType = window.sessionStorage.getItem("userType");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const projectInvestisseurs = Project ? Project.projet_invest : [];
  const projectMontantsInvestis = projectInvestisseurs.map(
    (investisseur) => investisseur.montant || 0
  );
  const projectTotalInvestment = projectMontantsInvestis.reduce(
    (acc, current) => {
      const currentNumber = parseFloat(current) || 0;
      return acc + currentNumber;
    },
    0
  );
  const uniqueInvestisseurs = new Set(
    projectInvestisseurs.map((investisseur) => investisseur.investisseur)
  );
  const uniqueInvestisseursCount = uniqueInvestisseurs.size;

  const projectObjectifCollecte = Project
    ? Project.fonds_supplementaires_prix
    : 0;
  const projectPourcentage = Math.min(
    Math.floor((projectTotalInvestment * 100) / projectObjectifCollecte),
    100
  );
  const projectId = Project?.id;
  const handleSessionSecurity = () => {
    if (userType === "investisseur") {
      navigate(`/alimenter-son-compte/${projectId}`);
    } else {
      navigate(`/projet/connexion/${projectId}`);
    }
  };
  return (
    <div>
      <div className=" card">
        <div className="card-body">
          <div
            // className="project-funding-type dette-items"
            className={`project-funding-type ${
              Project?.mode_financement === "obligation"
                ? "don-items"
                : Project?.mode_financement === "action"
                ? "equity-items"
                : Project?.mode_financement === "don"
                ? "dette-items"
                : "dette-items"
            }`}
          >
            {Project?.mode_financement === "obligation"
              ? "Prêt / Obligations"
              : Project?.mode_financement === "action"
              ? "Action"
              : Project?.mode_financement === "don"
              ? "Donation"
              : "Autre"}
          </div>
          <br />
          <div className="d-flex justify-content-between mt-3">
            <div className="text-pctg">
              <p className="text-pctg-fcfa">{projectPourcentage}%</p>
            </div>
            <div className="">
              <p className="text-pctg-fcfa">
                {Project?.fonds_supplementaires_prix || "Montant indéfini"} FCFA
              </p>
            </div>
          </div>
          <div className="progress mt-1 mb-2">
            <div
              className="progress-bar progress-bar-tab"
              style={{
                width: `${projectPourcentage || 0}%`,
              }}
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="pt-0">
            <div className="row pb-2 ">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <p className="text-progress-2">
                  {/* 27 Juin 2021 au 30juillet 2021 */}
                </p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <p className="text-progress-2 float-right">
                  {uniqueInvestisseursCount || "(0)"} participations
                </p>
              </div>
            </div>
          </div>

          <div className="card-text text-sub-title">
            <div className="text-lance-container">
              <p className="text-lance pt-4">
                Date de lancement du projet :{" "}
                {Project?.date_lancement || "Date non communiqué"}
              </p>
            </div>
          </div>
          {/* <button onClick={handleSessionSecurity}>Envoyer</button> */}
          {/* {userType !== "investisseur" && (
                    <div className="py-4 text-center mt-2">
                      <button
                        className={`btn-financer-projet ${
                          projectPourcentage >= 100
                            ? "disabled-btn bg-secondary"
                            : ""
                        }`}
                        onClick={
                          projectPourcentage < 100 ? handleSessionSecurity : null
                        }
                        disabled={projectPourcentage >= 100 ? true : false}
                      >
                        {projectPourcentage >= 100
                          ? "Déjà financé"
                          : "Financer ce projet"}
                      </button>
                    </div>
                  )} */}
          <div className="py-4 text-center mt-2">
            <button
              className={`btn-financer-projet ${
                projectPourcentage >= 100 ? "disabled-btn bg-secondary" : ""
              }`}
              // onClick={
              //   projectPourcentage < 100 ? handleSessionSecurity : null
              // }
              onClick={handleSessionSecurity}
              disabled={projectPourcentage >= 100 ? true : false}
            >
              {projectPourcentage >= 100
                ? "Déjà financé"
                : "Financer ce projet"}
            </button>
          </div>
          <div className="mt-3">
            <div className="text-participez">
              Participez à l’avancement du projet en le partageant
            </div>
            <div className="d-flex justify-content-between">
              <div className="mx-2">
                <EmailShareButton
                  quote={"Hello pouvez-vous votez pour mon projet "}
                  hashtag="#CAYOR"
                >
                  <img src={Mail} alt="mail" />
                </EmailShareButton>
              </div>
              <div className="mx-2">
                <LinkedinShareButton
                  quote={"Hello pouvez-vous votez pour mon projet !!"}
                  hashtag="#CAYOR"
                >
                  <img src={Linkedin} alt="linkedin" />
                </LinkedinShareButton>
              </div>
              <div className="mx-2">
                <TwitterShareButton
                  quote={"Hello pouvez-vous votez pour mon projet !!"}
                  hashtag="#CAYOR"
                >
                  <img src={Twitter} alt="Twitter" />
                </TwitterShareButton>
              </div>
              <div className="mx-2">
                <FacebookShareButton
                  quote={"Hello pouvez-vous votez pour mon projet !!"}
                  hashtag="#CAYOR"
                >
                  <img src={Facebook} alt="Facebook" />
                </FacebookShareButton>
              </div>
              <div className="mx-2">
                <WhatsappShareButton
                  quote={"Hello pouvez-vous votez pour mon projet !!"}
                  hashtag="#CAYOR"
                >
                  <img src={whatsapp} alt="whatsapp" />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-center">
          <div className="image-aicha">
            {" "}
            <img
              src={
                Project?.avatar && !Project?.avatar?.includes("default.png")
                  ? baseUrl + Project?.avatar
                  : avatar
              }
              style={{ borderRadius: "100%" }}
              width={50}
              height={50}
              alt="aicha"
            />
          </div>
          <div className="p-4">
            <p className="text-aicha">
              {Project?.user?.prenom + " " + Project?.user?.nom ||
                "Pas de nom pour le "}
            </p>
            <span className="text-aicha-porteur">Porteur du projet</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailProjetParticipation;
