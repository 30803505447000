import React, {useEffect} from "react";
import UseContactForm from "../NousContacter/UseContactForm";
import { useSelector } from "react-redux";

function Contact() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    clearErrors,
  } = UseContactForm();

  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user?.data?.id) {
      setValue("nom_complet", user?.data?.prenom + " " + user?.data?.nom);
      setValue("email", user?.data?.email);
      setValue("user", user?.data?.id);
    }
  }, [user]);

  return (
    <div className="container-cayore">
      <div className="d-lg-flex d-block justify-content-between">
        <div className="container p-5">
          <div className="partiContact">
            <h1 className="pb-5 titre-a-propos-text">Nous contacter</h1>
            <div className="">
            <form onSubmit={onSubmit}>
            <div className="form-group d-flex flex-column pb-5">
              <label className="labelform">Nom et prénom</label>
              <input
                type="text"
                name="nom_complet"
                className="inputform px-4"
                {...register("nom_complet")}
                placeholder="Nom et Prénom"
              />
              {formState?.errors && formState?.errors?.nom_complet && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.nom_complet?.message}
                </div>
              )}
            </div>
            <div className="form-group d-flex flex-column pb-5">
              <label className="labelform">Email</label>
              <input
                type="email"
                name="email"
                className=" inputform px-4"
                placeholder="Email"
                {...register("email")}
              />
              {formState?.errors && formState?.errors?.email && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.email?.message}
                </div>
              )}
            </div>
            <div className="form-group d-flex flex-column pb-5">
              <label className="labelform">Message</label>
              <textarea
                className="textareaform"
                name="message"
                id="message"
                cols="30"
                rows="10"
                {...register("message")}
              ></textarea>
              {formState?.errors && formState?.errors?.message && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.message?.message}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end">
            {isSuccessfullySubmitted && (
              <button className="btnform" disabled>
                En cours &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
            {!isSuccessfullySubmitted && (
              <button className="btnform">ENVOYER</button>
            )}
            </div>
          </form>
            </div>
          </div>
        </div>
        <div className="w-75">
            <div className="w-100">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.4473190791923!2d-17.456596424923593!3d14.743810373554457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec10d43bf53eca9%3A0x3b82f22203a8a02d!2sVolkeno!5e0!3m2!1sfr!2ssn!4v1713862759391!5m2!1sfr!2ssn"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
