import React from "react";
import { Link, NavLink } from "react-router-dom";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import "./Investir.css";
import pig from "../../../assets/images/investir/pig.png";
import vsProfile from "../../../assets/images/vs_profile.png";
import Num1 from "../../../images/icons/num1.png";
import Num2 from "../../../images/icons/num2.png";
import Num3 from "../../../images/icons/num3.png";
import Num4 from "../../../images/icons/num4.png";
import Num5 from "../../../images/icons/num5.png";
import Illust8 from "../../../images/sign-up.png";
import Illust9 from "../../../images/search.png";
import Illust10 from "../../../images/bro.png";
import Illust11 from "../../../images/social-dashboard.png";
import Illust12 from "../../../images/money-illus.png";
import { useEffect } from "react";

const Investir = (props) => {
  const token = window.sessionStorage.getItem("userToken");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="component-front-home">
      <FrontHeaderV2 />
      <div className="investir-page-couverture-container">
        <div className="container investir-intro-container">
          <div className="row align-items-center">
            <div className="col-md-8 investir-couverture-left-side">
              <div className="funding-couverture-textual-container">
                <p>
                  <span className="color-span"> Investissez </span> <br /> dans
                  des entreprises qui vous inspirent
                </p>
              </div>
              <div className="investir-couverture-btn-container">
                <div className="preference-link-container">
                  <Link
                    className="btn btn-success preference-link-btn"
                    to="/liste-des-projets"
                  >
                    Découvrir les opportunités
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 investir-couverture-image-right-side">
              <div className="investir-couverture-image-container">
                <img src={pig} className="w-100" alt="Choisir votre épargne" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container">
        <div className="investir-offer-section-container">
          <div className="offer-section-title-container">
            <h1 className="offer-section-title">
              Investissez directement dans des entreprises ambitieuses et
              soutenez l'économie réelle
            </h1>
          </div>
          <div className="investir-offer-container row">
            <div className="investir-offer-card col-md-4">
              <div className="offer-card-header">
                <div className="offer-card-profile-container">
                  <img src={vsProfile} alt="profile-icon" />
                </div>
                <div className="offer-card-numero-container">
                  <h6 className="offer-card-numero offer-card-numero-after-small">
                    1
                  </h6>
                </div>
              </div>
              <div className="offer-card-body">
                <h2 className="offer-card-title">
                  Financez des entrepreneurs pasionés
                </h2>
                <div className="offer-card-text-container">
                  <p className="offer-card-text">
                    Choisissez où placer votre épargne, investissez dans des
                    entreprises qui vous inspirent.
                  </p>
                </div>
              </div>
            </div>
            <div className="investir-offer-card col-md-4">
              <div className="offer-card-header">
                <div className="offer-card-profile-container">
                  <img src={vsProfile} alt="profile-icon" />
                </div>
                <div className="offer-card-numero-container">
                  <h6 className="offer-card-numero offer-card-numero-after-medium">
                    2
                  </h6>
                </div>
              </div>
              <div className="offer-card-body">
                <h2 className="offer-card-title">
                  Renforcez votre impact social
                </h2>
                <div className="offer-card-text-container">
                  <p className="offer-card-text">
                    Participez directement à la création d’emploi, à la
                    conservation de savoir-faire ou à la transition écologique.
                  </p>
                </div>
              </div>
            </div>
            <div className="investir-offer-card col-md-4">
              <div className="offer-card-header">
                <div className="offer-card-profile-container">
                  <img src={vsProfile} alt="profile-icon" />
                </div>
                <div className="offer-card-numero-container">
                  <h6 className="offer-card-numero offer-card-numero-after2">
                    3
                  </h6>
                </div>
              </div>
              <div className="offer-card-body">
                <h2 className="offer-card-title">
                  Apportez votre expertise aux entreprises
                </h2>
                <div className="offer-card-text-container">
                  <p className="offer-card-text">
                    Accompagnez les entrepreneurs et conseillez-les dans leurs
                    stratégie de developpement pour faire de leur entreprises.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container ccm-section-container">
        <div className="container-cayore">
          <div className="text-center">
            <h1 className="titre-ccm-investir">
              Comment participer au financement <br /> d’un projet?
            </h1>
          </div>
          <div>
            <div className="py-4">
              <div className="d-flex align-items-center">
                <img src={Num1} alt="numero" className="img-num" />
                <span className="text-num-ccm pl-2">Inscrivez-vous</span>
              </div>
              <div className="py-3 d-block d-lg-flex">
                <img src={Illust8} alt="illustration" className="w-5" />

                <div className="pl-4">
                  <p className="texte-ccm-home">
                    Inscrivez-vous gratuitement pour avoir accès à l'ensemble
                    des projets / opportunités d’investissement.
                  </p>
                </div>
              </div>
            </div>
            <div className="pb-4">
              <div className="d-flex align-items-center">
                <img src={Num2} alt="numero" className="img-num" />
                <span className="text-num-ccm pl-2">Consulter</span>
              </div>
              <div className="py-3 d-block d-lg-flex">
                <div className="pr-4">
                  <p className="texte-ccm-home">
                    Rechercher les projets selon vos critères et votre profil
                    investisseurs: stade de développement de l’entreprise
                    (amorçage, croissance, développement…); secteur d’activités;
                    type d’investissements (capital, obligation, royalties)…
                    Etudier en détails les projets qui vous intéressent : plan
                    d’affaires, éléments financiers, statuts, projet de
                    contrat…et poser vos questions en ligne.
                  </p>
                </div>
                <img src={Illust9} alt="illustration" className="w-25" />
              </div>
            </div>
            <div className="pb-4">
              <div className="d-flex align-items-center">
                <img src={Num3} alt="numero" className="img-num" />
                <span className="text-num-ccm pl-2">Investissez</span>
              </div>
              <div className="py-3" d-flex>
                <img src={Illust10} alt="illustration" className="w-15" />
                <div className="pl-4">
                  <p className="texte-ccm-home">
                    Une fois votre décision d’investissement prise, déposer vos
                    fonds en quelques clics sur le compte de la plateforme.
                    C’est simple, sécurisé et encadré par un contrat entre vous
                    et l’entreprise. Les fonds seront conservés jusqu’à ce que
                    l’objectif de montant soit atteint ou jusqu’à la date de
                    clôture de la campagne de levée de fonds. Dans le premier
                    cas, nous versons les fonds à l’entreprise et dans le second
                    cas, nous vous restitutions les fonds collectés sous cinq
                    jours ouvrés.
                  </p>
                </div>
              </div>
            </div>
            <div className="pb-4">
              <div className="d-flex align-items-center">
                <img src={Num4} alt="numero" className="img-num" />
                <span className="text-num-ccm pl-2">SUIVEZ</span>
              </div>
              <div className="py-3 d-block d-lg-flex">
                <div className="pr-4">
                  <p className="texte-ccm-home">
                    Accédez au suivi de vos investissements depuis votre
                    interface personnelle et recevez des Reportings réguliers
                    sur l’évolution et l’actualité de l’entreprise.
                  </p>
                </div>
                <img src={Illust11} alt="illustration" className="w-15" />
              </div>
            </div>
            <div className="pb-4">
              <div className="d-flex align-items-center">
                <img src={Num5} alt="numero" className="img-num" />
                <span className="text-num-ccm pl-2">Récoltez</span>
              </div>
              <div className="py-3 d-block d-lg-flex">
                <img src={Illust12} alt="illustration" className="w-15" />

                <div className="pl-4">
                  <p className="texte-ccm-home">
                    Les fruits de votre investissement vous sont automatiquement
                    versés sur le compte renseigné sur votre Profil conformément
                    aux modalités de la convention d’investissements
                    préalablement signée.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!token && (
            <div className="text-center">
              <NavLink to="/inscription" className="btn btn-theme">
                INSCRIVEZ-VOUS
              </NavLink>
            </div>
          )}
        </div>
      </section>
      {/* <section className="container-cayore section-container">
        <div className="participate-project-finance">
          <div className="offer-section-title-container">
            <h1 className="participate-project-finance-title">
              Comment participer au financement d’un projet?
            </h1>
          </div>
          <CommentInvestir />
          <div className="participate-project-finance-button-container">
            <Link to="" className="participate-project-finance-button">
              INSCRIVEZ-VOUS
            </Link>
          </div>
        </div>
      </section>
      */}
      <div className="container container-cayore section-container">
        <div className="row">
          <div className="offer-section-title-container">
            <h1 className="offer-section-title">
              Trouvez l'essentiel des réponses à vos questions sur
              l’investissement
            </h1>
          </div>
        </div>

        <div className="row qr-card-row">
          <div className="col-md-4 qr-card-col">
            <div className="qr-card-container">
              <h2 className="qr-card-title">L’investissement participatif</h2>
              <div className="qr-card-textual-container">
                <p>
                  L’investissement participatif, également crowd equity, est une
                  offre d’investissement ouverte à tous et qui vous permet
                  d’investir en direct dans des TPE et PME françaises. Vous
                  pouvez placer votre argent dans des projets solides et matures
                  sélectionnés par nos analystes financiers.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 qr-card-col">
            <div className="qr-card-container">
              <h2 className="qr-card-title">
                Comment investir dans une entreprise ?
              </h2>
              <div className="qr-card-textual-container">
                <p>
                  Les objectifs d’investissement peuvent être divers en fonction
                  des personnes ou des périodes : de la recherche d'un placement
                  à court-terme ou à long-terme, avec plus ou moins de risques,
                  pour pouvoir défiscaliser ou encore simplement pour soutenir
                  les PME françaises. Il est important de comprendre les enjeux
                  derrière votre investissement participatif
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 qr-card-col">
            <div className="qr-card-container">
              <h2 className="qr-card-title">
                Investissement PME et défiscalisation
              </h2>
              <div className="qr-card-textual-container">
                <p>
                  Certaines opportunités d’investissement peuvent vous donner
                  droit à un crédit d’impôts allant jusqu’à 10 000€ par an. Les
                  investissements éligibles à la défiscalisation sont indiqués
                  directement sur les pages descriptives des projets. Vous avez
                  également la possibilité d’investir via un PEA ou un PEA-PME.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          <Link to="/liste-des-projets" className="btn btn-theme">
            Découvrir les projets en cours
          </Link>
        </div>
      </div>

      {/* <section className="funding-donors-section-container">
        <div className="container-cayore funding-donors-content">
          <h2 className="funding-donors-title">
            Ils ont investi avec CayorVentures
          </h2>
          <div className="row funding-donors-row">
            <Carte />
            <Carte />
            <Carte />
          </div>
        </div>
      </section> */}

      <FrontFooterV2 />
    </div>
  );
};

export default Investir;
