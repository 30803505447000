import React, { useState, useEffect } from "react";
import * as IconlyPack from "react-iconly";
import { useForm } from "react-hook-form";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "moment/locale/fr";
import Projet from "../../Services/Projet";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreerUneDatePourLeLancement = ({
  navigation,
  projectId,
  setProjectId,
  userType
}) => {
  console.log(userType);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, clearErrors, formState, setValue, reset } =
    useForm();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  useEffect(() => {
    if (projectId) {
      register("date_lancement", {
        required: "Veuillez selectionner une date",
      });
    }

    return () => {
      reset();
    };
  }, [projectId, reset, register]);

  const onChange = (date) => {
    console.log("date", date);
    setDate(date);
    setValue("date_lancement", moment(date).format("YYYY-MM-DD"));
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setLoading(true)
    let request = Projet.onEditCandidature(projectId, data);
    await request
      .then(async (response) => {
        console.log("response", response);
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "<h5>La date de lancement a été soumise avec succés!</h5>",
          showConfirmButton: false,
          iconColor: "var(--secondary-color)",
          timer: 1500,
        }).then(async () => {
          await window.$("#CreerDateLancement").modal("hide");
          await setProjectId(null);
          await setLoading(false);
          if(userType === "entrepreneur"){
            window.location.href = ("/entrepreneur/gestion-des-projets?url=projetEnAttente")
          }else{
            navigate("/entrepreneur/gestion-des-projets");
          }
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        setLoading(false);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de la soumission!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          {/* <h5 className="modal-title" id="CreerDateLancement">
            Modal title
          </h5> */}
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            className="creer-une-date-pour-lancement"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="calendrier-container">
              <div className="calendrier">
                <h2 className="">
                  <IconlyPack.Calendar size="medium" /> Calendrier
                </h2>
                <div className="date-selectionne">
                  <p>{moment(date).locale("fr").format("dddd")}</p>{" "}
                  <p className="day">
                    {moment(date).locale("fr").format("DD")}
                  </p>{" "}
                  <p>{moment(date).locale("fr").format("MMMM/YYYY")}</p>
                </div>
                <div>
                  <Calendar
                    minDate={new Date()}
                    onChange={onChange}
                    value={date}
                  />
                </div>
                {formState.errors && formState.errors.date_lancement && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.date_lancement?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end py-5">
              
              {!loading && (
                <button className="button-enregistrer" type="submit">
                Enregistrer
              </button>
              )}
              {loading && (
                <button disabled className="button-enregistrer">
                  En cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreerUneDatePourLeLancement;
