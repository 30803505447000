import React from "react";
// import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import SidebarTableauDeBord from "../TableauDeBord/SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLink from "../TableauDeBord/TabsLink/TabsLink";
import "./MonProfile.css";
import "../MonProfile/MonProfile.css";
import EditerMonProfile from "./EditerMonProfile";
import HeaderV2 from "../../BackendV2/Admin/Navs/HeaderV2";
const MonProfile = () => {
  return (
    <div className="component-mes-projet-tableau-de-bord">
      <HeaderV2 />

      <TabsLink />
      <div className="mt-3 row p-0">
        <div className="">
          <SidebarTableauDeBord value={0} />
        </div>
        <div className="section-content-page-tb-investisseur">
          <EditerMonProfile />
        </div>
      </div>
      {/* <FrontFooterV2 /> */}
    </div>
  );
};

export default MonProfile;
