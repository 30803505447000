import React from "react";
import { useParams, NavLink } from "react-router-dom";
import NavbarV2 from "../../NavbarV2/NavbarV2";
import HeaderComponentV2 from "../../../Admin/HeaderComponent/HeaderComponentV2";
import clocks from "./icon/clock.png";
import investissement from "./icon/investment.png";
import flesh from "./icon/flesh.png";
import LesInvestisseurs from "./LesInvestisseurs";
import DetailsProjetInvetisseurParticipation from "../../../../FrontendV2/InvestisseurTableauDeBord/ProfilInvestisseur/ProjetInvestisseurDetail/DetailsProjetInvetisseurParticipation";
import * as IconlyPack from "react-iconly";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchProjects } from "../../../../../redux/projects/projectAction";
import Projet from "../../../../Services/Projet";

const ProjetIncubateurDetails = () => {
  const { projectId } = useParams();
  const { id } = useParams();
  console.log("id", id);
  console.log(" logProjet", projectId);
  const projectsForVisitors = useSelector((state) => state.projects);
  const { isLoading, filteredData } = projectsForVisitors;
  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const cleanedProjectId = projectId.replace(":projectId", "");

  const projetDetail = filteredData?.find(
    (project) => project.slug === cleanedProjectId
  );

  const onArchive = (item, newValue) => {
    const actionText = newValue ? "Validé" : "Rejeté";
    Swal.fire({
      title: `Êtes-vous sûr de vouloir ${actionText} ce projet?`,
      icon: "warning",
      input: newValue ? null : "textarea",
      inputPlaceholder: "Motif du rejet",
      showCancelButton: true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      iconColor: "var(--secondary-color)",
      confirmButtonColor: "var(--secondary-color)",
      allowOutsideClick: false,
      inputAttributes: {
        name: "motif_rejet",
      },
      inputValidator: (value) => {
        if (!value && !newValue) {
          return "Vous devez entrer un motif de rejet";
        }
      },
      preConfirm: (motif) => {
        const body = {
          statut: newValue ? "valider" : "rejeter",
          motif_rejet: newValue ? null : motif,
        };

        console.log("Request Body:", body); // Log the request body for debugging

        return fetch(
          `https://api-dev-elikia-funding.fewnu.app/api/projet/${item?.id}/`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${window.sessionStorage.getItem(
                "userToken"
              )}`,
              "Content-Type": "application/json", // Ensure the Content-Type is set to application/json
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => {
            console.log("Response status:", response.status); // Log the response status
            return response.json().then((data) => {
              if (!response.ok) {
                throw new Error(
                  data.message || `HTTP error! status: ${response.status}`
                );
              }
              return data;
            });
          })
          .then(async (data) => {
            console.log("Response data:", data); // Log the response data for debugging
            await dispatch(fetchProjects());
          })
          .catch((error) => {
            console.log("Error caught:", error); // Log the error for debugging
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Ce projet a été ${actionText}`,
          iconColor: "var(--secondary-color)",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        dispatch(fetchProjects());
      }
    });
  };

  const handleValidation = (row) => {
    onArchive(row, true);
  };

  const handleRejection = (row) => {
    onArchive(row, false);
  };

  return (
    <div className="component-mes-projet-tableau-de-bord">
      <HeaderComponentV2 />
      <div className="mt-3 row p-0" style={{ background: "white" }}>
        <div className="col-lg-4 col-xl-2">
          <NavbarV2 />
        </div>
        <div className="col-lg-8 col-xl-10">
          <div className="title-detai-projet">
            <p style={{ fontSize: "15px", paddingTop: "8%" }}>
              <NavLink
                to="/dashboard/incubateur/nos-projets"
                className="add-project-icon"w
              >
                <IconlyPack.ArrowLeft />
              </NavLink>
              Détail du projet
            </p>
          </div>
          <div
            className="container-fluid"
            id="pills-tabContent"
            style={{ paddingTop: "2%" }}
          >
            {isLoading ? (
              <div>Chargement...</div>
            ) : projetDetail ? (
              <>
                <div className="row">
                  <div className="col-md-7">
                    <DetailsProjetInvetisseurParticipation
                      project={projetDetail}
                    />
                  </div>
                  <div className="col-md-5">
                    <div className="row" style={{ paddingTop: "10px" }}>
                      <div className="col-md-6">
                        <p style={{ fontSize: "14px" }}>
                          <img src={clocks} alt="clock" /> Date du début
                        </p>
                        <span style={{ fontSize: "12px", color: "#717579" }}>
                          {projetDetail?.date_lancement}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <p style={{ fontSize: "14px" }}>
                          <img src={clocks} alt="clock" /> Date d'échéance
                        </p>
                        <span style={{ fontSize: "12px", color: "#717579" }}>
                          {projetDetail?.date_fin || "Non défini"}
                        </span>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#171B1E",
                          }}
                        >
                          <img src={investissement} alt="investment" /> Besoin
                          en investissement
                        </p>
                        <span style={{ fontSize: "12px", color: "#717579" }}>
                          {projetDetail?.objectif_financement}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <p style={{ fontSize: "14px" }}>
                          <img src={flesh} alt="flesh" /> Montant atteint
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#369E8A",
                            fontWeight: "500",
                          }}
                        >
                          {projetDetail?.fonds_supplementaires_prix ||
                            "Non défini"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-3 parainer mt-3">
                      <button
                        className="btn bouton-parainer w-100"
                        style={{
                          backgroundColor:
                            projetDetail.statut === "valider"
                              ? "rgb(54 158 138 / 55%)"
                              : "#369E8A",
                          color: "#FFFFFF",
                          fontSize: "18px",
                          cursor:
                            projetDetail.statut === "valider"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => handleValidation(projetDetail)}
                        disabled={projetDetail.statut === "valider"}
                      >
                        Valider
                      </button>
                      <button
                        className="btn bouton-parainer w-100"
                        style={{
                          backgroundColor:
                            projetDetail.statut === "rejeter"
                              ? "rgb(255 0 0 / 55%)"
                              : "red",
                          color: "#FFFFFF",
                          fontSize: "18px",
                          cursor:
                            projetDetail.statut === "rejeter"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => handleRejection(projetDetail)}
                        disabled={projetDetail.statut === "rejeter"}
                      >
                        Rejeter
                      </button>
                    </div>

                    <div style={{ paddingTop: "50px" }}>
                      <LesInvestisseurs project={projetDetail} />
                    </div>
                  </div>
                </div>
                <div
                  className="les-des-porteurs mt-7"
                  style={{ marginTop: "50px" }}
                ></div>
              </>
            ) : (
              <div>
                Aucun détail de projet trouvé pour l'identifiant fourni.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjetIncubateurDetails;
