import React, { useMemo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
// import "./SoumettreProjet.css";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Projet from "../../../Services/Projet";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as ReactIConly from "react-iconly";
import AjoutEntrepreneurModal from "./AjoutEntrepreneurModal";
import { NavLink} from "react-router-dom";
import * as IconlyPack from "react-iconly";
import incubateur from "../../../../services/incubateur";
import entrepreneur from "../../../../services/entrepreneur";
import HeaderComponentV2 from "../../Admin/HeaderComponent/HeaderComponentV2";

function AjoutProjet({ user, formData }) {
  const config = {
    headers: {
      Authorization: `Bearer  ${sessionStorage.getItem("userToken")}`,
    },
  };
  const [selectedEntrepreneur, setSelectedEntrepreneur] = useState("");
  const [entrepreneursState, setEntrepreneursState] = useState([]);
  // const [entrepreneur, setEntrepreneur] = useState("");
  const current_user = useSelector((state) => state.user).data;
  // const isLoading = current_user.isLoading
  const id=current_user.id
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onGetListUsers = async () => {
    try {
      if (current_user.isLoading) {
        return [{ label: "Loading...", value: "" }];
      } else {
        const entrepreneurs = await entrepreneur.getEntrepreneur(config);
        console.log({entrepreneurs});
        const filteredEntrepreneurs = entrepreneurs?.results?.filter(
            (entrepreneur) => entrepreneur?.incubateur === id
        );
        console.log({filteredEntrepreneurs});
        setEntrepreneursState(
            filteredEntrepreneurs.sort(
                (a, b) => new Date(b.date_joined) - new Date(a.date_joined)
            )
        );
      }
    } catch (error) {
        console.log(error);
    }
};

  const renderUserOptions = () => {
    if (current_user.isLoading) {
      return [{ label: "Loading...", value: "" }];
    } 
    else {
      const options = entrepreneursState.map((user) => ({
        label: `${user?.prenom} ${user?.nom}`,
        value: user?.id,
      }));
      console.log({options});
      return [
        { label: "--Entrepreneur--", value: "", isDisabled: true },
        ...options,
      ];
    }
  };
   useEffect(() => {
    onGetListUsers();
  }, [current_user]);

  const handleSelectEntrepreneur = (selectedOption) => {
    setSelectedEntrepreneur(selectedOption.value);
  };

  const navigate = useNavigate();

  const Backspace = () => {
    navigate("/dashboard/incubateur/nos-projets");
  };
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("Le titre est requis."),
        // prenom: yup.string().required("Le prénom est requis."),
        // nom: yup.string().required("Le nom est requis."),
      }),
    []
  );

  const { register, setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "lightblue" : "white",
      borderColor: state.isFocused ? "lightblue" : "#ced4da",
      "&:hover": {
        borderColor: state.isFocused ? "lightblue" : "#ced4da",
      },
    }),
    option: (provided) => ({
      ...provided,
      color: "black", // Couleur du texte des options
      fontWeight: "bold", // Poids de la police
    }),
  };

  console.log({ error: formState.errors });
  

  const onSubmit = async (data) => {
    console.log({ data });
    setIsSuccessfullySubmitted(true);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("from_incubateur", current_user.id);
    formData.append("description", data.description);
    formData.append("user", selectedEntrepreneur);

    try {
      const response = await incubateur.postProjetByIncubateur(formData, config);
      console.log("response", response);
      await Swal.fire({
        position: "center",
        icon: "success",
        title:
          "<h5>Le projet de l'entrepreneur a été ajouté avec succés! </h5>",
        showConfirmButton: false,
        iconColor: "var(--secondary-color)",
        timer: 1500,
      });
      window.location.href = "/dashboard/incubateur/nos-projets";
    } catch (error) {
      console.log("error", error);
      setIsSuccessfullySubmitted(false);
      if (error?.response?.data?.message) {
        Swal.fire({
          icon: "error",
          title: error?.response?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la soumission!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  return (
    <div className="easypm-modal-dialog modal-lg">
      <HeaderComponentV2 />
      <div className="users-container">
        <NavbarV2 active={3} />
        <div className="bloc-principal-body-ajoutI">
          <div className="ajout-form">
            <div className="liste-projets-bar">
              <div className="liste-projets-info">
              <NavLink
                      to="/dashboard/incubateur/nos-projets"
                      className="add-project-icon"
                    >
                      <IconlyPack.ArrowLeft />
                    </NavLink>
                <h1 className="liste-projets-title">Ajouter un projet</h1>
              </div>
            </div>
            <div className="add-userAdmin-form-container">
              <form onSubmit={handleSubmit(onSubmit)} className="formCrud">
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="position-relative form-group crud-form-group">
                      <label htmlFor="nom">
                        Prénom et Nom{" "}
                        <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                      </label>
                      <Select
                        options={renderUserOptions()}
                        placeholder="--Entrepreneur--"
                        styles={customStyles}
                        onChange={handleSelectEntrepreneur}
                      />
                       <div className="ajouter-projet-link-container form-group position-absolute posBTN">
                      <bouton
                        className="ajouter-projet-link border p-1"
                        style={{ borderRadius: "10px" }}
                        data-bs-toggle="modal"
                        data-bs-target="#ajouterEntrepreneur"
                        type="button"
                      >
                        <ReactIConly.Plus className="ajouter-projet-link-icon" />
                      </bouton>
                    </div>
                    </div>
                    {formState?.errors && formState?.errors?.user && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.user?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="nomProjet">
                        Nom du projet{" "}
                        <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Nom du projet"
                        name="title"
                        className="form-control"
                        id="nomProjet"
                        {...register("title")}
                      />
                    </div>
                    {formState?.errors && formState?.errors?.title && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.title?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="description">
                        Description{" "}
                        <strong style={{ color: "#BB4411" }}>*</strong>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Description"
                        name="description"
                        className="form-control"
                        id="description"
                        {...register("description")}
                      />
                    </div>
                    {formState?.errors && formState?.errors?.description && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.description?.message}
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-end crud-form-action-btn-container">
                    <div className="d-flex align-items-center gap-4">
                      <button
                        className="btn btn-secondary cancelleb-btn"
                        type="button"
                        onClick={Backspace}
                      >
                        Retour
                      </button>
                      {!isSuccessfullySubmitted && (
                        <button
                          type="submit"
                          className="btn btn-primary crud-submit-btn"
                        >
                          Enregistrer
                        </button>
                      )}
                      {isSuccessfullySubmitted && (
                        <button
                          disabled
                          className="auth-submit-btn btn btn-success"
                        >
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div id="ajouterEntrepreneur"  tabIndex="-1" className="modal fade"
            // data-backdrop="static"
            aria-labelledby="ajouterEntrepreneur"
            aria-hidden="true">
            <AjoutEntrepreneurModal/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutProjet;
