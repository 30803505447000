import { FETCH_USERS } from "../types";

export const usersReducer = (state = { isLoading: true, data: {} }, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};
