/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import api from "../../../../Http/global-vars";
import { fetchMessagesContact } from "../../../../redux/contacts/contactAction";

function EditStatusModal({ message }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, setValue, clearErrors } =
    useForm();

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (message?.id) {
      register("status", {
        required: "Veuillez selectionner le statut du message",
      });
      setValue("status", message?.status);
    }
  }, [message?.id]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const onSubmit = (data, e) => {
    setIsLoading(true);
    Axios.put(`${api}contact/${message?.id}/`, data, config)
      .then(async (response) => {
        console.log("response statut", response);

        await dispatch(fetchMessagesContact());
        await Swal.fire({
          icon: "success",
          title: "Statut modifié avec succès!",
          showConfirmButton: false,
          timer: 1500,
        });
        await window.$(`#contactStatusModal${message?.id}`).modal("hide");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };
  return (
    <div
      className="modal fade modal-faq modal-faq-contact"
      id={`contactStatusModal${message?.id}`}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md ">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header easypm-modal-title">
            <h5
              className="modal-title titre-question-reponse"
              id="exampleModalLabel"
            >
              Modifier le statut du message
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form
              className="statut-modal-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label
                  htmlFor="status"
                  className="col-form-label label-reponse"
                >
                  Statut
                </label>
                <select
                  className="form-control select-statut"
                  name="status"
                  id="status"
                  onChange={(e) => setValue("status", e.target.value)}
                  defaultValue={message?.status}
                >
                  <option value="" hidden>
                    Statut
                  </option>
                  <option value="new">Nouveau</option>
                  <option value="en_cours">En cours de traitement</option>
                  <option value="traité">Traiter</option>
                </select>
                {formState?.errors && formState?.errors?.status && (
                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                    {formState?.errors?.status?.message}
                  </div>
                )}
              </div>
              <div className="modal-footer faq-modal-footer">
                <button
                  className="btn btn-retour-faq-modal"
                  data-bs-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading && (
                  <button className="btn btn-envoyer-faq-modal">
                    Enregister
                  </button>
                )}
                {isLoading && (
                  <button disabled className="btn btn-envoyer-faq-modal">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStatusModal;
