export const FETCH_PROJECTS = "FETCH_PROJECTS";

export const FETCH_USERS = "FETCH_USERS";

export const FETCH_ADMINS = "FETCH_ADMINS";

export const ME = "ME";

export const SECTEUR = "SECTEUR";

export const FETCH_PROJECTS_FOR_VISITORS = "FETCH_PROJECTS_FOR_VISITORS";

export const FILTER_PROJECTS_FOR_VISITORS = "FILTER_PROJECTS_FOR_VISITORS";

export const FETCH_CONTACTS_MESSAGES = "FETCH_CONTACTS_MESSAGES";

export const FETCH_PROJECTS_IN_CAMPAIGN = "FETCH_PROJECTS_IN_CAMPAIGN";

export const FETCH_PROJECTS_BY_PORTEUR = "FETCH_PROJECTS_BY_PORTEUR";

export const FETCHS_FINISH_PROJECTS_BY_PORTEUR = "FETCHS_FINISH_PROJECTS_BY_PORTEUR";

export const FETCH_INVESTISSEURS = "FETCH_INVESTISSEURS";

export const FETCH_INCUBATEURS = "FETCH_INCUBATEURS";

export const FETCH_ENTREPRENEURS = "FETCH_ENTREPRENEURS";

export const FETCH_INVEST_BY_PROJECT = "FETCH_INVEST_BY_PROJECT";

export const FETCHS_PROJECT_BY_INCUBATEUR = "FETCHS_PROJECT_BY_INCUBATEUR";

export const FETCH_SOUSCRIRE = "FETCH_SOUSCRIRE";