import React, { useEffect, useState } from "react";
import UseEditParam from "./UseEditParam";
import { PAYS, SATUS_NEWPROJET_BY_PORTEUR } from "../../../../../utils/payes";
import { useSelector } from "react-redux";

function TestEditProjet(formData, setValue, userType, errorSecteur) {
  const secteurs = useSelector((state) => state.secteurs);
  const [data, setData] = useState([]);
  const [otherChecked, setOtherChecked] = useState(false);

  const onChange = (e) => {
    if (e.target.checked) {
      if (e.target.value === "other") {
        setData([e.target.value]);
      } else {
        setData([parseInt(e.target.value)]);
      }
    } else {
      if (e.target.value === "other") {
        setData(data.filter((val) => val !== "other"));
      } else {
        setData(
          data.filter((val) => parseInt(val) !== parseInt(e.target.value))
        );
      }
    }

    if (e.target.value === "other") {
      setOtherChecked(!otherChecked);
    }

    setData((prevData) => {
      // setValue("secteur", prevData);
      console.log(prevData);
      return prevData;
    });
  };
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    defaultValues,
  } = UseEditParam();

  return (
    <div>
      <form className="pt-0 form-input-profile" onSubmit={onSubmit}>
        <div className="container-input-address">
          <div className="">
            <div className="form-gro">
            <div className="d-flex flex-column justify-content-between gap-2 pb-3 flex-md-row">
                <div className="col-md-6">
                  <label htmlFor="campagneName" className="title-questionnaire">
                    Nom du projet<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Titre de projet"
                    id="campagneName"
                    name="title"
                    {...register("title")}
                    defaultValue={defaultValues.title}
                    data-testid="titleId"
                    className="form-control"
                  />
                  {formState?.errors && formState?.errors?.title && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState?.errors?.title?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 flex-fill">
                  <label htmlFor="url"  className="title-questionnaire">
                    Url de l'entreprise et ou de la demo
                  </label>
                  <input
                    type="url"
                    placeholder="https://www.founding.com"
                    name="url_demo"
                    id="url"
                    {...register("url_demo")}
                    defaultValue={defaultValues.url_demo}
                    data-testid="url_demoId"
                    className="form-control"
                  />
                  {formState?.errors && formState?.errors.url_demo && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState?.errors?.url_demo?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="quest-pp-block">
                <h3 className="title-questionnaire">
                    Ancienneté de l’idée
                  <span className="text-danger">*</span>
                </h3>
                <div className="form-group">
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="moins_1_an"
                      {...register("duree_existance")}
                      defaultChecked={
                        formData?.duree_existance === "Moins de 1 an"
                      }
                      name="duree_existance"
                      data-testid="duree_existanceId"
                      defaultValue={defaultValues.duree_existance}
                    />
                    <label className="quest-label" htmlFor="moins_1_an">
                      Moins de 1 an
                    </label>
                  </div>
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="entre_1_et_3"
                      {...register("duree_existance")}
                      defaultChecked={
                        formData?.duree_existance === "Entre 1 et 3 ans"
                      }
                      name="duree_existance"
                      data-testid="duree_existanceId"
                      defaultValue={defaultValues.duree_existance}
                    />
                    <label className="quest-label" htmlFor="entre_1_et_3">
                      Entre 1 et 3 ans
                    </label>
                  </div>
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="plus_3"
                      defaultChecked={
                        formData?.duree_existance === "Plus de 3 ans"
                      }
                      name="duree_existance"
                      data-testid="duree_existanceId"
                      defaultValue={defaultValues.duree_existance}
                      {...register("duree_existance")}
                    />
                    <label className="quest-label" htmlFor="plus_3">
                      Plus de 3 ans
                    </label>
                  </div>
                </div>
                {formState.errors && formState.errors.duree_existance && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.duree_existance?.message}
                  </div>
                )}
              </div>
              <div className="quest-pp-block">
                <h3 className="title-questionnaire">
                  Sélectionnez le pays où est localisé votre projet
                  <span className="text-danger">*</span>
                </h3>
                <div className="form-group">
                  <select
                    className="form-control"
                    defaultValue={defaultValues.senegal}
                    {...register("senegal")}
                  >
                    <option value="">Sélectionner un pays</option>
                    {PAYS.map((pays) => (
                      <option key={pays[0]} value={pays[0]}>
                        {pays[1]}
                      </option>
                    ))}
                  </select>
                </div>
                {formState.errors && formState.errors.senegal && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.senegal?.message}
                  </div>
                )}
              </div>
              {/* <div className="quest-pp-block">
                <h3 className="title-questionnaire">
                  3. Dans quel secteur d’activités opérez-vous ?
                  <span className="text-danger">*</span>
                </h3>
                <div className="form-group">
                  {!secteurs?.isLoading &&
                    (secteurs?.data?.length ? (
                      <>
                        {secteurs.data
                          .sort(function (a, b) {
                            if (a.name < b.name) {
                              return -1;
                            }
                            if (a.name > b.name) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((secteur) => (
                            <div className="form-quest-check" key={secteur?.id}>
                              <input
                                type="radio"
                                className="quest-control-input"
                                id={secteur?.id}
                                {...register("secteur")}
                                defaultValue={defaultValues.secteur}
                                defaultChecked={
                                  formData?.secteur?.length &&
                                  formData?.secteur.includes(secteur?.id)
                                }
                                name="secteur"
                                onChange={onChange}
                                data-testid="secteurId"
                              />

                              <label
                                className="quest-label"
                                htmlFor={secteur?.id}
                              >
                                {secteur?.name}
                              </label>
                            </div>
                          ))}
                        <div className="form-quest-check">
                          <input
                            type="radio"
                            className="quest-control-input"
                            id="other"
                            name="secteur"
                            {...register("secteur")}
                            defaultValue={defaultValues.secteur}
                            onChange={onChange}
                            data-testid="secteurId"
                          />
                          <label className="quest-label" htmlFor="other">
                            Autre
                          </label>
                        </div>
                        {otherChecked && (
                          <div>
                            <input
                              className="input-part-du-project-other"
                              type="text"
                              placeholder="Saisissez votre secteur d'activité"
                              // onChange={(e) => setValue("otherSecteur", e.target.value)}
                              {...register("secteur")}
                              defaultValue={defaultValues.secteur}
                              data-testid="autreSecteurId"
                            />
                            {errorSecteur && (
                              <div
                                className="alert alert-danger gfa-alert-danger"
                                role="alert"
                              >
                                {errorSecteur}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="gfa-rowy mt-5">
                        <div className="alert alert-warning gfa-warning">
                          Aucun secteur disponible pour le moment
                        </div>
                      </div>
                    ))}
                  {formState.errors && formState.errors.secteur && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.secteur?.message}
                    </div>
                  )}
                </div>
              </div> */}
              <div className="quest-pp-block">
                <h3 className="title-questionnaire">
                 Quel est votre objectif de financement ?
                  <span className="text-danger">*</span>
                </h3>
                <div className="form-group">
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="moins"
                      name="objectif_financement"
                      {...register("objectif_financement")}
                      defaultChecked={
                        formData?.objectif_financement === "moins_1500"
                      }
                      // onChange={(e) => setValue("objectif_financement", e.target.value)}
                      defaultValue={defaultValues.objectif_financement}
                      data-testid="objectifId"
                    />

                    <label className="quest-label" htmlFor="moins">
                      Moins de 1 million FCFA
                    </label>
                  </div>
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="entre"
                      name="objectif_financement"
                      defaultChecked={
                        formData?.objectif_financement === "entre_1500_et_50000"
                      }
                      // onChange={(e) => setValue("objectif_financement", e.target.value)}
                      defaultValue={defaultValues.objectif_financement}
                      data-testid="objectifId"
                      {...register("objectif_financement")}
                    />
                    <label className="quest-label" htmlFor="entre">
                      Entre 1 et 15 millions FCFA
                    </label>
                  </div>
                  <div className="form-quest-check ">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="et"
                      name="objectif_financement"
                      defaultChecked={
                        formData?.objectif_financement ===
                        "entre_50000_et_100000"
                      }
                      // onChange={(e) => setValue("objectif_financement", e.target.value)}
                      defaultValue={defaultValues.objectif_financement}
                      data-testid="objectifId"
                      {...register("objectif_financement")}
                    />
                    <label className="quest-label" htmlFor="et">
                      Entre 15 et 100 millions FCFA
                    </label>
                  </div>
                  <div className="form-quest-check ">
                    <input
                      type="radio"
                      className="quest-control-input"
                      id="plus"
                      name="objectif_financement"
                      defaultChecked={
                        formData?.objectif_financement === "plus_100000"
                      }
                      // onChange={(e) => setValue("objectif_financement", e.target.value)}
                      defaultValue={defaultValues.objectif_financement}
                      data-testid="objectifId"
                      {...register("objectif_financement")}
                    />
                    <label className="quest-label" htmlFor="plus">
                      Plus de 100 millions FCFA
                    </label>
                  </div>
                  {formState.errors &&
                    formState.errors.objectif_financement && (
                      <div
                        className="alert alert-danger gfa-alert-danger"
                        role="alert"
                      >
                        {formState.errors.objectif_financement?.message}
                      </div>
                    )}
                </div>
              </div>
              <div className="quest-pp-block">
                <h3 className="title-questionnaire">
                Quel mode de financement souhaiteriez-vous ? (deux choix maximum)
                  <span className="text-danger">*</span>
                </h3>
                <div className="form-group">
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input "
                      id="love_money"
                      name="mode_financement"
                      defaultChecked={
                        formData?.mode_financement === "love_money"
                      }
                      data-testid="mode_financementId"
                      defaultValue={defaultValues.mode_financement}
                      {...register("mode_financement")}
                    />
                    <label className="quest-label" htmlFor="loveMoney">
                      Love money
                    </label>
                  </div>
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input "
                      id="obligation"
                      name="mode_financement"
                      defaultChecked={
                        formData?.mode_financement === "obligation"
                      }
                      data-testid="mode_financementId"
                      defaultValue={defaultValues.mode_financement}
                      {...register("mode_financement")}
                    />
                    <label className="quest-label" htmlFor="loveMoney">
                      Prêt / Obligations
                    </label>
                  </div>
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input "
                      id="action"
                      name="mode_financement"
                      defaultChecked={formData?.mode_financement === "action"}
                      data-testid="mode_financementId"
                      defaultValue={defaultValues.mode_financement}
                      {...register("mode_financement")}
                    />
                    <label className="quest-label" htmlFor="action">
                      Actions
                    </label>
                  </div>
                  <div className="form-quest-check">
                    <input
                      type="radio"
                      className="quest-control-input "
                      id="don"
                      name="mode_financement"
                      defaultChecked={formData?.mode_financement === "don"}
                      data-testid="mode_financementId"
                      defaultValue={defaultValues.mode_financement}
                      {...register("mode_financement")}
                    />
                    <label className="quest-label" htmlFor="don">
                      Donation
                    </label>
                  </div>
                  {formState.errors && formState.errors.mode_financement && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.mode_financement?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="quest-pp-block">
                <h3 className="title-questionnaire">
                  Sélectionnez le statut de votre projet actuel
                  <span className="text-danger">*</span>
                </h3>
                <div className="form-group">
                  <select
                    className="form-control"
                    {...register("statut_newprojet_by_porteur")}
                    defaultValue={defaultValues.statut_newprojet_by_porteur}
                  >
                    <option value="">
                      Sélectionner le statut de votre projet
                    </option>
                    {SATUS_NEWPROJET_BY_PORTEUR.map(
                      (statut_newprojet_by_porteur) => (
                        <option
                          key={statut_newprojet_by_porteur[0]}
                          value={statut_newprojet_by_porteur[0]}
                        >
                          {statut_newprojet_by_porteur[1]}
                        </option>
                      )
                    )}
                  </select>
                </div>
                {formState.errors &&
                  formState.errors.statut_newprojet_by_porteur && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.statut_newprojet_by_porteur?.message}
                    </div>
                  )}
              </div>
            </div>
            <div className="quest-pp-block d-flex flex-column">
              <div className="form-textarea-group">
                <label htmlFor="companyDescription" className="title-questionnaire">
                  {/* Décrivez ce que votre entreprise fait ou fera */}
                  Description du projet
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="description"
                  id="companyDescription"
                  cols="50"
                  rows="10"
                  defaultValue={defaultValues.description}
                  {...register("description")}
                  //   onChange={onChange}
                  data-testid="descriptionId"
                  className="form-control"
                />
                <small>10 lignes maximum</small>
                <br />
              </div>

              {formState?.errors && formState?.errors?.description && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState?.errors?.description?.message}
                </div>
              )}
            </div>
            <div className="d-flex flex-column">
              <div className="quest-pp-block form-textarea-group">
                <label htmlFor="marketSize" className="title-questionnaire">
                  Quelle est la taille de votre marché adressable (estimation à
                  la fois du nombre de clients et de la valeur) ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="taille_marche"
                  id="marketSize"
                  cols="50"
                  rows="10"
                  defaultValue={defaultValues.taille_marche}
                  {...register("taille_marche")}
                  data-testid="taille_marcheId"
                  className="form-control"
                />
                {formState?.errors && formState?.errors?.taille_marche && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.taille_marche?.message}
                  </div>
                )}
              </div>
              <div className="quest-pp-block form-textarea-group">
                <label htmlFor="marketFraction" className="title-questionnaire">
                  Quelle fraction de ce marché avez-vous déjà conquise ou
                  souhaitez-vous conquérir (le cas échéant) ? Objectif en 1 an ?
                  dans 3 ans ?<span className="text-danger">*</span>
                </label>
                <textarea
                  name="fraction_objectif"
                  id="marketFraction"
                  cols="50"
                  rows="10"
                  {...register("fraction_objectif")}
                  defaultValue={defaultValues.fraction_objectif}
                  data-testid="fraction_objectifId"
                  className="form-control"
                />
                {formState?.errors && formState?.errors.fraction_objectif && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.fraction_objectif?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-end flex-column">
          <div className="ms-auto">
            {isSuccessfullySubmitted && (
              <button className="btn-modifier" disabled>
                En cours &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
            {!isSuccessfullySubmitted && (
              <button className="btn-modifier">Modifier</button>
            )}
          </div>
        </div>
        
      </form>
    </div>
  );
}

export default TestEditProjet;
