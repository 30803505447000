import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSouscrire } from "../../../../redux/souscrire/souscrireAction.js";
import storage from "../../../../Http/storage.js";

const ListeProjetFinanceDetail = () => {
  const projectSouscri = useSelector((state) => state.souscrire);
  const { isLoading, results: projects } = projectSouscri;
  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const dispatch = useDispatch();
console.log({projectSouscri});
  useEffect(() => {
    dispatch(fetchSouscrire());
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setShowSkeleton(false);
        setError("La requête a pris trop de temps. Veuillez réessayer.");
      }, 30000); // 30000ms = 30s, ajustez le temps selon vos besoins
      return () => clearTimeout(timeoutId);
    } else {
      setShowSkeleton(false);
    }
  }, [isLoading]);

  return (
    <div className="">
      <div className="m-0 raise-money-titles liste-projets-bar">
        <div className="liste-projets-info">
          <h1 className="titreDashboard">Projets intéressés</h1>
        </div>
      </div>
      <div className="">
        {!showSkeleton && error && (
          <div className="text-center">
            <p className="bg-danger text-light font-weight-bold p-2">{error}</p>
          </div>
        )}
        {!showSkeleton && !error && (
          <table className="table tableInteresser table-striped">
            <thead>
              <tr>
                <th className="containTitreInteresser">Nom du projet</th>
                <th className="containTitreInteresser">Nom du porteur du projet</th>
                <th className="containTitreInteresser">Secteur d’activité</th>
                <th className="containTitreInteresser">Montant</th>
                <th className="containTitreInteresser">Personne Intéressé</th>
                <th className="containTitreInteresser">Date du projet</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}  className="m-4">
                  <td>{project.projet.title}</td>
                  <td>
                    {project.user?.prenom } {project.user?.nom || 'Non défini'}
                  </td>
                  <td className="textSecteur">
                    {project.projet?.secteur?.length
                      ? project.projet.secteur.map(
                          (item, index) =>
                            item.name +
                            `${index < project.projet.secteur.length - 1 ? " - " : ""}`
                        )
                      : "non défini"}
                  </td>
                  <td>
                    {project.projet?.fonds_supplementaires_prix
                      ? project.projet.fonds_supplementaires_prix
                      : "0"}{" "}
                    FCFA 
                  </td>
                  <td>
                  {project.projet?.nb_subscriber || 'Non défini'}
                  </td>
                  <td>
                  {project.projet?.date_lancement || 'Non défini'}
                  </td>
                  {/* <td>
                    <Link
                      to={{
                        pathname: `/dashboard/investisseur/detail-projetFinance/${project.projet.slug}`,
                        state: project,
                      }}
                      className="btn btn-primary"
                    >
                      Voir les détails
                    </Link>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ListeProjetFinanceDetail;
