import React from "react";
import entrepreneur from "./entrepreneur.png";

const LesInvestisseurs = ({ project }) => {
  return (
    <>
      <div className="investisseur-details">
        <div className="investisseurs">
          <h6
            style={{
              fontSize: "14px",
              fontWeight: 300,
              color: "#171B1E",
              fontFamily: "Poppins",
            }}
          >
            Les Investisseurs :
          </h6>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 300,
              color: "#171B1E",
              fontFamily: "Poppins",
              marginTop: "10px",
            }}
          >
            {project?.user.prenom || "Non défini"} {project?.user.nom}
          </p>
        </div>
        <div className="porteur-de-projet">
          <h6
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "14px",
              color: "#171B1E",
            }}
          >
            Profil du porteur de projet:
          </h6>
          <div
            className="porteur"
            style={{
              display: "flex",
              flexDirection: "column", // Mettre en colonne
              alignItems: "center", // Centrer horizontalement
              padding: "50px",
            }}
          >
            <img src={entrepreneur} />
            <p style={{ marginTop: "15px" }}>
              {project?.user.prenom || "Non défini"} {project?.user.nom}
            </p>
          </div>
          <div className="name-porteur">
            <div className="description-porteur">
              {/* <p>
                Moussa Diop est un soutien précieux pour les nouvelles
                entreprises. Il aide les entrepreneurs à transformer leurs idées
                en succès en leur offrant conseils et ressources
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LesInvestisseurs;
