import React, { useEffect } from "react";
import HeaderV2 from "../../../../BackendV2/Admin/Navs/HeaderV2";
import SidebarMobileTableauBord from "../../SidebarTableauBord/SidebarMobileTableauBord";
import SidebarTableauBord from "../../SidebarTableauBord/SidebarTableauBord";
import TabsProfile from "../TabsProfile/TabsProfile";
import Profil from "../../../../BackendV2/Admin/Parametre/Profil";
import ModificationMotDePasseComponent from "../../../TableauDeBord/ModificationMotDePasse/ModificationMotDePasseComponent";

const EditerProfilInvestisseur = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-investisseur">
      <HeaderV2 />
      <div className="">
        <div className="no-view-mobile">
          <SidebarTableauBord />
        </div>
        <div className="px-0 pr-4">
          <div className="p-3 pt-5 section-content-page-tb-investisseur">
            <TabsProfile />
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active in p-5 pt-0 containeredite"
                id="pills-activite"
                role="tabpanel"
                aria-labelledby="pills-activite-tab"
              >
                <Profil />
              </div>
              <div
                className="tab-pane fade in p-5 pt-0 containeredite"
                id="pills-solde"
                role="tabpanel"
                aria-labelledby="pills-solde-tab"
              >
                <ModificationMotDePasseComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditerProfilInvestisseur;
