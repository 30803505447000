import React, { useMemo, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "./AlimenterSonCompte.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Invest from "../../Services/Invest";
import Swal from "sweetalert2";

const ChoisirMontant = ({ formData, setFormData, navigation }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const users = useSelector((state) => state.user);
  const userType = users.data;
  // console.log(userType);
  const [investId, setInvestId] = useState(null);

  // ajouter le token
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        montant: yup
          .number()
          .required("Veuillez répondre avant de continuer")
          .nullable(),
      }),
    []
  );
  const { handleSubmit, clearErrors, formState, setValue, register } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("montant", formData?.montant);
    setValue("moyen_paiement", formData?.moyen_paiement);
  }, [formData, setValue]);
  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const postData = {
        investisseur: userType?.id,
        projet: projectId,
        montant: data.montant,
        moyen_paiement: data.moyen_paiement,
      };
      // Envoyer la candidature avec les données à votre API
      const response = await Invest.onPostInvest(postData, config);
      setInvestId(response?.slug);
      // console.log({setInvestId});
      if (response) {
        await Swal.fire({
          icon: "success",
          title: "Vous avez financé ce projet avec succès",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(investId)
      }
      navigate('/liste-des-projets');
    } catch (error) {
      setIsLoading(false);
      console.error("Erreur lors de la soumission :", error);
      // Gérer les erreurs
      await Swal.fire({
        icon: "error",
        title: "Une erreur est survenu lors de la soumission",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="choisir-montant px-5">
      <h2 className="text-center" style={{ marginTop: "5rem" }}>
        Choisissez un montant
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-5" style={{ marginTop: "10rem" }}>
          <div className="d-flex flex-fill form-group-choisir-montant align-items-end">
            <label htmlFor="montant" style={{ opacity: 0.5 }}>
              Montant
            </label>
            <input
              type="number"
              id="montant"
              name="montant"
              placeholder="100000"
              className="border-0 pl-3 input-choisir-montant p-2"
              {...register("montant")}
              // value={montant}
              onChange={(e) => setValue("montant", e.target.value)}
              data-testid="montantId"
            />
          </div>
          {formState.errors && formState.errors.montant && (
            <div className="alert alert-danger gfa-alert-danger" role="alert">
              {formState.errors.montant?.message}
            </div>
          )}
        </div>
        <div className="flex-fill d-flex justify-content-end align items-center mode-paiement-buttons">
          <button
            className="button-alimenter-son-compte button-alimenter-son-compte-precedent mr-3"
            onClick={navigation?.previous}
          >
            Précédent
          </button>
          <button
        className="button-alimenter-son-compte button-alimenter-son-compte-suivant"
        type="submit"
        data-testid="btnId"
        disabled={isLoading} 
      >
        {isLoading ? "Chargement..." : "Soumettre"}
      </button>
        </div>
      </form>
    </div>
  );
};

export default ChoisirMontant;

