import React from "react";
import profile from "../../../images/icons/Profile.png";
import Projet from "../../Services/Projet";
import Swal from "sweetalert2";
import { useSelector } from 'react-redux';

function SuiviProjet(props) {
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
    },
  };
    const current_user = useSelector((state) => state.user).data;
    const project = useSelector((state) => state.projectsForVisitors);
    console.log("les projets", project);
  const userType = sessionStorage.getItem("userType");

  const handleSubscribeProject = async () => {
   
    let request = Projet.onSubscriber(config);
    await request
      .then(async (res) => {
        console.log(res);
        await Swal.fire({
          icon: "success",
          title: "Souscription reussi",
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((error) => {
        console.log(error?.response);
        if (error?.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Vous avez déjà souscrit à ce projet.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier si vous êtes connecté.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };

  const handleFollowProject = async (data) => {

    const formData = new FormData();
    formData.append("is_subscriber", data.is_subscriber);
    formData.append("user", current_user.id);
    formData.append("projet", project.id);

    let request = Projet.onFollow(formData, config);
    await request
      .then(async (res) => {
        console.log(res);
        await Swal.fire({
          icon: "success",
          title: "Reussi",
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((error) => {
        console.log(error?.response);
        if (error?.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Vous suivez déjà ce projet",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier si vous êtes connecté.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div>
      <div className="bordure-deux-cartes">
        <div className="card">
          <p className="p-3 recevoir-person">
            Recevez les nouvelles du projet dans votrre boite email
          </p>
          <div className="carte-profile-text"></div>
          <div className="p-3 d-flex justify-content-between align-items-center">
            <div className="button-text">
              <img src={profile} alt="profileimage" />
              <span className="text-person">
                Ce projet est suivi par {props.Count || "(0)"} personne(s)
              </span>
            </div>

            {userType !== "standard" && (
              <div className="auth-container register-btn-container">
                <button
                  className="link-button trans-0-2 mx-2"
                  onClick={handleFollowProject}
                >
                  Suivre
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card">
          <div className="text-recevoir-rappel">
            <p className="p-3 recevoir-person">
              Recevoir un mail de rappel d’investir tous les 10jours dans votre
              boite email
            </p>
          </div>
          <div className="carte-profile-text"></div>
          <div className="p-3 d-flex justify-content-between align-items-center">
            <div className="button-text">
              <img src={profile} alt="profileimage" />
              <span className="text-person">
                {props.investisseur || "(0)"} personnes s’intéressent à ce
                projet
              </span>
            </div>
            <div className="">
              {userType !== "standard" && (
                <div className="auth-container register-btn-container">
                  <button
                    className="link-button trans-0-2 mx-2"
                    onClick={handleSubscribeProject}
                  >
                    Souscrire
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuiviProjet;
