/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import baseUrl from "../../Http/backend-base-url";
// import { useDispatch } from "react-redux";
// import Swal from "sweetalert2";
import PhotoProfil from "../../images/others/default-profile-avatar.png";
import api from "../../Http/global-vars";

function ModalFaqContent({ message }) {
  const [isLoading, setIsLoading] = useState(false);
  const [responses, setResponses] = useState([]);
  const [id, setId] = useState(null);
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { register, handleSubmit, formState, reset, clearErrors } = useForm();

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (message?.id) {
      getResponses(message?.id);
      setId(message.id);
      register("response", {
        required: "La réponse est obligatoire.",
      });
    }
  }, [message, user]);

  useEffect(() => {
    if (id) {
      getResponses(id);
    }
  }, [id, message]);

  const getResponses = (itemId) => {
    Axios.get(`${api}responsebycontact/${itemId}`, config)
      .then((response) => {
        console.log("response", response);
        setResponses(response?.data?.results?.sort((a, b) => b?.id - a?.id));
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const onSubmit = (data, e) => {
    data["message"] = message?.id;
    data["user"] = user?.data?.id;
    setIsLoading(true);
    Axios.post(`${api}responsecontact/`, data, config)
      .then(async (response) => {
        console.log("response statut", response);
        await getResponses(id);
        // await dispatch(fetchMessagesContact());
        // await Swal.fire({
        //   icon: "success",
        //   title: "Statut modifié avec succès!",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        // await window.$(`#contactStatusModal${message?.id}`).modal("hide");
        await reset();
        await e.target.reset();
        await setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error?.response);
      });
  };

  const handleReset = () => {
    reset();
    // setId(null)
    // setResponses([])
  };
  return (
    <div
      className="modal fade modal-faq modal-faq-contact"
      id={`contactModal${message?.id}`}
      tabIndex="-1"
      data-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header">
            <h5
              className="modal-title titre-question-reponse"
              id="exampleModalLabel"
            >
              Réponses à ({message?.nom_complet})
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handleReset()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="div-para-contact-modal mb-5">
              <p className="contact-modal-para">{message?.message}</p>
            </div>
            <form
              className="contact-modal-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label
                  htmlFor="message-text"
                  className="col-form-label label-reponse"
                >
                  Réponses
                </label>
                <textarea
                  className="form-control input-reponse"
                  id="message-text"
                  rows="7"
                  name="response"
                  {...register("response", {
                    required: "La réponse est obligatoire.",
                  })}
                  placeholder="Ajouter une réponse"
                ></textarea>
                {formState?.errors && formState?.errors?.response && (
                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                    {formState?.errors?.response?.message}
                  </div>
                )}
              </div>
              <div className="modal-footer faq-modal-footer">
                <button
                  className="btn btn-retour-faq-modal"
                  data-bs-dismiss="modal"
                  onClick={() => handleReset()}
                >
                  Retour
                </button>
                {!isLoading && (
                  <button className="btn btn-envoyer-faq-modal">Envoyer</button>
                )}
                {isLoading && (
                  <button disabled className="btn btn-envoyer-faq-modal">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
            {responses?.length
              ? responses.map((resp) => (
                  <div
                    className="card-dashboard"
                    style={{ marginTop: "12px" }}
                    key={resp?.id}
                  >
                    <div className="col-12 itm-center">
                      <img
                        className="avatar-auteur-forum-card"
                        // src={PhotoProfil}
                        src={
                          resp?.user?.avatar &&
                          !resp?.user?.avatar?.includes("default.png")
                            ? baseUrl + resp?.user?.avatar
                            : PhotoProfil
                        }
                        alt="Avatar"
                      />
                      <p className="text-forum-card fs-12 fw-300 sender-name">
                        Par&nbsp;
                        <strong>
                          {resp?.user?.prenom + " " + resp?.user?.nom}
                        </strong>
                        <br />
                        <span className="cm-sender-email">
                          {resp?.admin?.email}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 itm-center">
                      <p
                        className="text-forum-card fs-12 fw-300"
                        style={style.resp_style}
                      >
                        {resp?.response}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
          {/* <div className="modal-footer faq-modal-footer">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-bs-dismiss="modal"
            >
              Retour
            </button>
            <button type="button" className="btn btn-envoyer-faq-modal">
              Envoyer
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ModalFaqContent;

const style = {
  resp_style: {
    margin: "1em 0px 0em 0em",
    fontSize: "20px",
    fontWeight: "500",
  },
  select_style: {
    display: "flex",
    alignItems: "self-start",
    flexDirection: "row-reverse",
  },
};
