import React, { useState } from "react";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

const DetailProjetDescription = ({ project, objectif }) => {
  const [isContentVisible, setIsContentVisible] = useState([
    true,
    false,
    false,
    false,
    false
  ]);
  const [isIconExpanded, setIsIconExpanded] = useState([
    false,
    false,
    false,
    false,
    false
  ]);

  const toggleContentVisibility = (index) => {
    const updatedIsContentVisible = [...isContentVisible];
    updatedIsContentVisible[index] = !updatedIsContentVisible[index];
    setIsContentVisible(updatedIsContentVisible);

    const updatedIsIconExpanded = [...isIconExpanded];
    updatedIsIconExpanded[index] = !updatedIsIconExpanded[index];
    setIsIconExpanded(updatedIsIconExpanded);
  };

  return (
    <div className="col-md-8 description">
      <div className="detail-projet-desc-content card">
        <div
          className="card-header border-bottom d-flex justify-content-between align-items-center"
          onClick={() => toggleContentVisibility(0)}
        >
          <h2 className="detail-projet-title-desc">Description</h2>
          <span onClick={() => toggleContentVisibility(0)}>
            {isIconExpanded[0] ? <ExpandLess /> : <ExpandMore />}
          </span>
        </div>
        {isContentVisible[0] && (
          <div className="card-body">
            <div className="detail-projet-desc-text-container">
              <p className="detail-projet-desc-text">{project?.description}</p>
            </div>
            <div className="detail-projet-desc-infos">
              <div className="detail-projet-desc-objectif">
                <span className="detail-projet-desc-info-label">Objectif:</span>
                <span className="detail-projet-desc-info-val">
                  100% ({objectif} FCFA)
                </span>
              </div>
              <div className="detail-projet-desc-objectif">
                <span className="detail-projet-desc-info-label">
                  Déplafonnement:
                </span>
                <span className="detail-projet-desc-info-val">
                  Je ne sais pas quoi mettre
                </span>
              </div>
              <div className="detail-projet-desc-objectif">
                <span className="detail-projet-desc-info-label">
                  Remboursements:
                </span>
                <span className="detail-projet-desc-info-val">
                  Je ne sais pas quoi mettre
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="detail-projet-desc-content card">
        <div
          className="card-header border-bottom d-flex justify-content-between align-items-center"
          onClick={() => toggleContentVisibility(1)}
        >
          <h2 className="detail-projet-title-desc">
            À quoi servira le financement?
          </h2>
          <span onClick={() => toggleContentVisibility(1)}>
            {isIconExpanded[1] ? <ExpandMore /> : <ExpandLess />}
          </span>
        </div>
        {isContentVisible[1] && (
          <div className="detail-projet-desc-text-container card-body">
            <p className="detail-projet-desc-text">
              {project.fonds_supplementaires_usage}
            </p>
          </div>
        )}
      </div>
      <div className="detail-projet-desc-content card">
        <div
          className="card-header border-bottom d-flex justify-content-between align-items-center"
          onClick={() => toggleContentVisibility(2)}
        >
          <h2 className="detail-projet-title-desc">Pourquoi investir?</h2>
          <span onClick={() => toggleContentVisibility(2)}>
            {isIconExpanded[2] ? <ExpandMore /> : <ExpandLess />}
          </span>
        </div>
        {isContentVisible[2] && (
          <div className="detail-projet-desc-text-container card-body">
            <p className="detail-projet-desc-text">{project.sauce_secrete}</p>
          </div>
        )}
      </div>
      <div className="detail-projet-desc-content card">
        <div
          className="card-header border-bottom d-flex justify-content-between align-items-center"
          onClick={() => toggleContentVisibility(3)}
        >
          <h2 className="detail-projet-title-desc">Risques et défis</h2>
          <span onClick={() => toggleContentVisibility(3)}>
            {isIconExpanded[3] ? <ExpandMore /> : <ExpandLess />}
          </span>
        </div>
        {isContentVisible[3] && (
          <div className="detail-projet-desc-text-container card-body">
            <p className="detail-projet-desc-text">{project.probleme}</p>
          </div>
        )}
      </div>
      <div className="detail-projet-desc-content card">
        <div
          className="card-header border-bottom d-flex justify-content-between align-items-center"
          onClick={() => toggleContentVisibility(4)}
        >
          <h2 className="detail-projet-title-desc">Informations supplémentaires</h2>
          <span onClick={() => toggleContentVisibility(4)}>
            {isIconExpanded[4] ? <ExpandMore /> : <ExpandLess />}
          </span>
        </div>
        {isContentVisible[4] && (
          <div className="detail-projet-desc-text-container card-body" style={{ maxHeight: "400px", overflowY: "auto" }}>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Pays</h2>
              <p className="texte-para">
                {project?.senegal || "Non communiqué"} 
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Objectif fraction</h2>
              <p className="texte-para">
                {project?.fraction_objectif || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Quand ?</h2>
              <p className="texte-para">{project?.quand || "Non communiqué"}</p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Combien ?</h2>
              <p className="texte-para">
                {project?.combien || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Probleme ?</h2>
              <p className="texte-para">
                {project?.probleme || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Pourquoi avez-vous ce problème?
              </h2>
              <p className="texte-para">
                {project?.pourquoi_probleme || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Secret ?</h2>
              <p className="texte-para">
                {project?.sauce_secrete || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">valeur ?</h2>
              <p className="texte-para">
                {project?.valeur || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Le produit</h2>
              <p className="texte-para">
                {project?.produit_marche || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">La durée du produit</h2>
              <p className="texte-para">
                {project?.produit_duree || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Le taux croissance du dernier mois
              </h2>
              <p className="texte-para">
                {project?.taux_croissance_dernier_mois || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Le revenu</h2>
              <p className="texte-para">
                {project?.revenus || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Le revenu du dernier mois
              </h2>
              <p className="texte-para">
                {project?.revenu_dernier_mois || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Evolutivite</h2>
              <p className="texte-para">
                {project?.evolutivite || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Le besoin evolutivite</h2>
              <p className="texte-para">
                {project?.besoin_evolutivite || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Concurrents</h2>
              <p className="texte-para">
                {project?.concurrents || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Le positionnement stratégique
              </h2>
              <p className="texte-para">
                {project?.positionnement_strategique || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                L'avantage concurrentiel
              </h2>
              <p className="texte-para">
                {project?.avantage_concurrentiel || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Maintenance avantage concurrentiel
              </h2>
              <p className="texte-para">
                {project?.maintenance_avantage_concurrentiel ||
                  "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Autres_commentaires</h2>
              <p className="texte-para">
                {project?.autres_commentaires || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Le financement</h2>
              <p className="texte-para">
                {project?.financement || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Fonds supplementaires</h2>
              <p className="texte-para">
                {project?.fonds_supplementaires || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Fonds supplementaires prix
              </h2>
              <p className="texte-para">
                {project?.fonds_supplementaires_prix || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Date lieu societe</h2>
              <p className="texte-para">
                {project?.date_lieu_societe || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">La capitale</h2>
              <p className="texte-para">
                {project?.capitale || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Répartition propriete</h2>
              <p className="texte-para">
                {project?.repartition_propriete || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">L'actionnarat</h2>
              <p className="texte-para">
                {project?.actionnarat || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Le bio fondateur</h2>
              <p className="texte-para">
                {project?.bio_fondateur || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Position</h2>
              <p className="texte-para">
                {project?.position || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Courriel</h2>
              <p className="texte-para">
                {project?.courriel || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Lieu connaissance</h2>
              <p className="texte-para">
                {project?.lieu_connaissance || "Non communiqué"}
              </p>
            </div>
            <div className="py-3">
              <h1 className="text-details-bold pb-5">Description</h1>
              <p className="texte-para">
                {project?.type_programme || "Type programme indéfini"}
              </p>
            </div>
            <div className="py-2">
              <h5 className="text-details-bold pb-5">
                À quoi servira le financement?
              </h5>
              <p className="texte-para">
                {project?.url_demo || "L'url du démo indéfinis"}
              </p>
            </div>
            <div className="pb-5">
              <h2 className="text-details-bold pb-5">Pourquoi investir?</h2>
              <p className="texte-para">
                {project?.taille_marche || "Non communiqué"}
              </p>
            </div>

            <div className="py-2">
              <h2 className="text-details-bold pb-5">Description titre</h2>
              <p className="texte-para">
                {project?.description_titre || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Site</h2>
              <p className="texte-para">{project?.site || "Non communiqué"}</p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                L'objectif du financement
              </h2>
              <p className="texte-para">
                {project?.objectif_financement || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Mode financement</h2>
              <p className="texte-para">
                {project?.mode_financement || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Admin</h2>
              <p className="texte-para">
                {project?.from_admin || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Le statut</h2>
              <p className="texte-para">
                {project?.statut || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">L'état</h2>
              <p className="texte-para">{project?.etat || "Non communiqué"}</p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Montant part</h2>
              <p className="texte-para">
                {project?.montant_part || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Un ticket minimum</h2>
              <p className="texte-para">
                {project?.ticket_minimum || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Souscription pallier</h2>
              <p className="texte-para">
                {project?.souscription_pallier || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Taux entré</h2>
              <p className="texte-para">
                {project?.taux_entree || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Durée existance</h2>
              <p className="texte-para">
                {project?.duree_existance || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Utilisateurs actifs</h2>
              <p className="texte-para">
                {project?.utilisateurs_actifs || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Taux</h2>
              <p className="texte-para">{project?.taux || "Non communiqué"}</p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Revenu annuel prévu</h2>
              <p className="texte-para">
                {project?.revenu_annuel_prevu || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">
                Revenu annuel prevu trois ans
              </h2>
              <p className="texte-para">
                {project?.revenu_annuel_prevu_trois_ans || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Les kpis</h2>
              <p className="texte-para">{project?.kpis || "Non communiqué"}</p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Valeur annuel kpis</h2>
              <p className="texte-para">
                {project?.valeur_annuel_kpis || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Vitesse kpis</h2>
              <p className="texte-para">
                {project?.vitesse_kpis || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Besoin pour croitre</h2>
              <p className="texte-para">
                {project?.besoins_pour_croitre || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Vitesse croissance</h2>
              <p className="texte-para">
                {project?.vitesse_croissance || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Nombre souscrit</h2>
              <p className="texte-para">
                {project?.nb_subscriber || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Nombre follower</h2>
              <p className="texte-para">
                {project?.nb_follower || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Montant</h2>
              <p className="texte-para">
                {project?.montant || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Date fin</h2>
              <p className="texte-para">
                {project?.date_fin || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Stade du développment</h2>
              <p className="texte-para">
                {project?.stade_development || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Projet validé</h2>
              <p className="texte-para">
                {project?.is_validated || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Modèle intervention</h2>
              <p className="texte-para">
                {project?.modele_intervention || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Stratégie sortie</h2>
              <p className="texte-para">
                {project?.strategie_sortie || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">Instrument financier</h2>
              <p className="texte-para">
                {project?.instrument_financier || "Non communiqué"}
              </p>
            </div>
            <div className="py-2">
              <h2 className="text-details-bold pb-5">comments</h2>
              <p className="texte-para">
                {project?.comments || "Non communiqué"}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailProjetDescription;
