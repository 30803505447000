import React from "react";
import "./SidebarTableauBord.css";
import { NavLink } from "react-router-dom";

const SidebarMobileTableauBord = () => {
  return (
    <div className="component-sidebar-mobile-tableau-bord">
      <div className="sidebar-mobile-tb-customisation ">
        <div className="hoverable bg-white">
          <ul className="nav nav-items-tb-custom d-flex">
          <li className="nav-item nav-item-vertical-custom nav-sidebar-items-custom">
              <NavLink
                className="nav-link nav-link-sidebar-custom"
                to="/dashboard-investisseur"
              >
                <span className="">Mon profil</span>
              </NavLink>
            </li>
            
            <li className="nav-item nav-item-tb-vertical-custom">
              <NavLink
                className="nav-link nav-link-sidebar-tb-custom"
                to="/dashboard-investisseur/resume"
              >
                <span className="">Résumé</span>
              </NavLink>
            </li>

            <li className="nav-item nav-item-tb-vertical-custom">
              <NavLink
                className="nav-link nav-link-sidebar-tb-custom"
                to="/dashboard-investisseur/prets"
              >
                <span className="">Prêt</span>
              </NavLink>
            </li>
            <li className="nav-item nav-item-tb-vertical-custom">
              <NavLink
                className="nav-link nav-link-sidebar-tb-custom"
                to="/dashboard-investisseur/transactions"
              >
                <span className="">Transactions</span>
              </NavLink>
            </li>

            <li className="nav-item nav-item-tb-vertical-custom">
              <NavLink
                className="nav-link nav-link-sidebar-tb-custom"
                to="/dashboard-investisseur/mon-compte"
              >
                <span className="">Mon compte</span>
              </NavLink>
            </li>
            <li className="nav-item nav-item-tb-vertical-custom">
              <NavLink
                className="nav-link nav-link-sidebar-tb-custom"
                to="/dashboard-investisseur/mes-actions"
              >
                <span className="">Mes actions</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobileTableauBord;
