// import Logo from '../../../images/ahj-logo.png';
import React from "react";
import "./FrontFooterV2.css";
// import Logo from '../../../images/ahj-logo.png';
import Facebook from "../../../assets/images/icons/facebook.png";
import Instagram from "../../../images/icons/instagram.png";
import LinkedIn from "../../../assets/images/icons/linkedin.png";

// import Linkedin from '../../../images/icons/linkedin.png';
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
// import IconPrint from "../../../assets/images/icons/print.png";

const FrontFooterV2 = (props) => {
  return (
    <footer className="component-front-footer">
      <div className="container-aem">
        <div className="container pt-5">
          <div className="row align-items-start">
            <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 pb-4 footer-grid">
              <p className="text-center text-lg-start title-footer mb-2">Besoin d’aide?</p>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to="/nous-contacter">
                  Nous contacter
                </NavLink>
              </div>
            </div>
            <div className="col-xl-4 col-lg-3 col-md-4 col-sm-6 pb-4 footer-grid">
              <p className="text-center text-lg-start title-footer mb-2">Liens utiles</p>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to={"/"}>
                  Accueil
                </NavLink>
              </div>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to={"/"}>
                  Les projets
                </NavLink>
              </div>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to={"/investir"}>
                  Investir
                </NavLink>
              </div>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to={"/se-financer"}>
                  Se Financer
                </NavLink>
              </div>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to={"comment-ca-marche"}>
                  Comment ça marche
                </NavLink>
              </div>
              <div className="text-center text-lg-start pb-2">
                <NavLink className="link-footer" to={"/a-propos-de-nous"}>
                  A propos de nous
                </NavLink>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 pb-4 footer-grid">
              <div>
                <div className="footer-reseaux-sociaux-container">
                  <div className="">
                    <div className="text-center text-lg-start text-center text-lg-start pb-2 link-footer d-flex">
                      <IconlyPack.Location
                        set="bold"
                        primaryColor="var(--primaryColor)"
                      />
                      <div className="text-center text-lg-start pl-2">
                        Hlm Grand Yoff Villa n°241
                        
                        près du terminus des bus Dakar
                         Dem Dikk qui vont à l'AIBD`
                         Dakar 11500
                      </div>
                    </div>
                    <div className="text-center text-lg-start pb-2">
                      <NavLink className="link-footer" to={"tel:+221767987776"}>
                        <IconlyPack.Call
                          set="bold"
                          primaryColor="var(--primaryColor)"
                        />
                        <span className="pl-2">(+221) 76 798 77 76</span>
                      </NavLink>
                    </div>
                    <div className="d-flex justify-content-around align-items-center pb-2">
                        <p className="text-center text-lg-start title-media-sociaux">Médias </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-center text-lg-start social-icon-item-container">
                          <NavLink
                            className="social-link-item"
                            target="_blank"
                            to={"https://www.facebook.com/elikia.venture"}
                          >
                            <img
                              src={Facebook}
                              alt="Facebook"
                              className="social-img"
                            />
                          </NavLink>
                        </div>
                        <div className="text-center text-lg-start social-icon-item-container">
                          <NavLink
                            className="social-link-item"
                            target="_blank"
                            to={
                              "https://www.linkedin.com/company/elikia-ventures/"
                            }
                          >
                            <img
                              src={LinkedIn}
                              alt="LinkedIn"
                              className="social-img"
                            />
                          </NavLink>
                        </div>
                        <div className="text-center text-lg-start social-icon-item-container">
                          <NavLink
                            className="social-link-item"
                            target="_blank"
                            to={"https://www.instagram.com/elikia_venture/"}
                          >
                            <img
                              src={Instagram}
                              alt="Instagram"
                              className="social-img"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0  mt-1 py-3 d-flex justify-content-center align-items-center">
        <div className="col-md-6 offset-md-4 col-sm-12 mt-lg-0 px-0 d-lg-flex">
          <p className="copyright">2024 © Elikia Funding</p>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooterV2;
