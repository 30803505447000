import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as ReactIconly from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import ListProjetSkeleton from "../ProjetEnLigneIncubateur/ListProjetSkeleton";
import { fetchProjectsByIncubateur } from "../../../../redux/ProjectsByIncubateur/ProjectsByIncubateurAction";
import storage from "../../../../Http/storage";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderComponentV2 from "../../Admin/HeaderComponent/HeaderComponentV2";

function ProjetFinancer() {
  const current_user = useSelector((state) => state.user.data);
  const id = current_user?.id;
  const projectsForVisitors = useSelector(
    (state) => state.projectsByIncubateur
  );
  const { isLoading, data: projectsData } = projectsForVisitors;

  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [filteredProjets, setFilteredProjets] = useState([]);
  console.log('filteredProjets', filteredProjets);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setShowSkeleton(true);
      dispatch(fetchProjectsByIncubateur(id)).then((response) => {
        setShowSkeleton(false);
        if (response?.error) {
          setError(
            "Une erreur est survenue lors du chargement des projets. Veuillez réessayer."
          );
        }
      });

      const timeoutId = setTimeout(() => {
        if (showSkeleton) {
          setShowSkeleton(false);
          setError("La requête a pris trop de temps. Veuillez réessayer.");
        }
      }, 300000);

      return () => clearTimeout(timeoutId);
    }
  }, [dispatch, id]);

  useEffect(() => {
    const filtered = projectsData.filter((projet) => {
      if (activeTab === 0) {
        return true;
      } else if (activeTab === 1) {
        return projet.statut === "valider";
      } else if (activeTab === 2) {
        return projet.statut === "rejeter";
      } else if (activeTab === 3) {
        return projet.statut === "nouveau";
      }
      return false;
    }, []);

    setFilteredProjets(filtered);
  }, [activeTab, projectsData]);

  return (
    <div>
      <div className="component-mes-projet-tableau-de-bord">
        <HeaderComponentV2 />
        <div className="mt-3 row p-0" style={{ background: "white" }}>
          <div className="col-lg-4 col-xl-2">
            <NavbarV2 />
          </div>
          <div className="col-lg-8 col-xl-10">
            <div className="projets">
            <div style={{ background: "white" }}>
        <div className="raise-money-titles liste-projets-bar">
          <div className="liste-projets-info">
            <h1 className="liste-projets-title">Projets financés</h1>
          </div>
        </div>
        {/* <div className="px-3 mb-3 d-flex menu">
          <ul className="detail-projets-tabs flex-column align-items-start flex-md-row align-items-md-center p-0">
            <li
              className={`detail-projets-tabs-item ${
                activeTab === 0 && "active-tab"
              }`}
              onClick={() => setActiveTab(0)}
            >
              Tous les projets
            </li>
            <li
              className={`detail-projets-tabs-item ${
                activeTab === 1 && "active-tab"
              }`}
              onClick={() => setActiveTab(1)}
            >
              Projets validés
            </li>
            <li
              className={`detail-projets-tabs-item ${
                activeTab === 2 && "active-tab"
              }`}
              onClick={() => setActiveTab(2)}
            >
              Projets rejetés
            </li>
            <li
              className={`detail-projets-tabs-item ${
                activeTab === 3 && "active-tab"
              }`}
              onClick={() => setActiveTab(3)}
            >
              Projets soumis
            </li>
          </ul>
        </div> */}
        <div>
          {showSkeleton && <ListProjetSkeleton />}
          {!showSkeleton && error && (
            <div className="text-center">
              <p className="bg-danger text-light font-weight-bold p-2">
                {error}
              </p>
            </div>
          )}
          {!showSkeleton && !error && (
            <div className="row project-list-container">
              {filteredProjets.map((project) => (
                <div
                  className="col-md-4 project-item-container"
                  key={project.id}
                >
                  <Link
                    to={{
                      pathname: `/dashboard/incubateur/projetFinancerDetail/:projectId${project.slug}`,
                      state: project,
                    }}
                    className="d-flex justify-content-center"
                  >
                    <div
                      className="project-item-global-card"
                      style={{ width: "340px" }}
                    >
                      <div className="card bg-white project-item-card">
                        <div className="project-item-card-img-container">
                          <video
                            controls
                            className="card-img-top project-item-img"
                          >
                            <source
                              src={storage + project?.video}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                        <div className="card-body bg-white p-0 project-item-card-body">
                          <div className="d-flex justify-content-between align-items-center pt-3">
                            <div className="">
                              <div
                                className="financement"
                                style={{ backgroundColor: "#E87930" }}
                              >
                                <p
                                  style={{
                                    fontSize: "10.3px",
                                    color: "#E9F3F4",
                                    textAlign: "center",
                                    fontWeight: "700",
                                    padding: "6px 20px",
                                  }}
                                >
                                  {project?.secteur?.length
                                    ? project.secteur.map(
                                        (item, index) =>
                                          item.name +
                                          `${
                                            index < project.secteur.length - 1
                                              ? " - "
                                              : ""
                                          }`
                                      )
                                    : "non défini"}
                                </p>
                              </div>
                            </div>
                            {/* <div className="">
                              <div className="financement">
                                <p
                                  style={{
                                    color: "#E9F3F4",
                                    textAlign: "center",
                                    fontSize: "9px",
                                    fontWeight: "900",
                                  }}
                                >
                                  {project.statut === "valider" ? (
                                    <span className="bg-success text-white py-2 px-4 bordureStatut">
                                      Validé
                                    </span>
                                  ) : project.statut === "rejeter" ? (
                                    <span className="bg-danger text-white py-2 px-4 bordureStatut">
                                      Rejeté
                                    </span>
                                  ) : (
                                    <span className="bg-secondary text-white py-2 px-4 bordureStatut">
                                      Soumis
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div> */}
                          </div>
                          <div className="px-4 py-2">
                            <h6
                              className="card-title mt-3 mr3 project-item-name"
                              style={{
                                fontSize: "18px",
                                marginLeft: "-28px",
                                color: "#808080",
                                fontWeight: "700",
                                lineHeight: "21px",
                              }}
                            >
                              {project.title}
                            </h6>
                            <div className="row">
                              <div className="col">
                                <div className="financement">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Besoin en financement:{" "}
                                  </span>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#F68B1E",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    FCFA{" "}
                                    {project?.fonds_supplementaires_prix
                                      ? project.fonds_supplementaires_prix
                                      : "Montant non défini"}
                                  </p>
                                </div>
                              </div>
                              <div className="col ml-auto">
                                <div
                                  className="financement"
                                  style={{ marginLeft: "40px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Montant atteint:{" "}
                                  </span>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#369E8A",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    FCFA{" "}
                                    {project?.fonds_supplementaires_prix
                                      ? project.fonds_supplementaires_prix
                                      : "non défini"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
            </div>
          </div>
        </div>
        {/* <FrontFooterV2 /> */}
      </div>
      
    </div>
  );
}

export default ProjetFinancer;
