import React from "react";

function SecteurInvestissement() {
  const investmentData = [
    {
      sector: "Agriculture",
      amount: "2.500.000F CFA",
      percentage: "30%",
      color: "#007D9C",
    },
    {
      sector: "Santé",
      amount: "7.000.000F CFA",
      percentage: "40%",
      color: "#FE452A",
    },
    {
      sector: "Numérique",
      amount: "4.000.000F CFA",
      percentage: "25%",
      color: "#D123B3",
    },
    {
      sector: "Éducation",
      amount: "1.000.000F CFA",
      percentage: "20%",
      color: "#F7E018",
    },
    {
      sector: "Logistique",
      amount: "500.000F CFA",
      percentage: "20%",
      color: "#fff",
    },
    {
      sector: "Énergie",
      amount: "5.000.000F CFA",
      percentage: "20%",
      color: "#244D70",
    },
    {
      sector: "Transport",
      amount: "10.000.000F CFA",
      percentage: "30%",
      color: "#007D9C",
    },
  ];

  const totalAmount = "25.000.000F CFA";
  return (
    <div className="bg-white card p-4">
      <div className="investment">
        <div className="d-flex justify-content-between align-items-center">
        <h3 className="investmentDetail">Detail d’investissement</h3>

        </div>
        <div className="d-flex justify-content-between align-items-center investment-details">
        <h2>{totalAmount}</h2>
        <p className="investment-details investment-mois">Mars</p>
        </div>
        <table>
          <thead >
            <tr className="">
              <th>Couleur</th>
              <th>Secteur</th>
              <th>Montant</th>
              <th>Pourcentage</th>
            </tr>
          </thead>
          <tbody>
            {investmentData.map((item, index) => (
              <tr key={index}>
                <td>
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                </td>
                <td>{item.sector}</td>
                <td>{item.amount}</td>
                <td>{item.percentage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SecteurInvestissement;
