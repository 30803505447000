import { Entrepreneur } from './Endpoints'
import axios from 'axios'

export default {
  async postEntrepreneur(data, config) {
    const response = await axios.post(Entrepreneur.onPostEntrepreneur(), data, config);
    return response.data;
  },
  async getEntrepreneur(data, config) {
    const response = await axios.get(Entrepreneur.onGetEntrepreneur(), data, config);
    return response.data;
  },
};
