import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ListProjetSkeleton from "./ListProjetSkeleton";
import { fetchProjectsForVisitors } from "../../../../../redux/projectsForVisitors/ProjectForVisitorAction";
import "./enligne.css";
import storage from "../../../../../Http/storage";

const ProjetsEnLigneInvestisseur = () => {
  const projectsForVisitors = useSelector((state) => state.projects);
  const { isLoading, filteredData } = projectsForVisitors;
  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setShowSkeleton(false);
        setError("La requête a pris trop de temps. Veuillez réessayer.");
      }, 300000);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchProjectsForVisitors());
      setShowSkeleton(false);
      setProjects(filteredData.slice(0, 109));
    }
  }, [isLoading, filteredData]);

  return (
    <div className="" style={{ background: "white" }}>
      <h1 className="raise-money-titles">Nos projets</h1>
      <div className="">
        {showSkeleton && <ListProjetSkeleton />}
        {!showSkeleton && error && (
          <div className="text-center">
            <p className="bg-danger text-light font-weight-bold p-2">{error}</p>
          </div>
        )}
        {/* {!showSkeleton && !error && ( */}
        <>
          <div className="row project-list-containe ">
            <div className="row project-list-containe">
              {projects.map((project) => (
                <div
                  className="col-md-4 project-item-container"
                  key={project.id}
                  style={{}}
                >
                  <Link
                    to={{
                      pathname: `/dashboard/investisseur/detail-projet/${project.slug}`,
                      state: project,
                    }}
                    className="d-flex justify-content-center"
                  >
                    <div
                      className="project-item-global-card"
                      style={{ width: "340px" }}
                    >
                      <div className="card bg-white project-item-card">
                        <div className="project-item-card-img-container">
                          <video controls width="100%" height="auto">
                            <source
                              src={storage + project?.video}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                        <div className="card-body bg-white p-0 project-item-card-body">
                          <div className="d-flex justify-content-between align-items-center pt-3">
                            <div className="">
                              <div
                                className="financement"
                                style={{ backgroundColor: "#E87930" }}
                              >
                                <p
                                  style={{
                                    fontSize: "10.3px",
                                    color: "#E9F3F4",
                                    textAlign: "center",
                                    fontWeight: "700",
                                    padding: "6px 20px",
                                  }}
                                >
                                  {project?.secteur?.length
                                    ? project.secteur.map(
                                        (item, index) =>
                                          item.name +
                                          `${
                                            index < project.secteur.length - 1
                                              ? " - "
                                              : ""
                                          }`
                                      )
                                    : "non défini"}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              {/* <div className="financement">
                                <p
                                  style={{
                                    color: "#E9F3F4",
                                    textAlign: "center",
                                    fontSize: "9px",
                                    fontWeight: "900",
                                  }}
                                >
                                  {project.statut === "valider" ? (
                                    <span className="bg-success text-white py-2 px-4 bordureStatut">
                                      Validé
                                    </span>
                                  ) : project.statut === "rejeter" ? (
                                    <span className="bg-danger text-white py-2 px-4 bordureStatut">
                                      Rejeté
                                    </span>
                                  ) : (
                                    <span className="bg-secondary text-white py-2 px-4 bordureStatut">
                                      Soumis
                                    </span>
                                  )}
                                </p>
                              </div> */}
                            </div>
                          </div>
                          <div className="px-4 py-2">
                            <h6
                              className="card-title mt-3 mr3 project-item-name"
                              style={{
                                fontSize: "18px",
                                marginLeft: "-28px",
                                color: "#808080",
                                fontWeight: "700",
                                lineHeight: "21px",
                              }}
                            >
                              {project.title}
                            </h6>
                            <div className="row">
                              <div className="col">
                                <div className="financement">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Besoin en financement:{" "}
                                  </span>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#F68B1E",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    FCFA{" "}
                                    {project?.fonds_supplementaires_prix
                                      ? project.fonds_supplementaires_prix
                                      : "Montant non défini"}
                                  </p>
                                </div>
                              </div>
                              <div className="col ml-auto">
                                <div
                                  className="financement"
                                  style={{ marginLeft: "40px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Montant atteint:{" "}
                                  </span>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#369E8A",
                                      marginLeft: "-13px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    FCFA{" "}
                                    {project?.fonds_supplementaires_prix
                                      ? project.fonds_supplementaires_prix
                                      : "non défini"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ProjetsEnLigneInvestisseur;
