import React, { useState } from "react";
import { useSelector } from "react-redux";
import storage from "../../../../../../src/Http/storage";
import Avatar from "../../../../../../src/assets/images/avatar.webp";
import "./DetailDesProjet.css";
import { NavLink } from "react-router-dom";

function LesInteresser({ project }) {
  const souscrire = useSelector((state) => state.souscrire);
  console.log("souscrire:", souscrire);

  const filteredSouscriptions = Array.isArray(souscrire.results)
    ? souscrire.results.filter((souscription) => {
        console.log(
          "souscription.projet.id:",
          souscription.projet.id,
          "project?.id:",
          project?.id
        );
        return souscription.projet.id === project?.id;
      })
    : [];

  console.log("filteredSouscriptions:", filteredSouscriptions);

  const uniqueInvestors = new Set();
  const uniqueSouscriptions = filteredSouscriptions.filter((souscription) => {
    if (!uniqueInvestors.has(souscription.user.id)) {
      uniqueInvestors.add(souscription.user.id);
      return true;
    }
    return false;
  });

  console.log("uniqueSouscriptions:", uniqueSouscriptions);

  const handleError = (e) => {
    e.target.src = Avatar;
  };

  return (
    <div className=" d-flex align-items-center">
      {uniqueSouscriptions.length > 0 ? (
        uniqueSouscriptions.map((souscription) => (
          <NavLink>
            <img
              key={souscription.user.id}
              alt="Avatar"
              src={
                souscription.user.avatar
                  ? storage + souscription.user.avatar
                  : Avatar
              }
              onError={handleError}
              className="pictureInteresser"
            />
          </NavLink>
        ))
      ) : (
        <p>Aucune souscription trouvée.</p>
      )}
    </div>
  );
}

export default LesInteresser;
