import { FETCHS_PROJECT_BY_INCUBATEUR } from "../types";

export const ProjectByIncubateurReducer = (
	state = { isLoading: true, data: [] },
	action,
) => {
    switch (action.type) {
		case FETCHS_PROJECT_BY_INCUBATEUR:
			return {
				isLoading: false,
				data: action.payload,
				filteredData: action.payload,
			};
		default:
            return state;
	}
};
