import React, { useMemo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// import masterCard from "../../../images/masterCard.png";
// import visa from "../../../images/visa.png";
import ecobank from "../../../images/ecobank.png";
import paydunya from "../../../images/paydunya.png";
// import DetailPaiementModal from "../../Modals/DetailPaiementModal";
import { useNavigate } from "react-router-dom";

const data = [
  { id: 0, label: "paytech", image: ecobank, alt: "Paytech", name: "Paytech" },
  {
    id: 1,
    label: "paydunya",
    image: paydunya,
    alt: "Paydunya",
    name: "Paydunya",
  },
];

const ChoisirModePaiement = ({ navigation, formData, setFormData }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const projectsState = useSelector((state) => state.projectsForVisitors);
  const [projectSlug, setProjectSlug] = useState("");

  useEffect(() => {
    const project = projectsState.data ? projectsState.data.find((projet) => projet.id == projectId) : null;
    if (project) {
      setProjectSlug(project.slug);
    }
  }, [projectId, projectsState.data]);

  // console.log(projectSlug);
  const handleSessionSecurity = () => {
    navigate(`/projet/${projectSlug}`);
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        moyen_paiement: yup
          .string()
          .required("Choisissez votre méthode de paiement avant de passer")
          .nullable(),
      }),
    []
  );
  const handleChange = (e) => {
    if (e.target.name === "moyen_paiement") {
      setValue("moyen_paiement", e.target.value);
      setHavePaid(true);
    }
  };
  const [havePaid, setHavePaid] = useState(false);
  const { handleSubmit, clearErrors, formState, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [errorPayment, setErrorPayment] = useState("");
  useEffect(() => {
    setValue("moyen_paiement", formData?.moyen_paiement);
  }, [formData, setValue]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [clearErrors, formState]);

  const onSubmit = async (data) => {
    if (!data?.moyen_paiement) {
      await setErrorPayment(
        "Choisissez votre méthode de paiement avant de passer"
      );
      window.scrollTo(50, 50);
      setTimeout(() => {
        setErrorPayment("");
      }, 3000);
    } else {
      await setFormData({ ...formData, ...data });
      console.log("moyen paiement :", data.moyen_paiement);
      return navigation?.go("choisir-montant");
    }
  };

  const props = {
    handleSubmit,
    clearErrors,
    formState,
    setValue,
    formData,
    // open,
    navigation,
    errorPayment,
  };

  return (
    <div>
      <div className="detail-paiement py-5">
        <div className="px-5 py-5">
          <h2 className="text-center mt-4">Mode de paiement</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5 flex-fill px-3">
              {data.map((card) => (
                <div className="fs-20 detail-paiement-carte" key={card.id}>
                  <input
                    type="radio"
                    name="moyen_paiement"
                    id={`moyen_paiement${card.id}`}
                    value={card.label}
                    defaultChecked={formData?.moyen_paiement === card.label}
                    onChange={handleChange}
                    data-testid={`moyen_paiement_${card.id}`}
                    className="input-hidden-radio"
                  />
                  <label
                    htmlFor={`moyen_paiement${card.id}`}
                    className="carte-image"
                  >
                    <img src={card.image} alt={card.alt} /> &ensp; {card.name}
                  </label>
                </div>
              ))}
              {formState.errors && formState.errors.moyen_paiement && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState.errors.moyen_paiement?.message}
                </div>
              )}
            </div>
            <div
              className="flex-fill d-flex align-items-center justify-content-end"
              style={{ marginTop: "8rem" }}
            >
              <button
                className="button-alimenter-son-compte button-alimenter-son-compte-precedent mr-3"
                onClick={handleSessionSecurity}
              >
                Annuler
              </button>
              <button
                className="button-alimenter-son-compte button-alimenter-son-compte-suivant"
                data-bs-toggle="modal"
                data-bs-target="#detailPaiementModal"
                type="submit"
              >
                Continuer
              </button>
            </div>
          </form>
          {/* <DetailPaiementModal /> */}
        </div>
      </div>
    </div>
  );
};

export default ChoisirModePaiement;
