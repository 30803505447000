import api from "../../Http/global-vars";
import { FETCH_SOUSCRIRE } from "../types";
import axios from "axios";

export const fetchSouscrire = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "subscriber/";
    try {
      const res = await axios.get(url, config);
      dispatch({
        type: FETCH_SOUSCRIRE,
        payload: {
          count: res.data.count,
          results: res.data.results.sort((a, b) => b.id - a.id),
        },
      });
    } catch (error) {
      console.log("error", error?.response);
    }
  };
};

export const fetchSouscrireId = (id) => {
	return async (dispatch) => {
		const config = {
			headers: {
				Authorization: `Bearer ${window.sessionStorage.getItem(
					"userToken"
				)}`,
			},
		};
		const token = window.sessionStorage.getItem("userToken");
		if (token) {
			const url = api + "subscriber/" + id+"/";

		await axios
			.get(url, config)
			.then(async (res) => {
				dispatch({
					type: FETCH_SOUSCRIRE,
					payload: res?.data.results.sort(
						(a, b) => a?.id > b?.id ? ( a?.id === b?.id) ? 0 : -1 : 1
					),
				});
			})
			.catch((error) => console.log("error", error?.response));
		}
	};
};

export const postSouscrire = (data) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "subscriber/";
    console.log('url', url);
    try {
      await axios.post(url, data, config);
      // Optionally, you can dispatch fetchSouscrire to update the list
      dispatch(fetchSouscrire());
    } catch (error) {
      console.log("error", error?.response);
    }
  };
};
