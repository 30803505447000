import React from 'react'
import { useNavigate  } from 'react-router-dom'

const ModalSecuriteSession = () => {
    const navigate = useNavigate ()
  return (
    <div
					className="modal fade"
					id="securityModal"
					tabIndex="-1"
					aria-labelledby="securityModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div
								className="bg-white px-3 py-4"
							>
								<div className="d-flex justify-content-end">
									<svg
										width="14"
										height="15"
										viewBox="0 0 14 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										type="button"
										data-bs-dismiss="modal"
									>
										<path
											d="M13.9844 1.92188L8.40625 7.5L13.9844 13.0781L12.5781 14.4844L7 8.90625L1.42188 14.4844L0.015625 13.0781L5.59375 7.5L0.015625 1.92188L1.42188 0.515625L7 6.09375L12.5781 0.515625L13.9844 1.92188Z"
											fill="#666666"
										/>
									</svg>
								</div>
								<h3 className="activite-modal-title mt-4">
									Sécurisez votre session
								</h3>
								<div className="mt-5 px-5 text-justify">
									<p>
										Votre compte a
										besoin d’être
										sécutrisé pour
										effectuer cette
										action. Nous avons
										envoyé un code à
										chiffres à votre
										numéro +221 77
										xxxxxxxx
									</p>
								</div>
								<div className="form-activite-modal-input-group">
									<input
										type="text"
										className="form-activite-modal-input"
										placeholder="77 xxx xx xx"
									/>
								</div>
								<div
									className="flex-fill d-flex align-items-center justify-content-center"
									style={{
										marginTop: "5rem",
									}}
								>
									<button
										type="button"
										data-bs-dismiss="modal"
										className="button-modal-activite-security"
										onClick={() =>
											navigate(
												"/alimenter-son-compte"
											)
										}
									>
										Sécuriser
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
  )
}

export default ModalSecuriteSession