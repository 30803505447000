import React, { useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import Projet from "../../../../Services/Projet";
import { fetchProjects } from "../../../../../redux/projects/projectAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function UseEditParam() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
    const params = useParams()
  const dispatch = useDispatch();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const projects = useSelector((state) => state.projects);
  console.log({projects});

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("Ce champ est requis."),
        description: yup
          .string()
          .required("Ce champ est requis.")
          .max(100, "Vous devez écrire au maximum 100 caractères.")
          .nullable(),
        url_demo: yup.string().url("Votre url est invalide.").nullable(),
       
      }),
    []
  );
const defaultProjectData = projects.data && projects.data.length > 0 ? projects.data[0] : {}; // Accéder aux données du premier projet s'il existe

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      title: defaultProjectData.title,
      description: defaultProjectData.description,
      url_demo: defaultProjectData.url_demo,
      duree_existance : defaultProjectData.duree_existance,
      senegal : defaultProjectData.senegal ,
      objectif_financement : defaultProjectData.objectif_financement ,
      mode_financement : defaultProjectData.mode_financement ,
      // secteur : defaultProjectData.secteur ,
      taille_marche : defaultProjectData.taille_marche,
      fraction_objectif : defaultProjectData.fraction_objectif ,
      statut_newprojet_by_porteur : defaultProjectData.statut_newprojet_by_porteur,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    console.log("Des forms", formValues);
    setIsSuccessfullySubmitted(true);

    var request = Projet.onEditProjet(
      params.projectId,
      formValues,
      requestConfig
    );
    await request
      .then(async (resp) => {
        await dispatch(fetchProjects());
        await Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        window.location.href = ("/entrepreneur/gestion-des-projets")
        // e.target.reset();
        // reset();
      })
      .catch((error) => {
        // console.error("error", error?.response);
       if (error?.response?.data?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
 
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    onSubmit: handleSubmit(onSubmitForm),
    defaultValues: {
      title: defaultProjectData?.title || "", // Assurez-vous que defaultProjectData est correctement défini et utilisez une valeur par défaut (ici, une chaîne vide) si title est undefined
      description: defaultProjectData?.description || "",
      url_demo: defaultProjectData?.url_demo || "",
      duree_existance : defaultProjectData.duree_existance || "",
      senegal : defaultProjectData.senegal || "",
      objectif_financement : defaultProjectData.objectif_financement || "",
      // secteur : defaultProjectData.secteur  || "",
      taille_marche : defaultProjectData.taille_marche  || "",
      fraction_objectif : defaultProjectData.fraction_objectif  || "",
      mode_financement : defaultProjectData.mode_financement || "",
      statut_newprojet_by_porteur : defaultProjectData.statut_newprojet_by_porteur || "",

    }
  };

}


export default UseEditParam;
