import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSouscrire } from "../../../../redux/souscrire/souscrireAction.js";
import storage from "../../../../Http/storage.js";

const ListeProjetFinanceDetail = () => {
  const projectSouscri = useSelector((state) => state.souscrire);
  const { isLoading, results: projects } = projectSouscri;
  console.log('projectSouscri', projectSouscri?.results);
  console.log({projectSouscri});
  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSouscrire());
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setShowSkeleton(false);
        setError("La requête a pris trop de temps. Veuillez réessayer.");
      }, 30000); // 30000ms = 30s, ajustez le temps selon vos besoins
      return () => clearTimeout(timeoutId);
    } else {
      setShowSkeleton(false);
    }
  }, [isLoading]);

  return (
    <div className="" style={{ background: "white" }}>
      <div className="raise-money-titles liste-projets-bar">
        <div className="liste-projets-info">
          <h1 className="liste-projets-title">Projets financés</h1>
        </div>
      </div>
      <div className="">
        {!showSkeleton && error && (
          <div className="text-center">
            <p className="bg-danger text-light font-weight-bold p-2">{error}</p>
          </div>
        )}
        {!showSkeleton && !error && (
          <div className="row project-list-containe">
            {projects.map((project) => (
              <div
                className="col-md-4 project-item-container"
                key={project.id}
                style={{}} // Adjust the margin bottom here
              >
                <Link
                  to={{
                    pathname: `/dashboard/investisseur/detail-projetFinance/${project.projet.slug}`,
                    state: project,
                  }}
                  className="d-flex justify-content-center"
                >
                  <div className="project-item-global-card">
                    <div className="card bg-white project-item-card">
                      <div className="project-item-card-img-container">
                        <video
                          controls
                          className="card-img-top project-item-img"
                        >
                          <source src={storage + project.projet.video} type="video/mp4" />
                        </video>
                      </div>
                      <div className="card-body bg-white p-0 project-item-card-body">
                        <div className="d-flex justify-content-between align-items-center pt-3">
                          <div className="">
                            <div className="financement" style={{ backgroundColor: "#E87930" }}>
                              <p
                                style={{
                                  fontSize: "10.3px",
                                  color: "#E9F3F4",
                                  textAlign: "center",
                                  fontWeight: "700",
                                  padding: "6px 20px",
                                }}
                              >
                                {project.projet?.secteur?.length
                                  ? project.projet.secteur.map(
                                      (item, index) =>
                                        item.name +
                                        `${index < project.projet.secteur.length - 1 ? " - " : ""}`
                                    )
                                  : "non défini"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-2">
                          <h6
                            className="card-title mt-3 mr3 project-item-name"
                            style={{
                              fontSize: "18px",
                              marginLeft: "-28px",
                              color: "#808080",
                              fontWeight: "700",
                              lineHeight: "21px",
                            }}
                          >
                            {project.projet.title} 
                          </h6>
                          <div className="row">
                            <div className="col">
                              <div className="financement">
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "gray",
                                    marginLeft: "-13px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Besoin en financement:{" "}
                                </span>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#F68B1E",
                                    marginLeft: "-13px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {project.projet?.fonds_supplementaires_prix
                                    ? project.projet.fonds_supplementaires_prix
                                    : "0"} FCFA
                                </p>
                              </div>
                            </div>
                            <div className="col ml-auto">
                              <div
                                className="financement"
                                style={{ marginLeft: "40px" }}
                              >
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "gray",
                                    marginLeft: "-13px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Montant atteint:{" "}
                                </span>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#369E8A",
                                    marginLeft: "-13px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {project.projet?.fonds_supplementaires_prix
                                    ? project.projet.fonds_supplementaires_prix
                                    : "0"} FCFA
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListeProjetFinanceDetail;
