import React from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the plugin with Chart.js
Chart.register(ChartDataLabels);

function GraphSecteur() {

  const values = [35, 25, 22, 20, 18, 15];
  const total = values.reduce((acc, value) => acc + value, 0);

  const labelsWithPercentages = ["Agriculture", "Numérique", "Transport", "Education", "Energie", "Logistique"].map(
    (label, index) => `${label} (${((values[index] / total) * 100).toFixed(2)}%)`
  );

  const data = {
    labels: labelsWithPercentages,
    datasets: [
      {
        label: "# of Votes",
        data: values,
        backgroundColor: [
          "#007D9C",
          "#244D70",
          "#D123B3",
          "#F7E018",
          "#fff",
          "#FE452A",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 10, // Adjust the width of the legend boxes
          padding: 5 // Adjust the padding between legend items
        },
      },
      title: {
        display: false,
        text: "Current Allocations",
      },
      datalabels: {
        formatter: (value, context) => {
          const percentage = ((value / total) * 100).toFixed(2) + "%";
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold'
        }
      },
    },
    // Add center text configuration
    centerText: {
      display: true,
      text: `Total: ${total}`
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Pie data={data} options={options} className="diagrammeCirculaire" />
      <div className="total">
        {total}
      </div>
    </div>
  );
}

export default GraphSecteur;
