import React from "react";
import "./FrontHeaderV2.css";
import { NavLink, useNavigate } from "react-router-dom";
import { User } from "react-iconly";
import { useSelector } from "react-redux";
import { navbarLogo } from "../../Logo/LogoComponent";

const FrontHeaderV2 = (props) => {
  var navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const userType = sessionStorage.getItem("userType");
  var logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("userToken");
    window.sessionStorage.clear();
    if (sessionStorage.removeItem("userType") === "admin") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
    var data = window.sessionStorage.getItem("userType");
    console.log("daadadada", data);
  };
  // console.log(userType);
  return (
    <div className="component-front-header">
      <nav className="navbar navbar-expand-lg position-fixed top-0 z-index-1  fixed-top w-100 py-3 header-scrolling-container">
        <div className="container gap-4" id="frontHeader">
          <NavLink
            className="navbar-brand w-25"
            to={!userType === "investisseur" ? "" : "/"}
          >
            <img src={navbarLogo} alt="logo" className="w-35 pb-2" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto">
              {userType === "investisseur" ? (
                ""
              ) : userType === "incubateur" ? (
                <li className="nav-item nav-african-hot-jobs px-lg-2">
                  <NavLink
                    className={`nav-link trans-0-2 ${
                      props.active === 1 && "active"
                    }`}
                    to="/incubateur/dashboard/"
                  >
                    Mon tableau de bord
                  </NavLink>
                </li>
              ) : userType === "entrepreneur" ? (
                <li className="nav-item nav-african-hot-jobs px-lg-2">
                  <NavLink
                    className={`nav-link trans-0-2 ${
                      props.active === 1 && "active"
                    }`}
                    to="/entrepreneur/dashboard/"
                  >
                    Mon tableau de bord
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item nav-african-hot-jobs px-lg-2">
                  <NavLink
                    className={`nav-link trans-0-2 ${
                      props.active === 1 && "active"
                    }`}
                    to="/"
                  >
                    Accueil
                  </NavLink>
                </li>
              )}

              {userType === "investisseur" ? (
                <li className="nav-item nav-african-hot-jobs px-lg-2">
                  <NavLink
                    className={`nav-link trans-0-2 ${
                      props.active === 6 && "active"
                    }`}
                    to="/investisseur/Dashboard"
                  >
                    Mon tableau de bord
                  </NavLink>
                </li>
              ) : (
                ""
              )}
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink
                  className={`nav-link trans-0-2 ${
                    props.active === 2 && "active"
                  }`}
                  to="/liste-des-projets"
                >
                  Les projets
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
									className="nav-link trans-0-2"
									to="/investir"
								>
									Investir
								</NavLink>
							</li>
              {userType === "investisseur" ? (
                ""
              ) : (
                <li className="nav-item nav-african-hot-jobs px-lg-2">
                  <NavLink
                    className={`nav-link trans-0-2 ${
                      props.active === 3 && "active"
                    }`}
                    to="/se-financer"
                  >
                    Se financer
                  </NavLink>
                </li>
              )}
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink
                  className={`nav-link trans-0-2 ${
                    props.active === 4 && "active"
                  }`}
                  to="/comment-ca-marche"
                >
                  Comment ça marche ?
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink
                  className={`nav-link trans-0-2 ${
                    props.active === 5 && "active"
                  }`}
                  to="/a-propos-de-nous"
                >
                  À propos de nous
                </NavLink>
              </li>
            </ul>
            {window.sessionStorage.getItem("userType") ? (
              <div className="">
                {/* A afficher si l'utilisateur n'est pas connecté */}
                {/* <NavLink className="link-header-login trans-0-2 d-block" to="/login" >Connexion</NavLink> */}

                {/* A afficher si l'utilisateur est connecté */}
                <div className="dropdown connexion-dropdown">
                  <a
                    className="dropdown-toggle trans-0-2"
                    href="#dropdown-menu"
                    role="button"
                    id="connexionDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="name-user-connect">
                      {user?.data?.id &&
                        user?.data?.prenom + " " + user?.data?.nom}
                    </span>
                    <span className="icon image-responsive mx-2 p-2 ">
                      <User set="bold" primaryColor="var(--secondary-color)" />
                    </span>
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="connexionDropdown"
                    id="dropdown-menu"
                  >
                    {userType === "admin" ? (
                      <NavLink
                        exact
                        className="dropdown-item no-bg border-bottom"
                        to="/admin/dashboard"
                      >
                        Mon tableau de board
                      </NavLink>
                    ) : (
                      ""
                    )}
                    <NavLink
                      className="dropdown-item no-bg"
                      to=""
                      onClick={(e) => logout(e)}
                    >
                      Deconnexion
                    </NavLink>
                  </div>
                </div>

                <div className="d-md-none d-inline-block mt-4 language-selector-mobile"></div>
              </div>
            ) : (
              <div className="auth-btn-container">
                <div className="auth-item login-btn-container">
                  <NavLink
                    className="link-header-login trans-0-2 d-block mx-2"
                    to="/connexion"
                  >
                    Connexion
                  </NavLink>
                </div>
                <div className="auth-container register-btn-container">
                  <NavLink
                    className="link-header-register trans-0-2 d-block mx-2"
                    to="/inscription"
                  >
                    Inscription
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default FrontHeaderV2;
