import React from "react";
import Entrepreneur from "../FrontendV2/InvestisseurTableauDeBord/ProfilInvestisseur/ProjetInvestisseurDetail/entrepreneur.png";

function InvestisseurModal({ data }) {
  return (
    <div className="modal-dialog modal-dialog-centered  easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">Voir le profil</h5>
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="porteur">
              <img src={Entrepreneur} alt="Image entrepreneur" />
            </div>
            <p className="text-center" style={{ marginTop: "15px" }}>
              Incubé chez {data?.prenom}
            </p>
            <div className="row align-items-center my-3">
              <div className="col-md-6">
                <div className="mb-3 form-group">
                  <div className="fs-textee">Prénom </div>
                  <p className="fs-Texte">{data?.prenom || "Pas défini"}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3 form-group">
                  <div className="fs-textee">Nom</div>
                  <p className="fs-Texte">{data?.nom || "Pas défini"}</p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-5">
              <div className="col-md-6">
                <div className="mb-3 form-group">
                  <div className="fs-textee">Adresse</div>
                  <p className="fs-Texte">
                    {data?.adresse || "Pas défini"}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3 form-group">
                  <div className="fs-textee">Téléphone</div>
                  <p className="fs-Texte">{data?.phone || "Pas défini"}</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
            <button className="btn-CV">Télécharger le cv</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestisseurModal;
