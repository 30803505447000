import api from '../Http/global-vars'


export const sous_admins = {
  onGetOrPostSousAdmin() {
    return `${api}sousadmin/`
  },
  onPutSousAdmin(id) {
    return `${api}user/${id}/`
  },
  onDeleteSousAdmin(id) {
    return `${api}user/${id}/`
  },
  onGetUser() {
    return `${api}user/`
  },
}
export const porteur_projects = {
  onPostPorteurProject() {
    return `${api}porteur_projet/`
  },
  onPutPorteurProject(id) {
    return `${api}porteur_projet/${id}/`
  },
  onDeletePorteurProject(id) {
    return `${api}porteur_projet/${id}/`
  },
}

// export const users = {
//   onGetUser() {
//     return `${api}user/` 
//   },
// }

export const message_contacts = {
  onPostMessageContact() {
    return `${api}contact/`
  },
  onPutMessageContact(id) {
    return `${api}contact/${id}/`
  },
  onDeleteMessageContact(id) {
    return `${api}contact/${id}/`
  },
  onGetMessageContactId(id) {
    return `${api}contact/${id}/`
  },
}


export const projects = {
  onPostProject() {
    return `${api}project/`
  },
  onGetAllProject() {
    return `${api}project/`
  },
  onPutProject(id) {
    // return `${api}project/${id}/`
    return `${api}valid_project/${id}/`
  },
  onDeleteProject(id) {
    return `${api}project/${id}/`
  },
  onGetProject(id) {
    return `${api}project/${id}/`
  },
}

// export const projects_frontend= {
//   onPostProject() {
//     return `${api}candidatures/`
//   },
//   postProjectDocs() {
//     return `${api}document/`
//   },
//   onGetAllProject() {
//     return `${api}candidatures/`
//   },
//   onPutProject(id) {
//     return `${api}candidatures/${id}/`
//   },
//   onDeleteProject(id) {
//     return `${api}candidatures/${id}/`
//   },
//   onGetProject(id) {
//     return `${api}candidatures/${id}/`
//   },
// }


export const commentaires = {
  onPostCommentaire() {
    return `${api}commentaire/`
  },
  onPutCommentaire(id) {
    return `${api}commentaire/${id}/`
  },
  onDeleteCommentaire(id) {
    return `${api}commentaire/${id}/`
  },
  onGetCommentaire(id) {
    return `${api}commentaire/${id}/`
  },
}

export const project_donations = {
  onPostorGetProjectDonation() {
    return `${api}donation/`
  },
  onPutProjectDonation(id) {
    return `${api}donation/${id}/`
  },
  onDeleteProjectDonation(id) {
    return `${api}donation/${id}/`
  },
  onGetProjectDonation(id) {
    return `${api}donation/${id}/`
  },
}

export const contribuer_projects = {
  onPostorGetContributionProject() {
    return `${api}donation/`
  },
  onPutContributionProject(id) {
    return `${api}donation/${id}/`
  },
  onDeleteContributionProject(id) {
    return `${api}donation/${id}/`
  },
  onGetContributionProject(id) {
    return `${api}donation/${id}/`
  },
}


export const financements = {
  onPostFinancement() {
    return `${api}invest/`
  },
  onPutFinancement(id) {
    return `${api}invest/${id}/`
  },
  onDeleteFinancement(id) {
    return `${api}invest/${id}/`
  },
  onGetFinancement(id) {
    return `${api}invest/${id}/`
  },
  onGetAllFinancement() {
    return `${api}invest/`
  },
}
export const files = {
  onPostFile() {
    return `${api}file/`
  },
  onPutFile(id) {
    return `${api}file/${id}/`
  },
  onDeleteFile(id) {
    return `${api}file/${id}/`
  },
  onGetFile(id) {
    return `${api}file/${id}/`
  },
}


export const faqs = {
  onPostFAQ() {
    return `${api}faq/`
  },
  onPutFAQ(id) {
    return `${api}faq/${id}/`
  },
  onDeleteFAQ(id) {
    return `${api}faq/${id}/`
  },
  onGetFAQ(id) {
    return `${api}faq/${id}/`
  },
}

export const faqsresponses = {
  onPostFaqResponse() {
    return `${api}faqreponse/`
  },
  onPutFaqResponse(id) {
    return `${api}faqreponse/${id}/`
  },
  onDeleteFaqResponse(id) {
    return `${api}faqreponse/${id}/`
  },
  onGetFaqResponse(id) {
    return `${api}faqreponse/${id}/`
  },
}

export const projects_tutores = {
  onPostProjectTutore() {
    return `${api}projet_tutore/`
  },
  onPutProjectTutore(id) {
    return `${api}projet_tutore/${id}/`
  },
  onDeleteProjectTutore(id) {
    return `${api}projet_tutore/${id}/`
  },
  onGetProjectTutore(id) {
    return `${api}projet_tutore/${id}/`
  },
}

export const actualites = {
  onPostActualite() {
    return `${api}actualite/`
  },
  onPutActualite(id) {
    return `${api}actualite/${id}/`
  },
  onDeleteActualite(id) {
    return `${api}actualite/${id}/`
  },
  onGetActualite(id) {
    return `${api}actualite/${id}/`
  },
}

export const notifications = {
  onPostNotification() {
    return `${api}notification/`
  },
  onPutNotification(id) {
    return `${api}notification/${id}/`
  },
  onDeleteNotification(id) {
    return `${api}notification/${id}/`
  },
  onGetNotification(id) {
    return `${api}notification/${id}/`
  },
}

export const candidatures = {
  onPostCandidature() {
    return `${api}candidatures/`
  },
  postProjectDocs() {
    return `${api}document/`
  },
  onPutCandidature(id) {
    return `${api}candidatures/${id}/`
  },
  onDeleteCandidature(id) {
    return `${api}candidatures/${id}/`
  },
  onGetCandidature(id) {
    return `${api}candidatures/${id}/`
  },
}

export const Utilisateur = {
  onGetListUtilisateur() {
    return `${api}user/`;
  },
  onPostUtilisateur() {
    return `${api}user/`;
  },
  onEditUtilisateur($id) {
    return `${api}user/${$id}/`;
  },
  onDeleteUtilisateur($id) {
    return `${api}user/${$id}/`;
  },
};

export const Incubateur = {
  onPostIncubateur() {
    return `${api}create_incubateur/`;
  },
  onPostProjectByIncubateur() {
    return `${api}projet_by_incubateur/`
  },
};
export const Entrepreneur = {
  onPostEntrepreneur() {
    return `${api}create_entrepreneur/`;
  },
  onGetEntrepreneur() {
    return `${api}create_entrepreneur/`;
  }
 
};

export const Souscrire = {
  onPostSouscrire() {
    return `${api}subscriber/`;
  },
  onGetSouscrire($id) {
    return `${api}subscriber/${$id}/`;
  },
  onGetAllSouscrire() {
    return `${api}subscriber/`;
  }
};