import React, { useEffect } from "react";
import "./Apropos.css";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import CircularProgress from "./CircularProgress";
import AproposText from "./AproposText";
import Partenaire from "../Partenaire/Partenaire";
import Investisseur from "./Investisseur";
import Contact from "./Contact";
import Incubateur from "./Incubateur";

var Apropos = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid col-12  col-md-6 col-lg-12  mx-0 px-0">
      <FrontHeaderV2 />
      <div className="cardsPropos">
          <h3 className="aprops-page-title">À propos de nous</h3>
      </div>
      <div className="">
        <div className="container">
          <AproposText />
          <CircularProgress />
        </div>
      </div>
      <div className="container">
        <Partenaire stylePartenaire="py-5 titre-a-propos-text" />
      </div>
      <div className="p-5 containerIn">
        <Investisseur />
      </div>
      <div className="p-5 containerIncubateur">
        <Incubateur/>
      </div>
      <div className="cards p-0">
        <Contact />
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default Apropos;
