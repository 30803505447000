import React,{useEffect} from "react";
import "./Faq.css";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HeaderV2 from "../Navs/HeaderV2";
import NavbarV2 from "../NavbarV2/NavbarV2";
import FaqModals from "../../../Modals/FaqModals";

function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="users-container">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="card card-body card-faq">
              <h3 className="faq-title">FAQ</h3>
              <div className="px-5">
                <div className="div-faq-boutton">
                  <button
                    type="button"
                    className="btn btn-nouvelle-question"
                    data-bs-toggle="modal"
                    data-bs-target="#faqModal"
                  >
                    <AddCircleOutlineIcon className="btn-nouvelle-question-icon" />
                    Nouvelle question
                  </button>

                  <FaqModals />
                </div>
                <div className="card-faq-main-content">
                  <div className="row div-card">
                    <div className="col-7">
                      <h4 className="faq-card-title">
                        comment participer au projet?
                      </h4>
                      <p className="faq-card-para">Le 02-03-2020</p>
                    </div>
                    <div className="col-5">
                      <div className="div-consultation-icon">
                        <div className="d-none d-md-block">
                          <div className="faq-consultation-number">254</div>
                          <p className="faq-consultation-para">consultation</p>
                        </div>
                        <div className="div-icon">
                          <EditIcon
                            className="faq-edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#faqModal"
                          />
                          <DeleteIcon className="faq-trash-icon" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row div-card">
                    <div className="col-7">
                      <h4 className="faq-card-title">
                        Que se passe t-il si le projet n'est pas financé?
                      </h4>
                      <p className="faq-card-para">Le 02-03-2020</p>
                    </div>
                    <div className="col-5">
                      <div className="div-consultation-icon">
                        <div className="d-none d-md-block">
                          <div className="faq-consultation-number">254</div>
                          <p className="faq-consultation-para">consultation</p>
                        </div>
                        <div className="div-icon">
                          <EditIcon
                            className="faq-edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#faqModal"
                          />
                          <DeleteIcon className="faq-trash-icon" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row div-card">
                    <div className="col-7">
                      <h4 className="faq-card-title">
                        Quand est-ce que je suis débité(e)?
                      </h4>
                      <p className="faq-card-para">Le 02-03-2020</p>
                    </div>
                    <div className="col-5">
                      <div className="div-consultation-icon">
                        <div className="d-none d-md-block">
                          <div className="faq-consultation-number">254</div>
                          <p className="faq-consultation-para">consultation</p>
                        </div>
                        <div className="div-icon">
                          <EditIcon
                            className="faq-edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#faqModal"
                          />
                          <DeleteIcon className="faq-trash-icon" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row div-card">
                    <div className="col-7">
                      <h4 className="faq-card-title">
                        Quelles informations me concernant seront affichée?
                      </h4>
                      <p className="faq-card-para">Le 02-03-2020</p>
                    </div>
                    <div className="col-5">
                      <div className="div-consultation-icon">
                        <div className="d-none d-md-block">
                          <div className="faq-consultation-number">254</div>
                          <p className="faq-consultation-para">consultation</p>
                        </div>
                        <div className="div-icon">
                          <EditIcon
                            className="faq-edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#faqModal"
                          />
                          <DeleteIcon className="faq-trash-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
