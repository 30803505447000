import React, { useEffect } from "react";
import NavbarV2 from "../NavbarV2/NavbarV2";
import ProjetsEnLigneIncubateur from "../ProjetEnLigneIncubateur/ProjetsEnLigneIncubateur";
import HeaderComponentV2 from "../../Admin/HeaderComponent/HeaderComponentV2";


const NosProjets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
   
    <div className="px-4 dashboard-container">
   <HeaderComponentV2 />
    <div className="mt-3 row p-0 ">
      <div className="col-lg-4 col-xl-2">
        <NavbarV2 />
      </div>
      <div className="col-lg-8 col-xl-10">
        <div className="projets">
        <ProjetsEnLigneIncubateur />
        </div>
      </div>
    </div>
  </div>
  );
};

export default NosProjets;
