import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HeaderV2 from "../../../../BackendV2/Admin/Navs/HeaderV2";
import SidebarTableauBord from "../../SidebarTableauBord/SidebarTableauBord";
import clocks from "./icon/clock.png";
import investissement from "./icon/investment.png";
import flesh from "./icon/flesh.png";
import LesInvestisseurs from "../../../../FrontendV2/InvestisseurTableauDeBord/ProfilInvestisseur/ProjetInvestisseurDetail/LesInvestisseurs";
import DetailsProjetInvetisseurParticipation from "./DetailsProjetInvetisseurParticipation";
import * as IconlyPack from "react-iconly";
import {
  fetchSouscrire,
  postSouscrire,
} from "../../../../../redux/souscrire/souscrireAction";
import { fetchMe } from "../../../../../redux/user/userAction";
import { fetchProjects } from "../../../../../redux/projects/projectAction";
import Swal from "sweetalert2";

const ProjetInvestisseurDetails = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectsForVisitors = useSelector((state) => state.projects);
  const interesser = useSelector((state) => state.souscrire);
  const current_user = useSelector((state) => state.user).data;
  const { isLoading, filteredData } = projectsForVisitors;
console.log({projectsForVisitors});
  const [userData, setUserData] = useState(null);
  const [projetData, setProjetData] = useState(null);
  const [projetSouscri, setProjetSouscri] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      const user = await dispatch(fetchMe());
      if (user) {
        setUserData(user.payload);
      }
    };

    const getProjetData = async () => {
      const projet = await dispatch(fetchProjects());
      if (projet) {
        setProjetData(projet.payload);
      }
    };

    const getProjetSouscire = async () => {
      const projetSouscri = await dispatch(fetchSouscrire());
      if (projetSouscri) {
        setProjetSouscri(projetSouscri.payload);
      }
    };

    getUserData();
    getProjetData();
    getProjetSouscire();
  }, [dispatch]);

  const projetDetail =
    filteredData && filteredData.find((project) => project.slug === projectId);

  const isAlreadySubscribed = interesser.results?.some(
    (item) =>
      item.projet.id === projetDetail?.id && item.user.id === current_user.id
  );

  const handleSouscrire = async () => {
    const souscrireData = {
      is_subscriber: true,
      user: current_user.id,
      projet: projetDetail.id,
    };
    setIsSubmitting(true);
    await dispatch(postSouscrire(souscrireData));
    setIsSubmitting(false);

    Swal.fire({
      icon: "success",
      text: "Votre souscription a été enregistrée avec succès.",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const handleValidation = () => {
    if (!isAlreadySubscribed) {
      handleSouscrire();
    } else {
      Swal.fire({
        icon: "warning",
        text: "Vous êtes déjà intéressé par ce projet.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <div className="component-mes-projet-tableau-de-bord">
      <HeaderV2 />
      <div className="mt-3 row p-0" style={{ background: "white" }}>
        <div className="col-lg-4 col-xl-2">
          <SidebarTableauBord active={2} />
        </div>
        <div className="col-lg-8 col-xl-10">
          <div className="title-detai-projet">
            <p style={{ fontSize: "15px", paddingTop: "8%" }}>
              <span>
                <NavLink
                  to="/dashboard-investisseur/nos-projets"
                  className="add-project-icon"
                >
                  <IconlyPack.ArrowLeft />
                </NavLink>
              </span>
              Détails du projet
            </p>
          </div>
          <div
            className="container-fluid"
            id="pills-tabContent"
            style={{ paddingTop: "2%" }}
          >
            {projetDetail && (
              <>
                <div className="row ">
                  <div className="col-md-7">
                    <DetailsProjetInvetisseurParticipation
                      project={projetDetail}
                    />
                  </div>
                  <div className="col-md-5 ">
                    <div className="row" style={{ paddingTop: "10px" }}>
                      <div className="col-md-6">
                        <p style={{ fontSize: "14px" }}>
                          <img src={clocks} alt="clock" /> Date du début
                        </p>
                        <span style={{ fontSize: "12px", color: "#717579" }}>
                          {projetDetail?.date_lancement}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <p style={{ fontSize: "14px" }}>
                          <span>
                            <img src={clocks} alt="clock" />
                          </span>{" "}
                          Date d'échéance
                        </p>
                        <span style={{ fontSize: "12px", color: "#717579" }}>
                          {projetDetail?.date_fin
                            ? projetDetail?.date_fin
                            : "Non défini"}
                        </span>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#171B1E",
                          }}
                        >
                          <img src={investissement} alt="investment" /> Besoin
                          en investissement
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#717579",
                          }}
                        >
                          {projetDetail?.objectif_financement}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <p style={{ fontSize: "14px" }}>
                          <span>
                            <img src={flesh} alt="flesh" />
                          </span>{" "}
                          Montant atteint
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#369E8A",
                            fontWeight: "500",
                          }}
                        >
                          {projetDetail?.date_fin
                            ? projetDetail?.fonds_supplementaires_prix
                            : "Non défini"}
                        </span>
                      </div>
                    </div>
                    <div className="parainer">
                      <button
                        className={`btn bouton-parainer w-100 ${
                          isAlreadySubscribed ? "bg-secondary" : ""
                        }`}
                        style={{
                          backgroundColor: "#369E8A",
                          color: "#FFFFFF",
                          fontSize: "18px",
                          marginTop: "10px",
                        }}
                        onClick={handleValidation}
                        disabled={isAlreadySubscribed}
                      >
                        {isAlreadySubscribed
                          ? "Déjà intéressé"
                          : "Je suis intéressé"}
                      </button>
                    </div>
                    <div style={{ paddingTop: "50px" }}>
                      <LesInvestisseurs project={projetDetail} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjetInvestisseurDetails;
